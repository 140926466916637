import React, { useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Procurement from "services/Procurement";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    width: "400px",
    maxWidth: "100%",
    height: "auto",
    overflow: "visible",
    transition: ".4s all ease",
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: ".4s all ease",
    backgroundColor: "rgba(0,0,0,.5)",
  },
};

function NegotiationModal({ procurementDetails, bidList, setUpdatePage }) {
  const { from } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [negotiationComment, setNegotiationComment] = useState("");
  const [negotiationRating, setNegotiationRating] = useState(""); // State for rating

  const convertBidList = bidList.map((bid) => ({
    id: bid.id,
    value: bid.id,
    businessId: bid.businesses.id,
    label: bid.businesses.business_name,
    totalAmount: bid.price,
    status: bid.status,
    supplierName: bid.businesses.business_name,
  }));

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleSubmit = async () => {
    // Handle the submit logic here
    const data = {
      procurementbid: selectedSupplier?.id,
      comment: negotiationComment,
      rating: negotiationRating,
      business: selectedSupplier?.businessId,
      bid_status: "Negotiation",
      status: "Negotiation",
    };
    const response = await Procurement.evaluationFeedBack(data);
    if (response.status === 200 || response.status === 201) {
      setUpdatePage(true);
      toast.success("Negotiation submitted successfully.");
      closeModal();
    } else {
      toast.error("Failed to submit negotiation.");
    }
  };

  const customStyles = (isValid) => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: isValid ? "#28a745" : "#dc3545",
      boxShadow: state.isFocused
        ? isValid
          ? "0 0 0 1px #28a745"
          : "0 0 0 1px #dc3545"
        : null,
      "&:hover": {
        borderColor: isValid ? "#28a745" : "#dc3545",
      },
      textAlign: "left",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isValid ? "#28a745" : "#dc3545",
      textAlign: "left",
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    menu: (provided) => ({
      ...provided,
      left: 0,
      marginTop: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: isValid ? "#28a745" : "#dc3545",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  });

  return (
    <div className="w-full flex justify-center">
      {from !== "supplier" && (
        <button
          className="my-4 bg-success hover:bg-green-600 text-white w-full font-bold  rounded py-3"
          onClick={openModal}
        >
          Negotiation
        </button>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Negotiation Modal"
      >
        <h2 className="text-lg font-bold mb-4">Negotiation</h2>

        <div className="mb-4">
          <Select
            styles={customStyles(!!selectedSupplier)}
            value={selectedSupplier}
            onChange={setSelectedSupplier}
            options={convertBidList}
            placeholder="Select supplier"
            isSearchable={false}
          />
        </div>

        {/* <div className="mb-4">
          <input
            type="number"
            min="1"
            max="5"
            className="w-full border rounded p-2"
            placeholder="Give rating"
            value={negotiationRating}
            onChange={(e) => setNegotiationRating(e.target.value)}
          />
        </div> */}

        <div className="mb-4">
          <textarea
            className="w-full border rounded p-2"
            rows="4"
            placeholder="Enter negotiation comments"
            value={negotiationComment}
            onChange={(e) => setNegotiationComment(e.target.value)}
          />
        </div>

        <button
          className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          onClick={handleSubmit}
        >
          Submit Negotiation
        </button>
      </Modal>
    </div>
  );
}

export default NegotiationModal;
