import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import DotMenu from "assets/images/icon/svg/DotMenu";
import AddBankBottomSheet from "components/mobile-components/AddBankAccount/AddBankBottomSheet";
import AddedBankList from "components/mobile-components/AddBankAccount/AddedBankList";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddBankBottomSheet } from "redux/container/userSlice";
import Business from "services/Business";

export default function AddedBankListPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [banks, setBanks] = useState([]);
  const [empLoading, setEmpLoading] = useState(false);
  const dispatch = useDispatch();

  const banksSearch = async () => {
    setEmpLoading(true);
    let response = await Business.getBankList();
    if (response.status === 200) {
      setBanks(response.results);
      setEmpLoading(false);
    } else {
      setEmpLoading(false);
    }
  };
  useEffect(() => {
    banksSearch();
  }, []);

  return (
    // <IonApp>
    <div className="business-name">
      {/* <IonContent> */}
      {/* <BackButtonTitle
        title={ekkLanguage.addedBankList.pageTitle}
        from="addedBankList"
      /> */}
      <div className="flex justify-between items-center w-full px-8">
        <h5 className="font-bold text-lg">
          {ekkLanguage.addedBankList.pageTitle}
        </h5>
        <div className="pb-2">
          <span onClick={() => dispatch(setAddBankBottomSheet(true))}>
            <DotMenu color="black" />
          </span>
        </div>
      </div>

      {empLoading && (
        <IonLoading isOpen={true} message={ekkLanguage.orderList?.loader} />
      )}
      <section className=" px-2">
        <AddedBankList banks={banks} ekkLanguage={ekkLanguage} />
      </section>
      <AddBankBottomSheet />
      {/* </IonContent> */}
    </div>
    // </IonApp>
  );
}
