import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import { getLanguage } from "config/language";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "config/language";
import { selectLan } from "redux/container/languageSlice";
import { useNavigate } from "react-router-dom";

export default function ChangeLanguage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  let dispatch = useDispatch();
  const [selectedLan, setSelectedLan] = useState(getLanguage());
  let navigate = useNavigate();

  const languageSubmitHandler = () => {
    if (selectedLan !== "") {
      setLanguage(selectedLan);
      dispatch(selectLan());
      navigate("/dashboard", { replace: true });
    }
  };
  return (
    <div className="px-2 pt-2 relative change-password-screen change-language">
      <h4 className="font-bold text-xl pb-5">Change Language</h4>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div>
              <IonRadioGroup
                value={selectedLan}
                onIonChange={(e) => {
                  setSelectedLan(e.detail.value);
                }}
              >
                <IonItem
                  lines="none"
                  className="bg-white border rounded-[10px] mb-5 relative"
                >
                  <IonLabel className="absolute left-8 border-0 font-sans text-14 text-black-500 font-medium ">
                    <div className="flex">
                      <span className="ml-2">বাংলা</span>
                    </div>
                  </IonLabel>
                  <IonRadio value="bn" color="success" />
                </IonItem>

                <IonItem
                  lines="none"
                  className="bg-white border rounded-[10px] mb-5 relative"
                >
                  <IonLabel className="absolute left-8 border-0 font-sans text-14 text-black-500 font-medium">
                    <div className="flex">
                      <span className="ml-2">English</span>
                    </div>
                  </IonLabel>
                  <IonRadio value="en" color="success" />
                </IonItem>

                <IonItem
                  lines="none"
                  className="bg-white border rounded-[10px] mb-5 relative"
                >
                  <IonLabel className="w-full absolute left-8 border-0 font-sans text-14 text-black-500 font-medium">
                    <div className="flex">
                      <span className="ml-2">Tiếng Việt</span>
                    </div>
                  </IonLabel>
                  <IonRadio value="vn" color="success" />
                </IonItem>
                <IonItem
                  lines="none"
                  className="bg-white border rounded-[10px] mb-5 relative"
                >
                  <IonLabel className="w-full absolute left-8 border-0 font-sans text-14 text-black-500 font-medium">
                    <div className="flex">
                      <span className="ml-2">Malay</span>
                    </div>
                  </IonLabel>
                  <IonRadio value="my" color="success" />
                </IonItem>
              </IonRadioGroup>
            </div>
          </IonCol>
        </IonRow>
        <div className="submit-button-wrapper w-full pr-6 pt-10">
          <IonRow>
            <IonCol size="12">
              <div className="text-center">
                <IonButton
                  onClick={languageSubmitHandler}
                  expand="full"
                  className="bg-success rounded-[7px] font-extrabold text-12 h-10"
                >
                  {ekkLanguage.changeLanguages.btnLabel}
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </div>
      </IonGrid>
    </div>
  );
}
