// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#forgeViewer {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #F0F8FF;
}`, "",{"version":3,"sources":["webpack://./src/components/mobile-components/BazAI/Viewer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,SAAS;IACT,yBAAyB;AAC7B","sourcesContent":["#forgeViewer {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    background-color: #F0F8FF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
