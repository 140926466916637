import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import UpgradePlan from "components/mobile-components/UpgradePlan";
import SubscriptionPlan from "components/mobile-components/UpgradePlan/SubscriptionPlan";
import TransactionHistory from "components/mobile-components/UpgradePlan/TransactionHistory";
import { getUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUpgradePlan } from "redux/container/SubscriptionSlice";
import Payment from "services/Payment";
export default function UpgradePlanPage() {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const selected = useSelector((state) => state.subscription?.upgradePlan);
  const dispatch = useDispatch();
  const [subscriptions, setSubscriptions] = useState(null);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const business = getUserBusiness();

  const getSelectedSubscriptionPlan = async () => {
    setLoadingPlan(true);
    let response = await Payment.getSelectedSubscriptionPlan(business.id);
    if (response.success) {
      if (response?.results?.id) {
        setLoadingPlan(false);
        setSelectedPlan(response?.results);
      } else {
        // setLoadingPlan(false);
      }
    } else {
      getSubscriptionPlan();
      setLoadingPlan(true);
    }
  };
  const getSubscriptionPlan = async () => {
    setLoadingPlan(true);
    let response = await Payment.subscriptionPlan();
    if (response.success) {
      setLoadingPlan(false);
      setSubscriptions(response?.results);
    } else {
      setLoadingPlan(false);
    }
  };

  useEffect(() => {
    getSelectedSubscriptionPlan();
    // getSubscriptionPlan();
    dispatch(setUpgradePlan());
  }, []);

  return (
    // <IonApp>
    // 	<IonPage>
    <div>
      {/* <BackButtonTitle title={ekkLanguage.paymentModule.pageTitle} /> */}
      <section
        style={{
          background:
            "linear-gradient(180deg, #44CA5F 0%, #48B1D3 67.28%, #FFFFFF 100%)",
          content: "",
        }}
        className="pt-14 relative w-full h-full pb-10 rounded-md"
      >
        {loadingPlan && (
          <IonLoading
            isOpen={true}
            message={ekkLanguage.orderList?.loader}
          ></IonLoading>
        )}
        {!loadingPlan && selectedPlan?.id ? (
          <SubscriptionPlan selected={selectedPlan?.plan} />
        ) : (
          <UpgradePlan selected={selected} subscriptions={subscriptions} />
        )}
        <div className="pb-5">
          {" "}
          <TransactionHistory />
        </div>
      </section>
    </div>
    // 	</IonPage>
    // </IonApp>
  );
}
