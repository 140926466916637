import BazAi from "components/mobile-components/BazAI";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-pro-sidebar/dist/css/styles.css";
import { hasActive } from "helpers/helper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NotificationList from "components/mobile-components/Notifications";
import { getUserBusiness } from "config/user";
import { getLanguage } from "config/language";
import Notification from "services/Notification";
import {
	setCustomerUnreadCount,
	setNotification,
	setSupplierUnreadCount,
} from "redux/container/notificationSlice";
import { clearThread } from "redux/container/bazAiSlice";
import Supplier from "assets/images/icon/svg/Supplier";
import Chart from "assets/images/icon/svg/Chart";
import Order from "assets/images/icon/svg/Order";
import BottomMenu from "components/global-components/common/BottomMenu";
import BazAiApi from "services/BazAi";
import moment from "moment";
import { IonLoading, IonToggle } from "@ionic/react";
import { toast } from "react-toastify";

export default function BusinessRulesPage() {
	const contentRef = useRef();
	const messageData = useSelector((state) => state?.bazAi?.data);
	const { pathname } = useLocation();
	const [loading, setLoading] = useState(false);
	const [notificationList, setNotificationList] = useState([]);
	const [notificationType, setNotificationType] = useState("customer");
	const dispatch = useDispatch();
	const id = getUserBusiness()?.id;
	const localLanguage = getLanguage();
	const [notificationCount, setNotificationCount] = useState("");
	const business = getUserBusiness();
	const navigate = useNavigate();
	const [businessRules, setBusinessRules] = useState([]);
	const [selectedIndex, setSelectedIndex] = useState({});
	const [selectedRule, setSelectedRule] = useState({});
	const [editRule, setEditRule] = useState(false);
	const [comment, setComment] = useState("");
	const [createNewRule, setCreateRule] = useState(false);
	const [createRuleContent, setCreateRuleContent] = useState("");
	const [toggleRules, setToggleRules] = useState(false);

	const openEditRule = () => {
		setEditRule(true);
		if (selectedRule?.is_active) {
			setToggleRules(true);
		} else {
			setToggleRules(false);
		}
	};
	const closeEditRule = () => setEditRule(false);

	var windowSize = window.matchMedia("(max-width: 1100px)");

	const [showLeft, setShowLeft] = useState(!windowSize.matches ? true : false);
	const [showRight, setShowRight] = useState(false);

	const scrollToBottom = () => {
		if (contentRef.current) {
			contentRef.current.scrollToBottom();
		}
	};
	useEffect(() => {
		scrollToBottom();
	}, [messageData.length]);

	const menuIconClick = () => {
		setShowLeft(!showLeft);
	};

	let textContentRef = useRef();

	let getNotificationList = async () => {
		let response = await Notification.getProcurementNotifications();
		if (response.success) {
			setNotificationCount(response?.unread);
			dispatch(setNotification([...notificationList, ...response?.results]));
			setNotificationList([...notificationList, ...response?.results]);
		}
	};
	let getNotificationListDetails = async (selectedNotification) => {
		console.log(selectedNotification);
		navigate(
			`/procurement-order-details/${Number(
				selectedNotification?.service_field_id
			)}/${business?.role}`
		);
		let response = await Notification.getProcurementNotificationsDetails(
			selectedNotification?.id
		);
		if (response.success) {
			dispatch(setNotification([...notificationList, ...response?.results]));
			setNotificationList([...notificationList, ...response?.results]);
		}
	};

	useEffect(() => {
		getNotificationList();
	}, [notificationType]);

	let getBusinessRulesInitLoading = async () => {
		setLoading(true);
		let response = await BazAiApi.getBusinessRules();
		if (response.success) {
			setLoading(false);
			setBusinessRules(response.results);
			let activeItem = response.results?.find((el) => el?.is_active);
			setSelectedRule({
				...activeItem,
				initRules: activeItem?.rules,
			});
		} else {
			setLoading(false);
			setBusinessRules([]);
		}
	};

	let getBusinessRules = async () => {
		let response = await BazAiApi.getBusinessRules();
		if (response.success) {
			setBusinessRules(response.results);
			let activeItem = response.results?.find((el) => el?.is_active);
			setSelectedRule({
				...activeItem,
				initRules: activeItem?.rules,
			});
		} else {
			setBusinessRules([]);
		}
	};
	useEffect(() => {
		getBusinessRulesInitLoading();
	}, []);

	const handleRulesClick = (rule, i) => {
		if (rule?.is_active) {
			setToggleRules(true);
		} else {
			setToggleRules(false);
		}
		setSelectedRule({ ...rule, initRules: rule?.rules });
	};
	const textareaRef = useRef(null);
	const [height, setHeight] = useState(50);
	const handleInputChange = (event) => {
		const textarea = textareaRef.current;
		const scrollHeight = textarea.scrollHeight;
		if (scrollHeight <= 50) {
			setHeight(50);
		} else if (scrollHeight <= 80) {
			setHeight(80);
		} else if (scrollHeight <= 110) {
			setHeight(110);
		} else {
			setHeight(110);
		}
		if (!event.target.value) {
			setHeight(50);
		}
	};
	useEffect(() => {
		if (!comment) {
			setHeight(50);
		}
	}, [comment]);

	const handleChange = (event) => {
		setComment(event.target.value);
		let data = { ...selectedRule, rules: event.target.value };
		setSelectedRule(data);
		if (selectedRule?.initRules !== event.target.value) {
			setToggleRules(false);
		} else {
			if (selectedRule?.is_active) {
				setToggleRules(true);
			}
		}
	};
	const createNewRuleHandler = () => {
		setCreateRule(true);
		setToggleRules(false);
	};
	const createANewRuleHandler = async () => {
		if (createRuleContent) {
			let payload = {
				rules: createRuleContent,
				teammate: "Sia ",
				is_active: true,
			};
			let response = await BazAiApi.createBusinessRules(payload);
			if (response.success) {
				getBusinessRules();
				setCreateRule(false);
				setToggleRules(false);
				toast.success("Rule added successfully");
			} else {
			}
		} else {
			toast.warning("please write business rule.");
		}
	};
	const updateNewRuleHandler = async () => {
		if (selectedRule?.rules) {
			let payload = {
				rules: selectedRule?.rules,
				is_active: true,
			};
			let response = await BazAiApi.updateBusinessRules(
				selectedRule?.id,
				payload
			);
			if (response.success) {
				setSelectedRule({
					...selectedRule,
					initRules: selectedRule?.rules,
				});
				getBusinessRules();
				setEditRule(false);
				setCreateRule(false);
				toast.success("Rule updated successfully");
			} else {
			}
		} else {
			toast.warning("please write business rule.");
		}
	};
	return (
		<div
			className='relative bg-white h-screen flex flex-col overflow-y-auto'
			ref={textContentRef}>
			<BazAiNav
				title='Business Rules'
				menuIconClick={menuIconClick}
				showLeft={showLeft}
				showRight={showRight}
				setShowRight={setShowRight}
				notificationCount={notificationCount}
			/>
			{loading ? (
				<IonLoading
					isOpen={loading}
					message={"Please wait..."}
					spinner='circles'
				/>
			) : (
				<div className='mx-8'>
					<div className='grid grid-cols-1 lg:grid-cols-2 gap-8'>
						<div className='rounded-lg py-2.5'>
							{/* Left Pane (Table) */}
							<div
								className='bg-[#ffffff] rounded-[10px] mt-20'
								style={{
									boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
								}}>
								<h3 className='text-222222 font-bold text-24 py-2 px-4'>
									Business Rules
								</h3>
								<div className='border border-gray-300 rounded-lg p-4'>
									<div className=''>
										<table className='min-w-full divide-y divide-gray-200'>
											<thead>
												<tr className='bg-gray-500 text-white'>
													<th className='text-left px-4 py-2'>
														S/N
													</th>
													<th className='text-left px-4 py-2'>
														Teammate
													</th>
													{/* <th className="text-left px-4 py-2">Start Date</th> */}
													{/* <th className="text-left px-4 py-2">
                              Procurement Type
                            </th> */}
													<th className='text-left px-4 py-2'>
														Created By
													</th>
													<th className='text-left px-4 py-2'>
														Created On
													</th>

													<th className='text-left px-4 py-2'>
														Status
													</th>
												</tr>
											</thead>
											<tbody>
												{businessRules?.map((rule, index) => (
													<tr
														key={rule?.id}
														className={`$ ${
															index % 2 === 0
																? "bg-white"
																: "bg-gray-50"
														} h-12 cursor-pointer transition-colors duration-200 
                              ${
											businessRules === index
												? "bg-gray-400"
												: "hover:bg-gray-200"
										}`}
														onClick={() => {
															setCreateRule(false);
															setCreateRuleContent("");
															setEditRule(false);
															setSelectedIndex(index);
															handleRulesClick(rule, index);
														}}>
														<td className='text-black px-4 py-2'>
															{index + 1}
														</td>
														<td className='text-black px-4 py-2'>
															{rule?.teammate}
														</td>
														<td className='text-black px-4 py-2'>
															{rule?.created_by?.full_name}
														</td>
														<td className='text-black px-4 py-2'>
															{moment(rule?.createdAt).format(
																"DD-MM-YYYY"
															)}
														</td>
														{/* <td className='text-black px-4 py-2 text-center'>
														{rule?.rules}
													</td> */}

														<td className='px-4 py-2'>
															<span
																className={`${
																	rule?.is_active
																		? "bg-green-100 text-green-800"
																		: "bg-red-100 text-red-800"
																} rounded-full px-3 py-1 text-sm`}>
																{rule?.is_active
																	? "Active"
																	: "Disabled"}
															</span>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>

							{/* Right Pane (Details) */}
						</div>
						<div className='rounded-lg mt-2'>
							<div
								className='bg-[#ffffff] rounded-[10px] mt-20'
								style={{
									boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
								}}>
								<div className='flex justify-between items-center'>
									<h3 className='text-222222 font-bold text-24 py-2 px-4'>
										Business Rules for Sia
									</h3>
									{editRule ? (
										<div className='mr-4'>
											<IonToggle
												color='success'
												checked={toggleRules}
												onClick={() => {
													setToggleRules(!toggleRules);
													updateNewRuleHandler();
												}}></IonToggle>
										</div>
									) : undefined}
								</div>
								<div className=' p-4 min-h-[200px]'>
									{createNewRule ? (
										<div>
											<textarea
												ref={textareaRef}
												// onKeyDown={handleKeyDown}
												style={{
													minHeight: "300px",
													height: `${height}px`,
													// overflow:
													// 	height === 120 ? "auto" : "hidden",
													resize: "none",
													width: "100%",
													padding: "10px",
													paddingTop: "15px",
													boxSizing: "border-box",
													paddingRight: 35,
													borderRadius: 15,
													overflowY: "scroll",
												}}
												value={createRuleContent}
												onChange={(e) =>
													setCreateRuleContent(e.target.value)
												}
												type='text'
												placeholder='Create a new rule'
												className='gpt-text-area py-3.5 pr-10 px-3 appearance-none block mr-1 border leading-tight focus:outline-1 bg-white focus:bg-white text-14 w-full'
											/>
											<div className='text-center mt-4'>
												<button
													className={`${
														createRuleContent
															? "bg-success"
															: "bg-808080 cursor-not-allowed"
													} rounded-[15px] px-3 font-bold py-2 text-white mx-2`}
													onClick={() =>
														createRuleContent &&
														createANewRuleHandler()
													}>
													Create Rule
												</button>
											</div>
										</div>
									) : (
										<div>
											{!editRule ? (
												<p onClick={openEditRule}>
													{selectedRule?.rules
														?.split("\n")
														.map((str, index) => (
															<React.Fragment key={index}>
																{str}
																<br />
															</React.Fragment>
														))}
												</p>
											) : (
												<div>
													<textarea
														ref={textareaRef}
														// onKeyDown={handleKeyDown}
														style={{
															minHeight: "300px",
															height: `${height}px`,
															// overflow:
															// 	height === 120 ? "auto" : "hidden",
															resize: "none",
															width: "100%",
															padding: "10px",
															paddingTop: "15px",
															boxSizing: "border-box",
															paddingRight: 35,
															borderRadius: 15,
															overflowY: "scroll",
														}}
														value={selectedRule?.rules}
														onChange={(e) => handleChange(e)}
														type='text'
														placeholder='Update selected rule'
														className='gpt-text-area py-3.5 pr-10 px-3 appearance-none block mr-1 border leading-tight focus:outline-1 bg-white focus:bg-white text-14 w-full'
													/>
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<BottomMenu createNewRuleHandler={createNewRuleHandler} />
		</div>
	);
}
