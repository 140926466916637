export const MOCK = {
	pass: "https://dpassapi.azurewebsites.net",
	business: "https://dbusiness.ekkbaz.com",
	product: "https://dproduct.ekkbaz.com",
	order: "https://dorderapi.azurewebsites.net",
	bucket: "https://dfileapi.azurewebsites.net",
	bizNpdt: "https://dbiznpdtapi.azurewebsites.net",
	social: "https://dsocialapi.azurewebsites.net",
	pay: "https://dpayapi.azurewebsites.net",
	rtc: "https://drtc2api.azurewebsites.net",
	loan: "https://dfinsptapi.azurewebsites.net",
	audio: "https://dbazaiapi.azurewebsites.net/",
	sitekey: "6LfXo6ckAAAAAGYkJ1BzEihBX2MtTWpoKlAaVjGw",
	mapKey: "AIzaSyBQ_daY075e4mISHGgNdjV5boeD_P73OV8",
	cms: "https://dcontractapi.azurewebsites.net",
	aiAgent: "https://dbazaiagentsapi.azurewebsites.net",
	stripPublicKey:
		"pk_test_51OkQanFSpB0NItUGKBbMnUsFqFgMWagyLQRWruC8DKMuaecguoEF9KRKAfMeTm8mklpEsoApABjeZaEkZpfS2hcf00u5J3OPmF",
	openAiKey:
		"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",
};
// export var baseUrl = MOCK;
export const DEVELOPMENT = {
	pass: "https://dpassapi.azurewebsites.net",
	business: "https://dbusiness.ekkbaz.com",
	product: "https://dproduct.ekkbaz.com",
	order: "https://dorderapi.azurewebsites.net",
	bucket: "https://dfileapi.azurewebsites.net",
	bizNpdt: "https://dbiznpdtapi.azurewebsites.net",
	social: "https://dsocialapi.azurewebsites.net",
	pay: "https://dpayapi.azurewebsites.net",
	rtc: "https://drtc2api.azurewebsites.net",
	loan: "https://dfinsptapi.azurewebsites.net",
	audio: "https://dbazaiapi.azurewebsites.net/",
	sitekey: "6LfXo6ckAAAAAGYkJ1BzEihBX2MtTWpoKlAaVjGw",
	mapKey: "AIzaSyBQ_daY075e4mISHGgNdjV5boeD_P73OV8",
	cms: "https://dcontractapi.azurewebsites.net",
	aiAgent: "https://dbazaiagentsapi.azurewebsites.net",
	procurement: "https://dprocurementapi.azurewebsites.net",
	stripPublicKey:
		"pk_test_51OkQanFSpB0NItUGKBbMnUsFqFgMWagyLQRWruC8DKMuaecguoEF9KRKAfMeTm8mklpEsoApABjeZaEkZpfS2hcf00u5J3OPmF",
	openAiKey:
		"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",
};
// export var baseUrl = DEVELOPMENT;

export const PRODUCTION = {
	pass: "https://ppassapi.azurewebsites.net",
	business: "https://pbusiness.ekkbaz.com",
	product: "https://pproduct.ekkbaz.com",
	order: "https://porderapi.azurewebsites.net",
	bucket: "https://pfileapi.azurewebsites.net",
	bizNpdt: "https://pbiznpdtapi.azurewebsites.net",
	social: "https://psocialapi.azurewebsites.net",
	pay: "https://ppayapi.azurewebsites.net",
	rtc: "https://prtcapi.azurewebsites.net",
	loan: "https://pfinsptapi.azurewebsites.net",
	audio: "https://pbazaiapi.azurewebsites.net/",
	sitekey: "6LfXo6ckAAAAAGYkJ1BzEihBX2MtTWpoKlAaVjGw",
	mapKey: "AIzaSyBQ_daY075e4mISHGgNdjV5boeD_P73OV8",
	cms: "https://pcontractapi.azurewebsites.net",
	aiAgent: "https://pbazaiagentsapi.azurewebsites.net",
	procurement: "https://pprocurementapi.azurewebsites.net",
	stripPublicKey:
		"pk_live_51OkQanFSpB0NItUG28hkYHwkWh0sJyEWzbBdfmr1aIVeWpINgXhUTRcC0cR7mDu11vAxGf6BYTNUE9FuWYNEb4Z700KMP7Ducf",
	openAiKey:
		"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",
};
export var baseUrl = PRODUCTION;
