import React, { useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { useGLTF, OrbitControls, Environment } from "@react-three/drei";
import { setBomMaterials, setUpdateMessage } from "redux/container/bazAiSlice";
import { useDispatch } from "react-redux";
import Procurement from "services/Procurement";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

function Model({ messageData, data, file, setMaterials }) {
	const { scene } = useGLTF(file);
	const dispatch = useDispatch();

	// Traverse the scene to adjust materials and orientation
	scene.traverse((child) => {
		if (child.isMesh) {
			// Adjust material emissive color for better visibility
			child.material.emissive = child.material.color
				.clone()
				.multiplyScalar(0.1);
			child.material.needsUpdate = true;
		}
	});
	const updateProductDataHandler = (procurement, bom, email, cad) => {
		let data = [
			{
				id: messageData?.length + 1,
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				...bom,
			},
			{
				id: messageData?.length + 3,
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				...procurement,
			},

			{
				id: messageData?.length + 2,
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				...email,
			},
		];

		dispatch(setUpdateMessage(data));
	};
	useEffect(() => {
		const materials = [];
		// Traverse the scene to collect materials
		scene.traverse((child) => {
			if (child.isMesh && child.material) {
				// Extract relevant material properties
				const materialData = {
					name: child.material.name || `Material_${materials.length + 1}`, // Material name
					type: child.material.type || "Unknown", // Material type
					uuid: child.material.uuid, // Unique ID
					color: child.material.color?.getHexString() || null, // Base color
					emissive: child.material.emissive?.getHexString() || null, // Emissive color
					roughness:
						child.material.roughness !== undefined
							? child.material.roughness
							: null, // Roughness value
					metalness:
						child.material.metalness !== undefined
							? child.material.metalness
							: null, // Metalness value
					opacity:
						child.material.opacity !== undefined
							? child.material.opacity
							: 1, // Opacity value
					transparent: child.material.transparent || false, // Transparency flag
					side: child.material.side, // Material side (front, back, or double)
					envMapIntensity: child.material.envMapIntensity || 1, // Environment map intensity
					blendColor: child.material.blendColor?.getHexString() || null, // Blend color
					envMapRotation: child.material.envMapRotation || [
						0,
						0,
						0,
						"XYZ",
					], // Environment map rotation
					alphaTest: child.material.alphaTest || 0, // Alpha test threshold
					depthWrite: child.material.depthWrite || false, // Depth write flag
					depthTest: child.material.depthTest || false, // Depth test flag
				};

				materials.push(materialData);
			}
		});

		// Dispatch the collected materials
		dispatch(setBomMaterials(materials));
		setMaterials(materials); // Pass materials as JSON to the parent

		// Only trigger the API call once, after materials are populated

		if (materials?.length) {
			sendBinaryToServer({ materials })
				.then((response) => {
					if (response) {
						updateProductDataHandler(
							{
								type: "success",
								cardType: "procurementCard",
								supplier: response?.business_relation_data,
								produermenId: response?.results?.id,
							},
							{
								type: "success",
								jsonUrl: response?.files?.json_url,
								bomJson: response?.json_bom_data,
								cardType: "bomCard",
							},
							{
								mailContent: response?.email_content,
								initMailContent: response?.email_content,
								emailAddress: response?.business_relation_data?.map(
									(supplier) => ({ email: supplier?.email })
								),

								produermenId: response?.results?.id,
								message: response?.business_relation_data?.length
									? `${response?.business_relation_data?.length} Suppliers successfully invited by email.`
									: "No exact or most similar supplier found for send invitation.",
								type: "success",
								cardType: "emailCard2",
								mailSend: false,
							}
							// {
							// 	mailContent: response?.email_content,
							// 	initMailContent: response?.email_content,
							// 	emailAddress: response?.business_relation_data?.map(
							// 		(supplier) => ({ email: supplier?.email })
							// 	),
							// 	produermenId: response?.results?.id,
							// 	type: "success",
							// 	cardType: "emailCard",
							// 	mailSend: false,
							// }
						);
					}
				})
				.catch((error) => {
					console.error("Error uploading file:", error);
				});
		}
	}, [scene]);

	const sendBinaryToServer = async ({ materials }) => {
		const formData = new FormData();
		formData.append("cad_file", data?.cadFile);
		// formData.append("cad_data_file", jsonFile);
		formData.append("cad_data", JSON.stringify(materials));

		try {
			let response = await Procurement.uploadGLBFile(formData);
			if (response.status === 200 || response.status === 201) {
				return response;
			} else {
				throw new Error("Failed to upload file.");
			}
		} catch (error) {
			console.error("Failed to send file to server:", error);
		}
	};

	// useEffect(() => {
	// 	const materials = [];
	// 	// Traverse the scene to collect materials
	// 	scene.traverse((child) => {
	// 		if (child.isMesh && child.material) {
	// 			// Extract relevant material properties
	// 			const materialData = {
	// 				name: child.material.name || `Material_${materials.length + 1}`, // Material name
	// 				type: child.material.type || "Unknown", // Material type
	// 				uuid: child.material.uuid, // Unique ID
	// 				color: child.material.color?.getHexString() || null, // Base color
	// 				emissive: child.material.emissive?.getHexString() || null, // Emissive color
	// 				roughness:
	// 					child.material.roughness !== undefined
	// 						? child.material.roughness
	// 						: null, // Roughness value
	// 				metalness:
	// 					child.material.metalness !== undefined
	// 						? child.material.metalness
	// 						: null, // Metalness value
	// 				opacity:
	// 					child.material.opacity !== undefined
	// 						? child.material.opacity
	// 						: 1, // Opacity value
	// 				transparent: child.material.transparent || false, // Transparency flag
	// 				side: child.material.side, // Material side (front, back, or double)
	// 				envMapIntensity: child.material.envMapIntensity || 1, // Environment map intensity
	// 				blendColor: child.material.blendColor?.getHexString() || null, // Blend color
	// 				envMapRotation: child.material.envMapRotation || [
	// 					0,
	// 					0,
	// 					0,
	// 					"XYZ",
	// 				], // Environment map rotation
	// 				alphaTest: child.material.alphaTest || 0, // Alpha test threshold
	// 				depthWrite: child.material.depthWrite || false, // Depth write flag
	// 				depthTest: child.material.depthTest || false, // Depth test flag
	// 			};

	// 			materials.push(materialData);
	// 		}
	// 	});
	// 	dispatch(setBomMaterials(materials));
	// 	setMaterials(materials); // Pass materials as JSON to the parent
	// }, [scene, setMaterials]);

	return (
		<primitive
			object={scene}
			scale={[2.3, 3.3, 2.3]} // Adjust scale for full coverage
			rotation={[0, Math.PI, 0]} // Rotate 180 degrees for back-to-front alignment
			position={[0, 0.5, 0]} // Adjust vertical position
			dispose={null}
		/>
	);
}

export default function CADPreviewForBazAI({ data, fileUrl, setMaterials }) {
	return (
		<Canvas
			camera={{ position: [0, 2, 8], fov: 50 }} // Adjusted camera for better framing
			style={{ width: "100%", height: "480px" }}
			// take the camera full height
			fullHeight>
			{/* Ambient light for global illumination */}
			<ambientLight intensity={0.8} />
			{/* Spotlight to highlight the model */}
			<spotLight
				position={[10, 20, 10]}
				angle={0.5}
				penumbra={1}
				intensity={1.5}
				castShadow
			/>
			{/* Optional HDRI environment for realistic lighting */}
			<Environment preset='sunset' />

			<Suspense fallback={null}>
				<Model data={data} file={fileUrl} setMaterials={setMaterials} />
			</Suspense>

			{/* Controls for model interaction */}
			<OrbitControls enablePan={false} enableZoom={true} autoRotate />
		</Canvas>
	);
}
