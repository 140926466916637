import React, { useEffect, useState, lazy, Suspense } from "react";
import { IonApp, IonContent, IonPage } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import DashboardBottomSheet from "components/mobile-components/DashBoard/DashboardBottomSheet";
import { getUser, getUserBusiness } from "config/user";
import Notification from "services/Notification";
import {
  setCollectionDate,
  setCollectorData,
  setDriverData,
} from "redux/container/driverSlice";
import {
  setCustomerUnreadCount,
  setSupplierUnreadCount,
} from "redux/container/notificationSlice";
import { setCountryInfo, setWarning } from "redux/container/userSlice";
import OverView from "components/mobile-components/Home/OverView";
import { getLanguage } from "config/language";
import Business from "services/Business";
import { setSubscriptionPackage } from "redux/container/SubscriptionSlice";
import User from "services/User";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import BottomMenu from "components/global-components/common/BottomMenu";

const Dashboard = lazy(() => import("components/mobile-components/DashBoard"));

export default function DashboardPage() {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();
  const id = getUserBusiness()?.id;
  const localLanguage = getLanguage();

  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  useEffect(() => {
    dispatch(setWarning(true));
    let getUnreadCount = async () => {
      dispatch(setDriverData(""));
      if (id) {
        let response1 = await Notification.getNewCustomerUnreadCount(
          id,
          localLanguage === "bn" ? "BD" : "EN"
        );
        if (response1.success) {
          dispatch(setCustomerUnreadCount(response1?.unread_count));
        }
        let response = await Notification.getNewSupplierUnreadCount(
          id,
          localLanguage === "bn" ? "BD" : "EN"
        );
        if (response.success) {
          dispatch(setSupplierUnreadCount(response?.unread_count));
        }
      }
    };
    getUnreadCount();
  }, [id, dispatch]);

  const getCountryInfo = async () => {
    let response = await Business.getCountry(getUserBusiness()?.country);
    if (response.status === 200) {
      dispatch(setCountryInfo(response.results));
    }
  };

  const getUserPackage = async () => {
    let response = await User.getUserPackagePlan(id);
    if (response.status === 200) {
      dispatch(setSubscriptionPackage(response.results));
    }
  };

  useEffect(() => {
    dispatch(setCollectorData({}));
    dispatch(setCollectionDate(""));
    getCountryInfo();
    getUserPackage();
  }, []);

  return (
    // <IonApp>
    // 	<IonPage>
    <div>
      {/* <LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.letNav.dashboardProfileMenuLabel}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
						from='dashboard'
					/> */}
      <BazAiNav />
      <DashboardBottomSheet />

      <section className="pt-14 px-2">
        <Suspense
          fallback={
            <div>
              <div className="preloader">
                <div style={{ textAlign: "center" }}>
                  <div className="lds-dual-ring"></div>
                </div>
                <h3 className="text-bold text-20">Loading...</h3>
              </div>
            </div>
          }
        >
          <div className="pt-5 px-4">
            <OverView ekkLanguage={ekkLanguage} />
          </div>

          <Dashboard />
        </Suspense>
        <BottomMenu />
      </section>
    </div>
    // 	</IonPage>
    // </IonApp>
  );
}
