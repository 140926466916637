import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useFBX } from "@react-three/drei";

const FBXViewer = ({ fileUrl }) => {
  const Model = ({ url }) => {
    const fbx = useFBX(url);
    return <primitive object={fbx} scale={0.01} dispose={null} />;
  };

  return (
    <Canvas
      style={{ width: "100%", height: "480px" }}
      camera={{ position: [0, 2, 10], fov: 50 }}
    >
      <ambientLight intensity={0.8} />
      <directionalLight position={[10, 10, 10]} />
      <Suspense fallback={null}>
        <Model url={fileUrl} />
      </Suspense>
      <OrbitControls autoRotate/>
    </Canvas>
  );
};

export default FBXViewer;
