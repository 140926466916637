import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProcurementInfoCard from "components/mobile-components/ProcurementOrderDetails.js/ProcurementInfoCard";
import BazAIStatusCard from "components/mobile-components/ProcurementOrderDetails.js/BazAIStatusCard";
import BazAIContactCard from "components/mobile-components/ProcurementOrderDetails.js/BazAIContactCard";
import DocumentsCard from "components/mobile-components/ProcurementOrderDetails.js/DocumentsCard";
import BidsCard from "components/mobile-components/ProcurementOrderDetails.js/BidsCard";
import WOCCard from "components/mobile-components/ProcurementOrderDetails.js/WOCCard";
import ActionButtonsCard from "components/mobile-components/ProcurementOrderDetails.js/ActionButtonsCard";
import Procurement from "services/Procurement";
import { useDispatch, useSelector } from "react-redux";
import {
	setBidList,
	setProcurementDetails,
} from "redux/container/procurementSlice";
import { IonLoading } from "@ionic/react";
import BidFeedbackModal from "components/mobile-components/ProcurementOrderDetails.js/BidsFeedBackModal";
import EvaluationFeedbackModal from "components/mobile-components/ProcurementOrderDetails.js/EvaluationFeedbackModal";
import AwardCard from "components/mobile-components/ProcurementOrderDetails.js/AwardCard";

export default function ProcurementOrderDetailsPage() {
	const dispatch = useDispatch();
	const { id, from } = useParams();
	const [loading, setLoading] = useState(true);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalContent, setModalContent] = useState(null);
	const [updatePage, setUpdatePage] = useState(false);
	const procurementDetails = useSelector((state) => state.procurement.details);
	const bidList = useSelector((state) => state.procurement.bids);
	const [hasJustification, setHasJustification] = useState(true);

	// Handle decision click function
	const handleDecisionClick = (decisionType) => {
		setModalContent(decisionType);
		setModalIsOpen(
			decisionType === "Accept" || decisionType === "Reject" ? true : false
		);
	};

	// Handle modal close function
	const handleModalClose = () => {
		setModalIsOpen(false);
		setModalContent(null);
	};

	const getProcurementList = async () => {
		const response = await Procurement.procurementDetails(id);
		if (response.status === 200) {
			dispatch(setProcurementDetails(response?.results));
			setHasJustification(
				response?.results?.waiver_of_competition ? true : false
			);
			let res = await Procurement.bidList(id);
			if (res.status === 200 || res.status === 404) {
				dispatch(setBidList(res?.results));
				setLoading(false);
				setUpdatePage(false);
			}
		} else {
			console.log("Error fetching procurement details", response);
		}
	};

	useEffect(() => {
		getProcurementList();
	}, []);
	useEffect(() => {
		if (updatePage) {
			setLoading(true);
			getProcurementList();
		}
	}, [updatePage]);
	return (
		<div>
			<BackButtonTitle title='Baz AI' from='procurementOrderDetails' />
			{loading ? (
				<IonLoading
					isOpen={true}
					message='Please wait...'
					spinner='bubbles'
				/>
			) : (
				<div className='container'>
					<section className='px-2 pt-10'>
						<BazAIStatusCard procurementDetails={procurementDetails} />
						<ProcurementInfoCard
							procurementDetails={procurementDetails}
						/>
						<BazAIContactCard procurementDetails={procurementDetails} />
						<WOCCard
							procurementDetails={procurementDetails}
							modalIsOpen={modalIsOpen}
							modalContent={modalContent}
							handleModalClose={handleModalClose}
							setUpdatePage={setUpdatePage}
						/>
						{((procurementDetails?.status === "Submission" &&
							!hasJustification) ||
							procurementDetails?.status === "Divisional Approval" ||
							procurementDetails?.status === "Published" ||
							procurementDetails?.status === "Bidding" ||
							procurementDetails?.status === "Evaluation" ||
							procurementDetails?.status === "Negotiation" ||
							procurementDetails?.status === "Awarded") && (
							<DocumentsCard />
						)}

						{(procurementDetails?.status === "Published" ||
							procurementDetails?.status === "Bidding" ||
							procurementDetails?.status === "Evaluation" ||
							procurementDetails?.status === "Negotiation" ||
							procurementDetails?.status === "Awarded") && (
							<>
								<BidsCard bidList={bidList} />
							</>
						)}
						{(procurementDetails?.status === "Published" ||
							procurementDetails?.status === "Bidding" ||
							procurementDetails?.status === "Evaluation" ||
							procurementDetails?.status === "Negotiation") &&
							from !== "Procurement Staff" && (
								<div className='grid lg:grid-cols-3 grid-cols-1 gap-5 w-full px-4'>
									<EvaluationFeedbackModal
										bidList={bidList}
										setUpdatePage={setUpdatePage}
									/>
									<BidFeedbackModal
										bidList={bidList}
										procurementDetails={procurementDetails}
										setUpdatePage={setUpdatePage}
									/>
									<AwardCard
										bidList={bidList}
										from={from}
										setUpdatePage={setUpdatePage}
										updatePage={updatePage}
									/>
								</div>
							)}
						<ActionButtonsCard
							status={procurementDetails?.status}
							onDecisionClick={handleDecisionClick}
							setUpdatePage={setUpdatePage}
							hasJustification={hasJustification}
							role={from}
						/>
					</section>
				</div>
			)}
		</div>
	);
}
