export const authBox_Block = {
  PHONE_NUMBER_BLOCK: 1,
  PASSWORD_BLOCK: 2,
  CHANGE_PASS_BLOCK: 3,
  TOKEN: 4,
  REGISTRATION_BLOCK: 5,
};

export const baz_Ai_Block = {
  MAIN_BAZ_AI: 1,
  PROCUREMENT: 2,
  ORDER: 3,
  SUPPLIER: 4,
};

export const profilePage_Block = {
  CHANGE_PASSWORD: 1,
  CHANGE_LANGUAGE: 2,
  UPDATE_LOCATION: 3,
  PAYMENT_TERM_SETTING: 4,
  BANK_LIST: 5,
  SUBSCRIPTION_PLAN: 6,
  KYC_INFORMATION: 7,
  DELETE_BUSINESS: 8,
};
