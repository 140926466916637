import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { clearThread } from "redux/container/bazAiSlice";

export default function BottomMenu({ createNewRuleHandler }) {
  const [showIcons, setShowIcons] = useState(true);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const toggleRight = () => {
    // Give time for the panel to slide in/out before showing/hiding icons
    setTimeout(() => {
      setShowIcons(!showIcons);
    }, 300); // 300ms matches the transition duration
  };
  return (
    <div className="z-50">
      {/* {pathname !== "/baz-ai" ? (
        <div
          style={{
            transition: ".3s all",
            transform: showIcons ? "rotate(135deg)" : "rotate(0deg)",
          }}
          className={`absolute flex items-center justify-center cursor-pointer w-full bg-[#d3d3d3] ${
            showIcons ? "red" : "green"
          } lg:bottom-5 lg:top-auto top-5 right-5 w-[50px] h-[50px] rounded-full `}
          onClick={toggleRight}
        >
          <h6 className="font-bold text-32">+</h6>
        </div>
      ) : (
        ""
      )} */}

      {showIcons && (
        <div
          className="fixed flex flex-col space-y-2 right-5 lg:bottom-5 bottom-24" // Added right-5 and bottom-[100px]
        >
          {pathname !== "/baz-ai" ? (
            <Link to="/baz-ai">
              <div
                style={{
                  color: "#000000",
                  width: 50,
                  borderRadius: "50%",
                  height: 50,
                  transition: "0.3s all",
                  transform: showIcons ? "translateY(0)" : "translateY(20px)",
                  //   opacity: showIcons ? 1 : 0,
                }}
                className=" flex items-center justify-center cursor-pointer bg-F3F3F3"
              >
                {/* <h6 className="font-bold text-24">M</h6> */}
                <img
                  className="w-6 h-6"
                  src="/assets/images/bot-m.png"
                  alt="img"
                />
              </div>
            </Link>
          ) : (
            ""
          )}
          {pathname === "/baz-ai" ? (
            <div
              onClick={() => dispatch(clearThread())}
              style={{
                color: "#000000",
                width: 50,
                borderRadius: "50%",
                height: 50,
                transition: "0.4s all",
                transform: showIcons ? "translateY(0)" : "translateY(20px)",
                //   opacity: showIcons ? 1 : 0,
              }}
              className="text-white flex items-center justify-center cursor-pointer text-30 font-bold bg-F3F3F3"
            >
              <ion-icon
                style={{ "--ionicon-stroke-width": "25px" }}
                name="add-circle"
                color="medium"
              ></ion-icon>
            </div>
          ) : (
            ""
          )}
          {pathname === "/business-rules" ? (
            <div
              onClick={() => createNewRuleHandler()}
              style={{
                color: "#000000",
                width: 50,
                borderRadius: "50%",
                height: 50,
                transition: "0.4s all",
                transform: showIcons ? "translateY(0)" : "translateY(20px)",
                //   opacity: showIcons ? 1 : 0,
              }}
              className="text-white flex items-center justify-center cursor-pointer text-30 font-bold bg-F3F3F3"
            >
              <ion-icon
                style={{ "--ionicon-stroke-width": "25px" }}
                name="add-circle"
                color="medium"
              ></ion-icon>
            </div>
          ) : (
            ""
          )}
          {pathname !== "/orders" ? (
            <Link to="/orders">
              <div
                style={{
                  color: "#000000",
                  width: 50,
                  borderRadius: "50%",
                  height: 50,
                  transition: "0.4s all",
                  transform: showIcons ? "translateY(0)" : "translateY(20px)",
                  //   opacity: showIcons ? 1 : 0,
                }}
                className=" flex items-center justify-center cursor-pointer bg-F3F3F3"
              >
                {/* <h6 className="font-bold text-24">M</h6> */}
                <img
                  className="w-6 h-6"
                  src="/assets/images/bazai/order.png"
                  alt="img"
                />
              </div>
            </Link>
          ) : (
            ""
          )}

          {pathname !== "/procurements" ? (
            <Link to="/procurements">
              <div
                style={{
                  background: "#D3D3D3",
                  color: "#000000",
                  width: 50,
                  borderRadius: "50%",
                  height: 50,
                  transition: "0.6s all",
                  transform: showIcons ? "translateY(0)" : "translateY(20px)",
                  //   opacity: showIcons ? 1 : 0,
                }}
                className="flex items-center justify-center cursor-pointer"
              >
                <img
                  className="w-6 h-6"
                  src="/assets/images/bazai/procurement.png"
                  alt="img"
                />
              </div>
            </Link>
          ) : (
            ""
          )}
          {pathname !== "/suppliers" ? (
            <Link to="/suppliers">
              <div
                style={{
                  background: "#D3D3D3",
                  color: "#000000",
                  width: 50,
                  borderRadius: "50%",
                  height: 50,
                  transition: "0.8s all",
                  transform: showIcons ? "translateY(0)" : "translateY(20px)",
                  //   opacity: showIcons ? 1 : 0,
                }}
                className="flex items-center justify-center cursor-pointer"
              >
                <img
                  className="w-6 h-6"
                  src="/assets/images/bazai/supplier.png"
                  alt="img"
                />
              </div>
            </Link>
          ) : (
            ""
          )}
          {/* {pathname !== "/assets" ? (
            <Link to="/assets">
              <div
                style={{
                  background: "#D3D3D3",
                  color: "#000000",
                  width: 50,
                  borderRadius: "50%",
                  height: 50,
                  transition: "0.8s all",
                  transform: showIcons ? "translateY(0)" : "translateY(20px)",
                  //   opacity: showIcons ? 1 : 0,
                }}
                className="flex items-center justify-center cursor-pointer"
              >
                
                <img
                  className="w-6 h-6"
                  src="/assets/images/bazai/supplier.png"
                  alt="img"
                />
              </div>
            </Link>
          ) : (
            ""
          )} */}
        </div>
      )}
    </div>
  );
}
