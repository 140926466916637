import {
	getLocalUser,
	getUser,
	getUserBusiness,
	setProductSeller,
} from "config/user";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import {
	clearThread,
	procApiCall,
	setLoaderMessage,
	setNewMessage,
	setNewThread,
	setUpdateEmailContent,
	setUpdateItemContent,
	setUpdateMessage,
	setUpdateReceiverMessage,
} from "redux/container/bazAiSlice";
import { ReactMic } from "react-mic";
import moment from "moment";
import AudioPlayer from "./AudioPlayer";
import BazAiApi from "services/BazAi";
import { Link, useNavigate } from "react-router-dom";
import { addCartFormate, productAddToCart } from "helpers/shoppingCart";
import Orders from "services/Orders";
import User from "services/User";
import { useDropzone } from "react-dropzone";
import { IonCol, IonLoading, IonSpinner } from "@ionic/react";
import QtyController from "./QtyController";
import { toast } from "react-toastify";
import { setLocation } from "redux/container/userSlice";
import { baseUrl } from "config/apiUrl";
import OpenAI from "openai";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import parse from "html-react-parser";
import BazAiService from "services/BazAi";
import BazAiBottomChatArea from "./BazAiBottomChatArea";
import { getAuth } from "config/authFetch";
import CADPreviewForBazAI from "./CADPreviewForBazAI";
import ModelViewer from "./CADViewerWithServerURL";
import JSONPreview from "./JSONPreview";
import EmailEditorCard from "./EmailEditorCard";
import { v4 as uuidv4 } from "uuid";
import { digitChanger } from "helpers/helper";
import FBXViewer from "./FBXViewer";
import DXFViewer from "./DXFViewer";

export default function BazAi({ name = "name" }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const messageData = useSelector((state) => state?.bazAi?.data);
	const thread = useSelector((state) => state?.bazAi?.thread);
	const runningApiCalling = useSelector((state) => state?.bazAi);
	const user = getUser() ? getUser() : getLocalUser()?.user;
	const [ripple, setRipple] = useState(false);
	const [audioPlayer, setAudioPlayer] = useState(false);
	const [timer, setTimer] = useState(0);
	const [timerIntervalId, setTimerIntervalId] = useState();
	const [selectedProduct, setSelectedProduct] = useState();
	const [materials, setMaterials] = React.useState([]);
	const [files, setFiles] = useState([]);
	let [imageUrl, setImageUrl] = useState("");
	let [loading, setLoading] = useState(false);
	const [value, setValue] = useState(null);
	const navigate = useNavigate();
	const [comment, setComment] = useState("");
	const business = getUserBusiness();
	const [liked, setLiked] = useState(false);
	const [unliked, setUnliked] = useState(false);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const [selectedSuggestion, setSelectedSuggestion] = useState("");
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [feedback, setFeedback] = useState("");
	var windowSize = window.matchMedia("(max-width: 900px)");
	const [editEmail, SetEditEmail] = useState(false);
	const { token } = getAuth();

	const handleLike = () => {
		setLiked(true);
		setUnliked(false);
	};

	const handleUnlike = () => {
		setLiked(false);
		setUnliked(true);
		setShowSuggestions(true); // Show suggestions when "Unlike" is clicked
	};

	const handleSuggestionClick = (suggestion) => {
		setSelectedSuggestion(suggestion);
		setModalIsOpen(true); // Open modal when suggestion is clicked
		setShowSuggestions(false);
	};

	const handleModalClose = () => {
		setModalIsOpen(false);
		setSelectedSuggestion("");
	};

	const handleSubmit = () => {
		// Handle form submission here
		console.log(`Feedback for "${selectedSuggestion}":`, feedback);
		setModalIsOpen(false);
		setSelectedSuggestion("");
	};
	useEffect(() => {
		return async () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		};
	}, [name, files]);

	let data = [
		{
			cardType: "infoCard",
			id: 1,
			user_name: "Bot",
			receiver: true,
			message: `Hi ${
				user?.full_name ? user?.full_name : user.DisplayName
			},  Sia here. How can I help you?`,
			created_at: moment().format("LLLL"),
			avatar: "assets/images/bot-s.png",
			type: "initial",
			loading: false,
		},
	];
	useEffect(() => {
		if (!messageData?.length) {
			SetEditEmail(false);
			dispatch(setNewMessage(data));
		}
	}, [messageData]);

	const startTimer = () => {
		setTimer(0);
		const intervalId = setInterval(() => {
			setTimer((prevTimer) => prevTimer + 1);
		}, 1000);
		return intervalId;
	};

	const stopTimer = (intervalId) => {
		clearInterval(intervalId);
	};

	const handleTouchStart = () => {
		setRipple(true);
		const intervalId = startTimer();
		setTimerIntervalId(intervalId);
	};

	const handleTouchEnd = () => {
		setRipple(false);
		stopTimer(timerIntervalId);
		onStop();
		setTimer(0);
	};
	const onStop = async (recordedBlob) => {
		setSelectedProduct({});
		if (recordedBlob) {
			let url = URL.createObjectURL(recordedBlob?.blob);
			let data = {
				audioBlob: recordedBlob?.blob,
				audioUrl: url,
				type: "audio",
				loading: true,
			};
			newSenderItemHandler(data);

			let formData = new FormData();
			formData.append("audio_file", recordedBlob?.blob);

			const apiUrl = BazAiApi.bazAiVoiceURL();

			const headers = {
				Authorization: `${token}`,
			};

			try {
				const resp = await fetch(apiUrl, {
					method: "POST",
					headers: headers,
					body: formData,
				});

				if (!resp.ok) {
					console.error("Request failed with status:", resp.status);
					return;
				}

				const reader = resp.body.getReader();
				const decoder = new TextDecoder("utf-8");
				let jsonString = "";
				let done, value;
				while (true) {
					({ done, value } = await reader.read());
					if (done) break;
					jsonString += decoder.decode(value, { stream: true });
				}
				const response = JSON.parse(JSON.parse(jsonString));

				if (response) {
					let formattedSuppliers = Object.entries(
						response?.supplier_comparison
					).map(([supplier, price]) => {
						return { supplier, price };
					});

					updateProductDataHandler(
						{
							id: messageData?.length + 2,
							cardType: "analysisCard",
							productQty: response?.product_details,
							productInitQty: response?.product_details?.quantity
								? response?.product_details?.quantity
								: response?.product_details?.minimum_order_quantity,
							product: response?.product_details,
							type: "success",
							corns: response?.product_details?.product_risk_analysis,
							suppliers: formattedSuppliers,
						},
						{
							id: messageData?.length + 3,
							cardType: "productCard",
							productQty: response?.product_details,
							productInitQty: response?.product_details?.quantity
								? response?.product_details?.quantity
								: response?.product_details?.minimum_order_quantity,
							product: response?.results,
							message: `Create Order \nSupplier: ${
								response?.product_details?.seller_business_name
							} \n${response?.product_details?.title} \n${
								response?.product_details?.country
									?.currency_symbol_native
							}${response?.product_details?.procure_price}x${
								response?.product_details?.quantity
									? response?.product_details?.quantity
									: response?.product_details?.minimum_order_quantity
							} = ${
								response?.product_details?.country
									?.currency_symbol_native
							}${
								response?.product_details?.quantity
									? Number(
											response?.results?.procure_price *
												response?.product_details?.quantity
									  )
									: Number(
											response?.product_details?.procure_price *
												response?.product_details
													?.minimum_order_quantity
									  )
							}\nTotal = ${
								response?.product_details?.country
									?.currency_symbol_native
							}${
								response?.product_details?.quantity
									? Number(
											response?.product_details?.procure_price *
												response?.product_details?.quantity
									  )
									: Number(
											response?.product_details?.procure_price *
												response?.product_details
													?.minimum_order_quantity
									  )
							}`,
							suppliers: formattedSuppliers,
							type: "success",
							corns: response?.product_details?.product_risk_analysis,
						}
					);
				} else {
					updateLastDataHandler({
						message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
						type: "notFound",
						loading: false,
					});
				}

				if (response.status === 404) {
					updateLastDataHandler({
						message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
						type: "notFound",
						loading: false,
					});
				}
			} catch (error) {
				console.error(error);
				updateLastDataHandler({
					message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
					type: "notFound",
					loading: false,
				});
			}
		}
	};

	// const onStop = async (recordedBlob) => {
	// 	setSelectedProduct({});
	// 	if (recordedBlob) {
	// 		let url = URL.createObjectURL(recordedBlob?.blob);
	// 		let data = {
	// 			audioBlob: recordedBlob?.blob,
	// 			audioUrl: url,
	// 			type: "audio",
	// 			loading: true,
	// 		};
	// 		newSenderItemHandler(data);
	// 		let formData = new FormData();
	// 		formData.append("audio_file", recordedBlob?.blob);

	// 		const apiUrl = BazAiApi.bazAiVoiceURL();
	// 		const payload = {
	// 			data: formData,
	// 		};
	// 		console.log(payload);

	// 		const headers = {
	// 			Authorization: token,
	// 			"Content-Type": "application/json",
	// 		};
	// 		try {
	// 			const resp = await fetch(apiUrl, {
	// 				method: "POST",
	// 				headers: headers,
	// 				body: JSON.stringify(formData),
	// 			});
	// 			console.log(resp);
	// 			if (!resp.body) {
	// 				console.error("No body in the response");
	// 				return;
	// 			}
	// 			const reader = resp.body.getReader();
	// 			const decoder = new TextDecoder("utf-8");
	// 			let jsonString = "";
	// 			let done, value;
	// 			while (true) {
	// 				({ done, value } = await reader.read());
	// 				if (done) break;
	// 				jsonString += decoder.decode(value, { stream: true });
	// 				console.log(jsonString);
	// 			}
	// 			console.log(jsonString);
	// 			console.log(JSON.parse(jsonString));
	// 			const response = JSON.parse(jsonString);
	// 			if (response) {
	// 				let formattedSuppliers = Object.entries(
	// 					response?.supplier_comparison
	// 				).map(([supplier, price]) => {
	// 					return { supplier, price };
	// 				});
	// 				updateProductDataHandler(
	// 					{
	// 						id: messageData?.length + 2,
	// 						cardType: "analysisCard",
	// 						productQty: response?.product_details,
	// 						productInitQty: response?.product_details?.quantity
	// 							? response?.product_details?.quantity
	// 							: response?.product_details?.minimum_order_quantity,
	// 						product: response?.product_details,
	// 						type: "success",
	// 						corns: response?.product_details?.product_risk_analysis,
	// 						suppliers: formattedSuppliers,
	// 					},
	// 					{
	// 						id: messageData?.length + 3,
	// 						cardType: "productCard",
	// 						productQty: response?.product_details,
	// 						productInitQty: response?.product_details?.quantity
	// 							? response?.product_details?.quantity
	// 							: response?.product_details?.minimum_order_quantity,
	// 						product: response?.results,
	// 						message: `Create Order \nSupplier: ${
	// 							response?.product_details?.seller_business_name
	// 						} \n${response?.product_details?.title} \n${
	// 							response?.product_details?.country
	// 								?.currency_symbol_native
	// 						}${response?.product_details?.procure_price}x${
	// 							response?.product_details?.quantity
	// 								? response?.product_details?.quantity
	// 								: response?.product_details?.minimum_order_quantity
	// 						} = ${
	// 							response?.product_details?.country
	// 								?.currency_symbol_native
	// 						}${
	// 							response?.product_details?.quantity
	// 								? Number(
	// 										response?.results?.procure_price *
	// 											response?.product_details?.quantity
	// 								  )
	// 								: Number(
	// 										response?.product_details?.procure_price *
	// 											response?.product_details
	// 												?.minimum_order_quantity
	// 								  )
	// 						}\nTotal = ${
	// 							response?.product_details?.country
	// 								?.currency_symbol_native
	// 						}${
	// 							response?.product_details?.quantity
	// 								? Number(
	// 										response?.product_details?.procure_price *
	// 											response?.product_details?.quantity
	// 								  )
	// 								: Number(
	// 										response?.product_details?.procure_price *
	// 											response?.product_details
	// 												?.minimum_order_quantity
	// 								  )
	// 						}`,
	// 						suppliers: formattedSuppliers,
	// 						type: "success",
	// 						corns: response?.product_details?.product_risk_analysis,
	// 					}
	// 				);
	// 			} else {
	// 				updateLastDataHandler({
	// 					message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
	// 					type: "notFound",
	// 					loading: false,
	// 				});
	// 			}
	// 			if (response.status === 404) {
	// 				updateLastDataHandler({
	// 					message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
	// 					type: "notFound",
	// 					loading: false,
	// 				});
	// 			}
	// 		} catch (error) {
	// 			console.log(error);

	// 			updateLastDataHandler({
	// 				message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
	// 				type: "notFound",
	// 				loading: false,
	// 			});
	// 		}
	// 		// if (response.success) {
	// 		// 	let formattedSuppliers = Object.entries(
	// 		// 		response?.result?.supplier_comparison
	// 		// 	).map(([supplier, price]) => {
	// 		// 		return { supplier, price };
	// 		// 	});
	// 		// 	updateProductDataHandler(
	// 		// 		{
	// 		// 			id: messageData?.length + 2,
	// 		// 			uid: uuidv4(),
	// 		// 			cardType: "analysisCard",
	// 		// 			productQty: response?.result?.product_details,
	// 		// 			productInitQty: response?.result?.product_details?.quantity
	// 		// 				? response?.result?.product_details?.quantity
	// 		// 				: response?.result?.product_details
	// 		// 						?.minimum_order_quantity,
	// 		// 			product: response?.result?.product_details,
	// 		// 			type: "success",
	// 		// 			corns: response?.result?.product_details
	// 		// 				?.product_risk_analysis,
	// 		// 			suppliers: formattedSuppliers,
	// 		// 		},
	// 		// 		{
	// 		// 			id: messageData?.length + 3,
	// 		// 			uid: uuidv4(),
	// 		// 			cardType: "productCard",
	// 		// 			productQty: response?.result?.product_details,
	// 		// 			productInitQty: response?.result?.product_details?.quantity
	// 		// 				? response?.result?.product_details?.quantity
	// 		// 				: response?.result?.product_details
	// 		// 						?.minimum_order_quantity,
	// 		// 			product: response?.results,
	// 		// 			message: `Create Order \nSupplier: ${
	// 		// 				response?.result?.product_details?.seller_business_name
	// 		// 			} \n${response?.result?.product_details?.title} \n${
	// 		// 				response?.result?.product_details?.country
	// 		// 					?.currency_symbol_native
	// 		// 			}${response?.result?.product_details?.procure_price}x${
	// 		// 				response?.result?.product_details?.quantity
	// 		// 					? response?.result?.product_details?.quantity
	// 		// 					: response?.result?.product_details
	// 		// 							?.minimum_order_quantity
	// 		// 			} = ${
	// 		// 				response?.result?.product_details?.country
	// 		// 					?.currency_symbol_native
	// 		// 			}${
	// 		// 				response?.result?.product_details?.quantity
	// 		// 					? Number(
	// 		// 							response?.results?.procure_price *
	// 		// 								response?.result?.product_details?.quantity
	// 		// 					  )
	// 		// 					: Number(
	// 		// 							response?.result?.product_details?.procure_price *
	// 		// 								response?.result?.product_details
	// 		// 									?.minimum_order_quantity
	// 		// 					  )
	// 		// 			}\nTotal = ${
	// 		// 				response?.result?.product_details?.country
	// 		// 					?.currency_symbol_native
	// 		// 			}${
	// 		// 				response?.result?.product_details?.quantity
	// 		// 					? Number(
	// 		// 							response?.result?.product_details?.procure_price *
	// 		// 								response?.result?.product_details?.quantity
	// 		// 					  )
	// 		// 					: Number(
	// 		// 							response?.result?.product_details?.procure_price *
	// 		// 								response?.result?.product_details
	// 		// 									?.minimum_order_quantity
	// 		// 					  )
	// 		// 			}`,
	// 		// 			suppliers: formattedSuppliers,
	// 		// 			type: "success",
	// 		// 			corns: response?.result?.product_details
	// 		// 				?.product_risk_analysis,
	// 		// 		}
	// 		// 	);
	// 		// }
	// 		// else {
	// 		// 	updateLastDataHandler({
	// 		// 		message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
	// 		// 		type: "notFound",
	// 		// 		loading: false,
	// 		// 	});
	// 		// }
	// 		// if (response.status === 404) {
	// 		// 	updateLastDataHandler({
	// 		// 		message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
	// 		// 		type: "notFound",
	// 		// 		loading: false,
	// 		// 	});
	// 		// }
	// 	}
	// };
	const updateLastDataHandler = (item) => {
		let data = {
			id: messageData?.length,
			user_name: "Bot",
			receiver: true,
			created_at: moment().format("LLLL"),
			avatar: "assets/images/bot-s.png",
			loading: false,
			cardType: "infoCard",
			...item,
		};
		dispatch(setUpdateMessage(data));
	};

	const updateProductDataHandler = (analysis, product) => {
		let data = [
			{
				id: messageData?.length + 1,
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				cardType: "infoCard",
				...analysis,
			},
			{
				id: messageData?.length + 2,
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: false,
				cardType: "infoCard",
				...product,
			},
		];

		dispatch(setUpdateMessage(data));
	};

	const updateBeforeLastDataHandler = (item) => {
		let data = {
			...item,
		};
		dispatch(setUpdateMessage(data));
	};
	const loaderMessageAdded = (type) => {
		let data = [
			{
				id: "loader1",
				uid: uuidv4(),
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: true,
				type: "initial",
				cardType: "infoCard",
			},
		];
		dispatch(setLoaderMessage(data));
	};

	const newSenderItemHandler = (extData, cusData) => {
		let data = [
			{
				id: messageData?.length + 1,
				user_name: user?.DisplayName,
				receiver: false,
				created_at: moment().format("LLLL"),
				avatar: user?.profile_image,
				...extData,
				loading: extData?.type === "image" ? true : false,
			},
			{
				id: messageData?.length + 2,
				user_name: "Bot",
				receiver: true,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				loading: true,
				type: "initial",
				cardType: "infoCard",
				...cusData,
			},
		];
		dispatch(setNewMessage(data));
	};
	const newReceiverAutoItem = (extData) => {
		if (!extData?.lastItem) {
			let data = [
				{
					cardType: "infoCard",
					id: messageData?.length + 1,
					user_name: "Bot",
					receiver: true,
					message: extData?.message,
					created_at: moment().format("LLLL"),
					avatar: "assets/images/bot-s.png",
					type: extData?.cardType,
					loading: false,
				},
				{
					id: messageData?.length + 2,
					user_name: "Bot",
					receiver: true,
					created_at: moment().format("LLLL"),
					avatar: "assets/images/bot-s.png",
					loading: true,
					type: "initial",
					cardType: "infoCard",
				},
			];
			dispatch(setUpdateReceiverMessage(data));
		} else {
			let data = [
				{
					cardType: "infoCard",
					id: messageData?.length + 1,
					user_name: "Bot",
					receiver: true,
					message: extData?.message,
					created_at: moment().format("LLLL"),
					avatar: "assets/images/bot-s.png",
					type: extData?.cardType,
					loading: false,
				},
			];
			dispatch(setUpdateReceiverMessage(data));
		}
	};
	const newReceiverItem = (extData) => {
		let data = [
			{
				cardType: "infoCard",
				id: messageData?.length + 1,
				user_name: "Bot",
				receiver: true,
				message: extData?.message,
				created_at: moment().format("LLLL"),
				avatar: "assets/images/bot-s.png",
				type: extData?.cardType,
				loading: false,
			},
		];
		dispatch(setUpdateReceiverMessage(data));
	};
	const updateItemContent = (extData, dataItem) => {
		let data = { ...dataItem, mailContent: extData };
		dispatch(setUpdateItemContent(data));
	};

	const createOrderRequest = async () => {
		newSenderItemHandler({ type: "accept" });
		let product = addCartFormate(
			messageData[messageData?.length - 1]?.productQty
		);
		const data = {
			quantity: messageData[messageData?.length - 1]?.productInitQty ?? 0,
			buyer: getUserBusiness().id,
			order_items: [product],
			price_adjustment: 0,
			seller:
				messageData[messageData?.length - 1]?.productQty
					?.seller_business_pg_id,
			delivery_date: moment(new Date()).add(1, "days"),
			order_origin: "Ekkbaz Seller",
		};
		const response = await Orders.createOrderForBot(data);
		if (response.status === 200 || response.success) {
			updateLastDataHandler({
				message: `🎉 Thanks for Ordering 🎉\nYour Order has been placed.\nOrder No:  ${response?.data?.order_id}`,
				type: "orderSuccess",
				orderId: response?.data?._id,
			});
		} else {
			if (response.status === 404) {
				updateLastDataHandler({
					message: `This seller default Payment not set yet! please set default payment`,
					type: "initial",
					orderId: response?.data?._id,
				});
			} else {
				updateLastDataHandler({
					message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
					type: "initial",
				});
			}
		}
	};
	const senderDataViewer = (data) => {
		if (data.type === "audio") {
			return (
				<div className='audio-player bg-success rounded-[20px] px-2 py-2 flex items-center min-w-[200px]'>
					<AudioPlayer
						data={data}
						setAudioPlayer={setAudioPlayer}
						audioPlayer={audioPlayer}
					/>
				</div>
			);
		} else if (data.type === "accept") {
			return (
				<div className='audio-player bg-success rounded-[15px] px-3 py-3  flex items-center'>
					<h3 className='text-white font-bold text-15'>Accept</h3>
				</div>
			);
		} else if (data.type === "cancel") {
			return (
				<div
					className='audio-player rounded-[15px] px-3 py-3  flex items-center'
					style={{
						background: "#FF564C",
					}}>
					<h3 className='text-white font-bold text-15'>Cancel</h3>
				</div>
			);
		} else if (data.type === "image") {
			return (
				<div
					className='px-3 py-3 min-w-[200px] flex items-center mb-1 bg-white min-h-[150px]'
					style={{
						borderRadius: "15px 15px 15px 15px",
						boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
					}}>
					{data?.loading ? (
						<div className='flex items-center justify-center h-[156px] m-auto min-w-[200px]'>
							{" "}
							<IonSpinner name='crescent'></IonSpinner>
						</div>
					) : (
						<img
							src={data?.imageUrl}
							className='rounded-[10px] m-auto image-sizer'
							alt='images'
						/>
					)}
				</div>
			);
		} else if (data.type === "cad") {
			return (
				<>
					<div
						className='px-1 flex items-center justify-center bg-white '
						style={{
							borderRadius: "15px 15px 15px 15px",
							boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
							minWidth: "800px",
							minHeight: "500px",
							width: "90%",
							maxWidth: "90%",
							flex: 1,
						}}>
						{data?.loading ? (
							<div className='flex items-center justify-center h-[156px] m-auto min-w-[200px]'>
								<IonSpinner name='crescent'></IonSpinner>
							</div>
						) : data.updateCad ? (
							data.updateCad ? (
								<ModelViewer url={data.updateCad} />
							) : (
								""
							)
						) : data.cadUrl ? (
							data?.cadFileExtension === "glb" ? (
								<CADPreviewForBazAI
									messageData={messageData}
									data={data}
									fileUrl={data.cadUrl}
									setMaterials={setMaterials}
								/>
							) : data?.cadFileExtension === "dxf" ? (
								<DXFViewer fileContent={data.cadFile} />
							) : (
								<FBXViewer fileUrl={data.cadUrl} />
							)
						) : (
							""
						)}
					</div>
					{/* <div
						className='px-2 py-2 min-w-[200px] flex items-center mb-1 bg-white min-h-[20px] mt-2'
						style={{
							borderRadius: "15px 15px 15px 15px",
							boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
						}}>
						<JSONPreview fileName={data.jsonFileName} />
					</div> */}
				</>
			);
		} else if (data.type === "text") {
			return (
				<div
					className=' px-3 py-3 mb-1 bg-white '
					style={{
						borderRadius: "15px 15px 15px 15px",
						boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
					}}>
					<p className='block text-black-700 text-semibold text-15 '>
						{replaceURLsWithLinks(data?.text)}
					</p>
				</div>
			);
		}
	};

	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = seconds % 60;
		return `${String(minutes).padStart(1, "0")}:${String(
			remainingSeconds
		).padStart(2, "0")}`;
	};

	const cancelHandler = () => {
		newSenderItemHandler({ type: "cancel" });
		updateLastDataHandler({
			message: `Canceled! you can find product again.`,
			type: "cancel",
		});
	};

	const openEditEmail = () => SetEditEmail(true);
	const closeEditEmail = (data) => {
		updateItemContent(data?.initMailContent, data);
		SetEditEmail(false);
	};

	const handleChange = (event, data) => {
		updateItemContent(event.target.value, data);
	};

	const emailSendHandler = async (data) => {
		loaderMessageAdded();
		let payload = {
			email_context: data?.mailContent,
			email_address: data?.emailAddress,
		};
		let response = await BazAiApi.mailSend(data?.produrmenId, payload);
		if (response.success) {
			SetEditEmail(false);
			let updateData = { ...data, mailSend: true };

			dispatch(setUpdateEmailContent(updateData));
		} else {
			updateLastDataHandler({
				message: `Please try again later!`,
				type: "notFound",
				loading: false,
			});
		}
	};

	const receiverDataViewer = (data) => {
		if (data?.cardType === "infoCard") {
			return (
				<div className='flex-1 flex'>
					{data.loading ? (
						<div className='flex items-center'>
							<h3
								className='font-bold text-16'
								style={{ color: "#555151" }}>
								Thinking
							</h3>
							<div className='loader'></div>
						</div>
					) : (
						<p
							className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] md:min-w-[450px]'
							style={{
								borderRadius: "0px 15px 15px 15px",
								boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
							}}>
							<span className='block'>
								{data?.message.split("\n").map((line, index) => (
									<React.Fragment key={index}>
										{parse(line)}
										<br />
									</React.Fragment>
								))}
							</span>
						</p>
					)}
				</div>
			);
		} else if (data?.cardType === "analysisCard") {
			return (
				<div>
					{data.loading ? (
						<div className='loader'></div>
					) : (
						<p
							className='font-semibold text-15  bg-white text-black-700 p-3 mb-[5px]'
							style={{
								borderRadius: "0px 15px 15px 15px",
								boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
							}}>
							{
								<div className='mb-3'>
									<span
										className='block font-bold pb-1'
										style={{ color: "#808080" }}>
										Supplier Price Analysis:
									</span>
									<span style={{ color: "#808080" }}>
										{data?.suppliers?.map((item, index) => (
											<React.Fragment key={index}>
												{`${index + 1})${item?.supplier}: ${
													data?.product?.country
														?.currency_symbol_native
												}${item?.price}`}
												<br />
											</React.Fragment>
										))}
									</span>
								</div>
							}
							{data?.corns?.length && (
								<>
									<span
										className='block font-bold pb-1'
										style={{ color: "#808080" }}>
										Risk Analysis:
									</span>
									<span style={{ color: "#808080" }}>
										{data?.corns?.map((line, index) => (
											<React.Fragment key={index}>
												{`${index + 1})${line}`}
												<br />
											</React.Fragment>
										))}
									</span>
								</>
							)}
						</p>
					)}
				</div>
			);
		} else if (data?.cardType === "productCard") {
			return (
				<div>
					{data.loading ? (
						<div className='loader'></div>
					) : (
						<p
							className='font-semibold min-w-[200px] bg-white text-black-700 p-3 mb-[5px]'
							style={{
								borderRadius: "0px 15px 15px 15px",
								boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
							}}>
							<span className='mb-3 block'>
								{data?.message.split("\n").map((line, index) => (
									<React.Fragment key={index}>
										{line}
										<br />
									</React.Fragment>
								))}
							</span>

							{data?.corns?.length && data?.orderState !== "hide" && (
								<div className='pb-3'>
									<QtyController
										data={data}
										item={data?.productQty}
										initQty={data?.productInitQty}
										updateLastDataHandler={updateLastDataHandler}
									/>
								</div>
							)}
						</p>
					)}
				</div>
			);
		} else if (data?.cardType === "cadCard") {
			return (
				<div>
					{data.loading ? <div className='loader'></div> : <div></div>}
				</div>
			);
		} else if (data?.cardType === "emailCard2") {
			return (
				<div className='flex-1'>
					{data.loading ? (
						<div className='loader'></div>
					) : (
						<div className='flex-1 flex'>
							{data.loading ? (
								<div className='flex items-center'>
									<h3
										className='font-bold text-16'
										style={{ color: "#555151" }}>
										Thinking
									</h3>
									<div className='loader'></div>
								</div>
							) : (
								<div
									className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] min-w-[200px]'
									style={{
										borderRadius: "0px 15px 15px 15px",
										boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
									}}>
									<h1 className='text-left font-bold text-16 mb-5'>
										Email Invitation
									</h1>
									{/* <span className='block'>
								{data?.message.split("\n").map((line, index) => (
									<React.Fragment key={index}>
										{parse(line)}
										<br />
									</React.Fragment>
								))}
							</span> */}

									<p className='font-semibold text-15 bg-white text-black-700 mb-[5px] md:min-w-[450px]'>
										<span className='block'>
											{data?.message
												.split("\n")
												.map((line, index) => (
													<React.Fragment key={index}>
														{parse(line)}
														<br />
													</React.Fragment>
												))}
										</span>
									</p>
								</div>
							)}
						</div>
					)}
				</div>
			);
		} else if (data?.cardType === "emailCard") {
			return (
				<div className='flex-1'>
					{data.loading ? (
						<div className='loader'></div>
					) : (
						<div className='flex-1 flex'>
							{data.loading ? (
								<div className='flex items-center'>
									<h3
										className='font-bold text-16'
										style={{ color: "#555151" }}>
										Thinking
									</h3>
									<div className='loader'></div>
								</div>
							) : (
								<div
									className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] w-full'
									style={{
										borderRadius: "0px 15px 15px 15px",
										boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
									}}>
									<h1 className='text-left font-bold text-16 mb-5'>
										Send Email
									</h1>
									{/* <span className='block'>
								{data?.message.split("\n").map((line, index) => (
									<React.Fragment key={index}>
										{parse(line)}
										<br />
									</React.Fragment>
								))}
							</span> */}

									<div>
										{!editEmail ? (
											<p onClick={!data?.mailSend && openEditEmail}>
												{data?.mailContent
													?.split("\n")
													.map((str, index) => (
														<React.Fragment key={index}>
															{str}
															<br />
														</React.Fragment>
													))}
											</p>
										) : (
											<div>
												<textarea
													ref={textareaRef}
													// readOnly={
													// 	messageData[messageData?.length - 1]
													// 		?.loading
													// }
													onKeyDown={handleKeyDown}
													style={{
														minHeight: "300px",
														height: `${height}px`,
														// overflow:
														// 	height === 120 ? "auto" : "hidden",
														resize: "none",
														width: "100%",
														padding: "10px",
														paddingTop: "15px",
														boxSizing: "border-box",
														paddingRight: 35,
														borderRadius: 15,
														overflowY: "scroll",
													}}
													value={data?.mailContent}
													onChange={(e) => handleChange(e, data)}
													type='text'
													placeholder='Write your email'
													className='gpt-text-area py-3.5 pr-10 px-3 appearance-none block mr-1 border leading-tight focus:outline-1 bg-white focus:bg-white text-14 w-full'
												/>
											</div>
										)}
									</div>
									{!data?.mailSend && (
										<div className='flex mt-5 justify-center'>
											<div
												className=' bg-success rounded-[15px] px-3 py-2  flex items-center mx-2 justify-center'
												onClick={() => emailSendHandler(data)}>
												<h3 className='text-white font-bold text-15'>
													Send Email
												</h3>
											</div>

											{editEmail && (
												<div
													className=' bg-primary rounded-[15px] px-3 py-2  flex items-center mx-2  justify-center'
													onClick={() => closeEditEmail(data)}>
													<h3 className='text-white font-bold text-15'>
														Close
													</h3>
												</div>
											)}
										</div>
									)}
								</div>
							)}
						</div>
					)}
				</div>
			);
		} else if (data?.cardType === "bomCard") {
			return (
				<div>
					{data.loading ? (
						<div className='loader'></div>
					) : (
						<div className='flex-1 flex'>
							{data.loading ? (
								<div className='flex items-center'>
									<h3
										className='font-bold text-16'
										style={{ color: "#555151" }}>
										Thinking
									</h3>
									<div className='loader'></div>
								</div>
							) : (
								<div
									className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] md:min-w-[750px]'
									style={{
										borderRadius: "0px 15px 15px 15px",
										boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
									}}>
									<h1 className='text-left font-bold text-16 mb-5'>
										Bill Of Material
									</h1>

									<div>
										<div className=''>
											<table
												className='table-auto w-full bg-white'
												style={{
													boxShadow:
														"0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
												}}>
												{/* <thead>
													<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
														<th className='border p-1 font-bold text-12'>
															S/N
														</th>
														<th className='border  font-bold text-12'>
															Material Name
														</th>
														<th className='border p-1 font-bold text-12'>
															Qty
														</th>
														<th className='border p-1 font-bold text-12'>
															Measurement
														</th>
													</tr>
												</thead> */}
												<thead>
													<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
														<th className='border p-1 font-bold text-16'>
															S/N
														</th>
														<th className='border p-1 font-bold text-16 text-left'>
															Component Name
														</th>
														{/* <th className='border p-1 font-bold text-16'>
															Quantity
														</th> */}
													</tr>
												</thead>
												<tbody>
													{data?.bomJson?.length
														? data?.bomJson?.map(
																(component, index) => (
																	<tr
																		key={`${component?.name}-${index}`}
																		className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'>
																		<td
																			// rowSpan={
																			// 	components.length
																			// }
																			// style={{
																			// 	verticalAlign:
																			// 		"middle",
																			// }}
																			className='border p-2 w-14 font-semibold text-14'>
																			{index + 1}
																		</td>

																		<td className='border p-2 w-14 font-semibold text-14 text-left'>
																			{component?.name}
																		</td>
																		{/* <td className='border p-2 w-14 font-semibold text-14'>
																			1
																		</td> */}
																	</tr>
																)
														  )
														: undefined}
												</tbody>
												{/* <tbody>
													{data?.bomJson?.CADFileData?.length
														? data?.bomJson?.CADFileData?.map(
																(el, i) => {
																	return (
																		<tr
																			className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
																			key={i}>
																			<td className='border p-2 w-14 font-semibold text-10'>
																				{digitChanger(
																					i + 1
																				)}
																			</td>
																			<td className='border font-semibold p-2 text-10'>
																				{el.materialName}
																			</td>
																			<td className='border font-semibold p-2 text-10'>
																				{el.materialName}
																			</td>
																		</tr>
																	);
																}
														  )
														: ""}
												</tbody> */}
											</table>
											{loading ? (
												<div className='flex items-center justify-center h-[156px] m-auto'>
													{" "}
													<IonSpinner name='crescent'></IonSpinner>
												</div>
											) : (
												""
											)}
											{data?.bomJson?.CADFileData?.length === 0 && (
												<>
													<IonCol size='2'></IonCol>
													<IonCol size='8'>
														<div
															className='text-center flex items-center justify-center'
															style={{
																height: "calc(100vh - 420px)",
															}}>
															<div>
																<img
																	className='m-auto mb-[10px] mt-5'
																	src='/assets/images/not-found-file.png'
																	alt='emptyCard'
																/>
																<p className='text-16 font-normal text-222222 mb-[35px]'>
																	{
																		ekkLanguage.orderList
																			.notFound
																	}
																</p>
															</div>
														</div>
													</IonCol>
												</>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			);
		} else if (data?.cardType === "procurementCard") {
			return (
				<div className='flex-1 flex'>
					{data.loading ? (
						<div className='flex items-center'>
							<h3
								className='font-bold text-16'
								style={{ color: "#555151" }}>
								Thinking
							</h3>
							<div className='loader'></div>
						</div>
					) : (
						<div
							className='font-semibold text-15 bg-white text-black-700 p-3 mb-[5px] md:min-w-[450px]'
							style={{
								borderRadius: "0px 15px 15px 15px",
								boxShadow: "rgba(0, 0, 0, 0.14) 0px 1px 10px 0px",
							}}>
							<h1 className='text-left font-bold text-16 mb-5'>
								Procurement
							</h1>
							{/* <h4 className="font-bold text-16">Supplier list</h4> */}
							<span className='block'>
								{/* {data?.supplier?.map((line, index) => (
									<p>{index + 1} )  {line?.business_name}</p>
								))} */}
								Your procurement request based on the CAD Design file is
								created.
							</span>
							<div className='text-right'>
								<Link
									to={`/procurements/admin/${data?.produrmenId}`}
									className='text-12 font-semibold underline text-delivered '>
									See Details
								</Link>
							</div>
						</div>
					)}
				</div>
			);
		}
	};

	const getSearchHandler = async (imageUrl) => {
		const url = BazAiApi.bazAiImageURL();
		const payload = {
			image_url: imageUrl,
		};
		const headers = {
			Authorization: token,
			"Content-Type": "application/json",
		};

		try {
			const resp = await fetch(url, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(payload),
			});

			if (!resp.body) {
				console.error("No body in the response");
				return;
			}

			const reader = resp.body.getReader();
			const decoder = new TextDecoder("utf-8");
			let jsonString = "";
			let done, value;

			while (true) {
				({ done, value } = await reader.read());
				if (done) break;
				jsonString += decoder.decode(value, { stream: true });
			}
			const response = JSON.parse(jsonString);

			if (response) {
				let formattedSuppliers = Object.entries(
					response?.supplier_comparison
				).map(([supplier, price]) => {
					return { supplier, price };
				});
				updateProductDataHandler(
					{
						id: messageData?.length + 2,
						cardType: "analysisCard",
						productQty: response?.product_details,
						productInitQty: response?.product_details?.quantity
							? response?.product_details?.quantity
							: response?.product_details?.minimum_order_quantity,
						product: response?.product_details,
						type: "success",
						corns: response?.product_details?.product_risk_analysis,
						suppliers: formattedSuppliers,
					},
					{
						id: messageData?.length + 3,
						cardType: "productCard",
						productQty: response?.product_details,
						productInitQty: response?.product_details?.quantity
							? response?.product_details?.quantity
							: response?.product_details?.minimum_order_quantity,
						product: response?.results,
						message: `Create Order \nSupplier: ${
							response?.product_details?.seller_business_name
						} \n${response?.product_details?.title} \n${
							response?.product_details?.country?.currency_symbol_native
						}${response?.product_details?.procure_price}x${
							response?.product_details?.quantity
								? response?.product_details?.quantity
								: response?.product_details?.minimum_order_quantity
						} = ${
							response?.product_details?.country?.currency_symbol_native
						}${
							response?.product_details?.quantity
								? Number(
										response?.results?.procure_price *
											response?.product_details?.quantity
								  )
								: Number(
										response?.product_details?.procure_price *
											response?.product_details
												?.minimum_order_quantity
								  )
						}\nTotal = ${
							response?.product_details?.country?.currency_symbol_native
						}${
							response?.product_details?.quantity
								? Number(
										response?.product_details?.procure_price *
											response?.product_details?.quantity
								  )
								: Number(
										response?.product_details?.procure_price *
											response?.product_details
												?.minimum_order_quantity
								  )
						}`,
						suppliers: formattedSuppliers,
						type: "success",
						corns: response?.product_details?.product_risk_analysis,
					}
				);
			} else {
				updateLastDataHandler({
					message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
					type: "notFound",
					loading: false,
				});
			}
			if (response.status === 404) {
				updateLastDataHandler({
					message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
					type: "notFound",
					loading: false,
				});
			}
		} catch (error) {
			updateLastDataHandler({
				message: `Sorry, i couldn't find your products to create order. 😭\nI am baby and still learning😋`,
				type: "notFound",
				loading: false,
			});
		}
	};

	useEffect(() => {
		let formData = new FormData();
		formData.append("key", files[0]);
		if (files.length !== 0) {
			setLoading(true);
			let data = {
				audioBlob: files[0],
				imageUrl: files[0]?.preview,
				type: "image",
			};
			newSenderItemHandler(data);
			let response = User.toBucketImage(formData);
			response.then((res) => {
				setLoading(false);
				if (res.status === 201) {
					setImageUrl(res.results[0]);
					updateBeforeLastDataHandler({
						imageUrl: res.results[0],
						loading: false,
						updateImage: true,
					});
					getSearchHandler(res.results[0]);
				}

				setValue(name, res.results[0]);
			});
		}
	}, [files, name]);

	const { getRootProps, getInputProps } = useDropzone({
		// accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			if (
				acceptedFiles[0].type === "image/jpeg" ||
				acceptedFiles[0].type === "image/png" ||
				acceptedFiles[0].type === "image/jpeg"
			) {
				setFiles(
					acceptedFiles.map((file) =>
						Object.assign(file, {
							preview: URL.createObjectURL(file),
						})
					)
				);
			}
		},
	});
	function alertMs(message) {
		navigate("/create-order-ai", { replace: true });
	}
	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			if (!event.shiftKey) {
				event.preventDefault();
				handleAssistant();
			}
		}
	};
	const replaceURLsWithLinks = (text) => {
		const urlRegex =
			/((?:https?:\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-zA-Z]{2,}(?:\/[\w-./?%&=]*)?(?:#[\w-]+)?)/gi;
		const parts = text.split(urlRegex);
		return parts.map((part, index) => {
			if (part.match(urlRegex)) {
				const url = !part.match(/^https?:\/\//i) ? `http://${part}` : part;

				return (
					<a
						key={index}
						href={url}
						target='_blank'
						rel='noopener noreferrer'>
						{part}
					</a>
				);
			} else {
				return part;
			}
		});
	};

	// const handleAssistant = async () => {
	// 	let data = {
	// 		text: comment,
	// 		type: "text",
	// 		loading: true,
	// 	};
	// 	newSenderItemHandler(data);
	// 	// setProcurement(false);
	// 	// dispatch(procApiCall(true));
	// 	const response = await BazAiService.findProcurementProduct(comment);
	// 	setComment("");
	// 	if (response?.results?.id) {
	// 		updateBeforeLastDataHandler({
	// 			message: `Here is your Procurement Reference Number: <a className='text-partDelivered underline font-bold' href='${window.location.origin}/procurement-order-details/${response?.results?.id}/${business?.role}'>${response?.results?.id}<a> `,
	// 			cardType: "infoCard",
	// 			type: "initial",
	// 			loading: false,
	// 		});
	// 	} else {
	// 		let redingMessage = response?.result;

	// 		function replaceAsterisksWithBold(text) {
	// 			let formattedText = text.replace(
	// 				/\*\*Hidden JSON for System Use:\*\*```json[\s\S]*?```/g,
	// 				""
	// 			);
	// 			formattedText = formattedText.replace(/```json[\s\S]*?```/g, "");
	// 			formattedText = formattedText.replace(
	// 				/\*\*(.*?)\*\*/g,
	// 				"<b>$1</b>"
	// 			);
	// 			formattedText = formattedText.replace(/-/g, "");
	// 			formattedText = formattedText.replace(/^###(.*)$/gm, "<b>$1</b>");
	// 			formattedText = formattedText.replace(
	// 				/【(\d+:\d+)†source】/g,
	// 				"[$1]"
	// 			);
	// 			return formattedText;
	// 		}
	// 		let result = replaceAsterisksWithBold(redingMessage);
	// 		updateBeforeLastDataHandler({
	// 			message: result,
	// 			cardType: "infoCard",
	// 			type: "initial",
	// 			loading: false,
	// 		});
	// 	}
	// };

	const handleAssistant = async () => {
		let data = {
			text: comment,
			type: "text",
			loading: true,
		};
		newSenderItemHandler(data);
		setComment("");
		const url = BazAiApi.bazAiChatURL(comment, token);
		const payload = {
			message: comment,
		};
		const headers = {
			Authorization: token,
			"Content-Type": "application/json",
		};
		try {
			const response = await fetch(url, {
				method: "GET",
			});
			// const response = await fetch(url, {
			// 	method: "POST",
			// 	headers: headers,
			// 	body: JSON.stringify(payload),
			// });

			if (!response.body) {
				console.error("No body in the response");
				return;
			}
			if (response.status === 200) {
				const procurementId = response.headers.get("Procurement-ID");

				if (!procurementId) {
					const reader = response.body.getReader();
					const decoder = new TextDecoder("utf-8");
					let text = "";
					let buffer = "";
					let inBold = false;
					let isFirstLine = true;

					while (true) {
						const { done, value } = await reader.read();
						if (done) break;
						const chunk = decoder.decode(value, { stream: true });
						buffer += chunk;

						// Handle line breaks and remove numbers before `**`
						buffer = buffer
							// Case 1: Remove numbers (and any preceding characters) before `**`
							.replace(/([a-zA-Z]+)\d+\.\s\*\*/g, "$1 <br>**")
							// Case 2: Add <br> before '**' when no number is detected
							.replace(
								/(^|\s)(\*\*)/g,
								(match, p1, p2) => `${p1}<br>${p2}`
							)
							// Case 3: Add <br> before '### Additional Considerations-'
							.replace(/(### Additional Considerations-)/g, "<br>$1");

						let updatedText = "";
						let index = 0;

						while (index < buffer.length) {
							if (!inBold) {
								const startBold = buffer.indexOf("**", index);
								if (startBold === -1) {
									updatedText += buffer.slice(index);
									break;
								} else {
									updatedText += buffer.slice(index, startBold);
									inBold = true;
									index = startBold + 2;
								}
							} else {
								const endBold = buffer.indexOf("**", index);
								if (endBold === -1) {
									updatedText += `<b>${buffer.slice(index)}</b>`;
									break;
								} else {
									updatedText += `<b>${buffer.slice(
										index,
										endBold
									)}</b>`;
									inBold = false;
									index = endBold + 2;
								}
							}
						}

						text += updatedText;
						buffer = buffer.slice(index);

						updateBeforeLastDataHandler({
							message: text,
							cardType: "infoCard",
							type: "initial",
							loading: false,
						});
					}
				} else {
					updateBeforeLastDataHandler({
						message: `Here is your Procurement Reference Number: <a className='text-partDelivered underline font-bold' href='${window.location.origin}/procurement-order-details/${procurementId}/${business?.role}'>${procurementId}</a> `,
						cardType: "infoCard",
						type: "initial",
						loading: false,
					});
				}
			} else {
				updateBeforeLastDataHandler({
					message: "Invalid request please try again!",
					cardType: "infoCard",
					type: "initial",
					loading: false,
				});
			}
		} catch (error) {
			console.error("Error in streaming POST request:", error);
		}
	};

	const textareaRef = useRef(null);
	const [height, setHeight] = useState(50);
	const handleInputChange = (event) => {
		const textarea = textareaRef.current;
		const scrollHeight = textarea.scrollHeight;
		if (scrollHeight <= 50) {
			setHeight(50);
		} else if (scrollHeight <= 80) {
			setHeight(80);
		} else if (scrollHeight <= 110) {
			setHeight(110);
		} else {
			setHeight(110);
		}
		if (!event.target.value) {
			setHeight(50);
		}
	};
	useEffect(() => {
		if (!comment) {
			setHeight(50);
		}
	}, [comment]);

	return (
		<div className='pt-5'>
			{/* <div className='header pt-5 pb-[50px]'>
				<img src='assets/images/bot-m.png' className='m-auto' alt='bot' />
			</div> */}

			<div className='chat-body pb-[150px] '>
				{messageData?.map((el, i) => {
					const showReactInfo = () => {
						if (!messageData[messageData?.length - 1]?.loading) {
							if (i != 0) {
								return (
									<div className='flex items-center'>
										<img
											src='/assets/images/gray_copy.png'
											alt='Copy'
											className='w-4 h-4 cursor-pointer ml-2'
											onClick={() => {
												navigator.clipboard.writeText(el?.message);
												toast.success("Text Copied");
											}}
										/>

										{!liked && (
											<div className='ml-1 flex items-center'>
												<button
													// onClick={handleLike}
													className='ml-2'>
													<FaThumbsUp
														color={liked ? "blue" : "gray"}
													/>
												</button>
											</div>
										)}
										{!unliked && (
											<div className='ml-1 flex items-center pt-1'>
												<button
													// onClick={handleUnlike}
													className='ml-2'>
													<FaThumbsDown
														color={unliked ? "red" : "gray"}
													/>
												</button>
											</div>
										)}
										{/* {showSuggestions && (
															<div className='mt-2'>
																<p>Suggestions from ChatGPT:</p>
																<ul>
																	<li
																		onClick={() =>
																			handleSuggestionClick(
																				"Suggestion 1"
																			)
																		}>
																		Suggestion 1
																	</li>
																	<li
																		onClick={() =>
																			handleSuggestionClick(
																				"Suggestion 2"
																			)
																		}>
																		Suggestion 2
																	</li>
																	<li
																		onClick={() =>
																			handleSuggestionClick(
																				"Suggestion 3"
																			)
																		}>
																		Suggestion 3
																	</li>
																</ul>
															</div>
														)} */}
									</div>
								);
							}
						}
					};
					return (
						<div key={i}>
							<div className='receiver mb-[30px]'>
								{el.receiver ? (
									<div>
										<div className='relative'>
											<div className='mb-1'>
												<div
													className={`${
														!windowSize?.matches
															? " ml-10"
															: " ml-0"
													} w-full flex justify-between`}>
													<div className='flex mt-3'>
														<p className='text-black-333 text-12 font-bold'>
															{windowSize?.matches && (
																<span className='text-success mr-1'>
																	Baz AI
																</span>
															)}
															{moment(el?.created_at).format(
																"h:mm A"
															)}
														</p>
													</div>

													{/* {el.type === "success" &&
													el.cardType !== "analysisCard" ? (
														<span
															to={`!#`}
															onClick={() => {
																if (el.productInitQty) {
																	if (navigator.geolocation) {
																		let location =
																			navigator.geolocation;
																		if (
																			location &&
																			Object.keys(location)
																				.length === 0
																		) {
																			navigator.geolocation.getCurrentPosition(
																				(p) => {
																					dispatch(
																						setLocation({
																							type: "Point",
																							coordinates:
																								[
																									p
																										.coords
																										.longitude,
																									p
																										.coords
																										.latitude,
																								],
																						})
																					);
																				}
																			);
																		}
																	} else {
																		toast.er(
																			"Geolocation is not supported by this browser."
																		);
																	}
																	localStorage.removeItem(
																		"cart"
																	);
																	// let status = shoppingCart.addToCart(product, qty);
																	let status =
																		productAddToCart(
																			el?.productQty,
																			el?.productQty
																				?.minimum_order_quantity,
																			el?.productInitQty
																		);
																	if (status) {
																		setProductSeller(
																			el?.productQty
																		);
																		alertMs(
																			"Product Add To Basket"
																		);
																	} else {
																		alertMs(
																			"Product Already Added To Basket"
																		);
																	}
																} else {
																	toast.warning(
																		"Please increase the quantity"
																	);
																}
															}}
															className='text-12 font-semibold underline text-delivered'>
															Go Details
														</span>
													) : undefined} */}
													{el?.type === "orderSuccess" ? (
														<Link
															to={`/order-details/${el?.orderId}/buyer`}
															className='text-12 font-semibold underline text-delivered'>
															Go Details
														</Link>
													) : undefined}
												</div>
											</div>
											<div className='flex'>
												{!windowSize?.matches && (
													<div className='mr-4 self-start'>
														<img
															src={el?.avatar}
															className='m-auto w-[26px] h-[26px] rounded-full'
															alt='bot'
														/>
													</div>
												)}

												{receiverDataViewer(el)}
											</div>
											<div className='mb-1'>
												<div
													className={`${
														!windowSize?.matches
															? " ml-10"
															: " ml-0"
													} w-full flex`}>
													<div className='flex mr-[120px]'>
														{showReactInfo()}

														<Modal
															isOpen={modalIsOpen}
															onRequestClose={handleModalClose}
															contentLabel='Feedback Modal'
															className='modal'
															overlayClassName='overlay'>
															<h2>Submit Your Feedback</h2>
															<p className='font-semibold mb-2'>
																Selected Suggestion:
															</p>
															<p className='mb-4'>
																{selectedSuggestion}
															</p>
															<textarea
																rows='4'
																placeholder='Your feedback here...'
																className='w-full border p-2 mb-4'
																value={feedback}
																onChange={(e) => {
																	setFeedback(e.target.value);
																}}></textarea>
															<button
																onClick={handleSubmit}
																className='px-4 py-2 bg-blue-500 text-white rounded'>
																Submit
															</button>
															<button
																onClick={handleModalClose}
																className='px-4 py-2 ml-2 bg-gray-500 text-white rounded'>
																Cancel
															</button>
														</Modal>
													</div>

													{el.type === "success" &&
													el.cardType !== "analysisCard" &&
													el.cardType == "productCard" ? (
														<span
															onClick={() => {
																if (el.productInitQty) {
																	if (navigator.geolocation) {
																		let location =
																			navigator.geolocation;
																		if (
																			location &&
																			Object.keys(location)
																				.length === 0
																		) {
																			navigator.geolocation.getCurrentPosition(
																				(p) => {
																					dispatch(
																						setLocation({
																							type: "Point",
																							coordinates:
																								[
																									p
																										.coords
																										.longitude,
																									p
																										.coords
																										.latitude,
																								],
																						})
																					);
																				}
																			);
																		}
																	} else {
																		toast.er(
																			"Geolocation is not supported by this browser."
																		);
																	}
																	localStorage.removeItem(
																		"cart"
																	);
																	// let status = shoppingCart.addToCart(product, qty);
																	let status =
																		productAddToCart(
																			el?.productQty,
																			el?.productQty
																				?.minimum_order_quantity,
																			el?.productInitQty
																		);
																	if (status) {
																		setProductSeller(
																			el?.productQty
																		);
																		alertMs(
																			"Product Add To Basket"
																		);
																	} else {
																		alertMs(
																			"Product Already Added To Basket"
																		);
																	}
																} else {
																	toast.warning(
																		"Please increase the quantity"
																	);
																}
															}}
															className='text-12 font-semibold underline text-delivered cursor-pointer'>
															Go Details
														</span>
													) : undefined}
													{el?.type === "orderSuccess" ? (
														<Link
															to={`/order-details/${el?.orderId}/buyer`}
															className='text-12 font-semibold underline text-delivered'>
															Go Details
														</Link>
													) : undefined}
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className='relative'>
										<p className='text-black-333 text-12 font-bold text-right mb-1 right-0'>
											{moment(el?.created_at).format("h:mm A")}
										</p>
										<div className='flex justify-end w-full'>
											<div style={{ flexBasis: "fit-content" }}>
												{senderDataViewer(el)}
											</div>
											{/* <div className='self-end'>
												<img
													src={el?.avatar}
													className='m-auto w-[26px] h-[26px] rounded-full'
													alt='bot'
												/>
											</div> */}
										</div>
									</div>
								)}
							</div>
						</div>
					);
				})}
				<div className='sender'></div>
			</div>
			<div
				className='chat-footer w-11/12 sm:w-10/12 md:w-8/12 lg:w-6/12 fixed bottom-[0px] sm:bottom-[0px] md:bottom-[0px] lg:bottom-[0px] rounded-[8px]'
				style={{
					background: "#fff",
					boxShadow: "1px 1px 10px rgba(0, 0, 0, .1)",
				}}>
				<div className='hidden'>
					<ReactMic
						record={ripple}
						className='sound-wave'
						onStop={onStop}
						strokeColor='#000000'
						backgroundColor='#FF4081'
					/>
				</div>

				<div className='mic-wrapper'>
					{messageData?.length > 0 &&
					messageData[messageData?.length - 1].receiver &&
					messageData[messageData?.length - 1].type === "success" &&
					messageData[messageData?.length - 1].cardType ===
						"productCard" ? (
						<div className='flex px-2 py-5'>
							<div
								className='audio-player bg-success rounded-[15px] px-3 py-3  flex items-center mx-2 w-1/2 justify-center'
								onClick={createOrderRequest}>
								<h3 className='text-white font-bold text-15'>Accept</h3>
							</div>
							<div
								onClick={cancelHandler}
								className='audio-player rounded-[15px] px-3 py-3  flex items-center mx-2 w-1/2 justify-center'
								style={{
									background: "#FF564C",
								}}>
								<h3 className='text-white font-bold text-15 '>
									Cancel
								</h3>
							</div>
						</div>
					) : (
						<>
							<BazAiBottomChatArea
								textareaRef={textareaRef}
								messageData={messageData}
								handleKeyDown={handleKeyDown}
								height={height}
								handleInputChange={handleInputChange}
								setComment={setComment}
								comment={comment}
								handleAssistant={handleAssistant}
								timer={timer}
								formatTime={formatTime}
								getRootProps={getRootProps}
								getInputProps={getInputProps}
								handleTouchStart={handleTouchStart}
								handleTouchEnd={handleTouchEnd}
								ripple={ripple}
								newSenderItemHandler={newSenderItemHandler}
								updateBeforeLastDataHandler={
									updateBeforeLastDataHandler
								}
								materials={materials}
								setMaterials={setMaterials}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
