import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    width: "400px",
    maxWidth: "100%",
    height: "auto",
    overflow: "visible",
    transition: ".4s all ease",
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: ".4s all ease",
    backgroundColor: "rgba(0,0,0,.5)",
  },
};

function EvaluationNegotiationModal({
  evaluations,

  showDialog,
  setShowDialog,
  handleOpenDialog,
  handleCloseDialog,
}) {
  const [feedbacks, setFeedbacks] = useState([]);
  const [negotiations, setNegotiations] = useState([]);
  const handleClose = () => {
    handleCloseDialog();
  };
  useEffect(() => {
    const handleFilter = () => {
      const filteredNegotiation = evaluations.filter(
        (ev) => ev.status === "Evaluating"
      );
      setFeedbacks(filteredNegotiation);
      const filteredEvaluation = evaluations.filter(
        (ev) => ev.status === "Negotiation"
      );
      setNegotiations(filteredEvaluation);
    };
    handleFilter();
  }, []);

  return (
    <div className="w-full flex justify-center">
      <Modal
        isOpen={showDialog}
        onRequestClose={handleClose}
        style={customModalStyles}
        contentLabel="Evaluation and Negotiation Modal"
      >
        <h2 className="text-lg font-bold mb-4">Evaluations and Negotiations</h2>
        <div className="mb-4">
          <h3 className="text-md font-semibold mb-2">Evaluations</h3>
          <ul>
            {feedbacks.map((ev) => (
              <li key={ev.id} className="mb-2 p-2 border rounded">
                <p>
                  <strong>Comment:</strong> {ev.comment}
                </p>
                <p>
                  <strong>Rating:</strong> {ev.ratting}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-4">
          <h3 className="text-md font-semibold mb-2">Negotiations</h3>
          <ul>
            {negotiations.map((neg) => (
              <li key={neg.id} className="mb-2 p-2 border rounded">
                <p>
                  <strong>Comment:</strong> {neg.comment}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <button
          className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
          onClick={handleClose}
        >
          Close
        </button>
      </Modal>
    </div>
  );
}

export default EvaluationNegotiationModal;
