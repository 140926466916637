import { IonCol } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";

export default function NotificationCard({ getNotificationListDetails }) {
	const notifications = useSelector((state) => state.notification.data);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<>
			<div>
				<div>
					{notifications?.length ? (
						notifications.map((notification, index) => {
							return (
								<div key={index}>
									<div
										onClick={() =>
											getNotificationListDetails(notification)
										}
										className={`py-3 px-4 overflow-hidden mb-1 ${
											notification?.read ? "bg-white" : "bg-gray-200"
										}  cursor-pointer`}>
										<div className=' p-[2px] flex w-full'>
											{/* <img
												className={`w-[50px] h-[50px] mr-[15px] ${
													notification?.read
														? "opacity-100"
														: "opacity-50"
												}`}
												src={notification?.image}
												alt='statusIcon'
											/> */}

											<div className='flex flex-col'>
												{/* <p
													className={`text-14 font-bold ${
														notification?.read && "opacity-100"
													}`}>
													{devideWord(orderStatus)}
												</p> */}
												<p
													className={`text-12 font-bold ${
														notification?.read
															? "opacity-100"
															: "opacity-50"
													}`}>
													{`${notification?.message} ${
														notification?.service_field_id
															? "no " +
															  notification?.service_field_id
															: undefined
													} `}
												</p>
											</div>
										</div>
									</div>
								</div>
							);
						})
					) : (
						<>
							<IonCol size='2'></IonCol>
							<IonCol size='8'>
								<div className='text-center flex items-center justify-center'>
									<div>
										<img
											className='m-auto mb-[10px] mt-5'
											src='/assets/images/not-found-file.png'
											alt='emptyCard'
										/>
										<p className='text-16 font-normal text-222222 mb-[35px]'>
											{ekkLanguage.orderList.notFound}
										</p>
									</div>
								</div>
							</IonCol>
						</>
					)}
				</div>
			</div>
		</>
	);
}
