import React, { useState } from "react";
import BazAICard from "./BazAICard";
import EvaluationNegotiationModal from "./EvaluationNegotiationModal";

function BidsCard({ bidList }) {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedBid, setSelectedBid] = useState(null);

  const handleOpenDialog = (bid) => {
    setSelectedBid(bid);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedBid(null);
  };

  return (
    <div className="px-4 pb-5">
      <BazAICard title={"Bids Information"}>
        <div className="border border-gray-300 rounded-lg p-4">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-blue-400 text-white">
                  <th className="text-left px-4 py-2">S/N</th>
                  <th className="text-left px-4 py-2">Supplier Name</th>
                  <th className="text-left px-4 py-2">Total Amount</th>
                  <th className="text-left px-4 py-2">Status</th>
                  <th className="text-left px-4 py-2">
                    CO<sub>2</sub> Footprint
                  </th>
                </tr>
              </thead>
              <tbody>
                {bidList.map((bid, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-blue-50"
                    } h-12 cursor-pointer`}
                    onClick={() => handleOpenDialog(bid)}
                  >
                    <td className="text-blue-900 px-4 py-2">{index + 1}</td>
                    <td className="text-blue-900 px-4 py-2">
                      {bid.businesses.business_name}
                    </td>
                    <td className="text-blue-900 px-4 py-2">${bid.price}</td>
                    <td className="px-4 py-2">
                      <span
                        className={`${
                          bid.status === "Awarded"
                            ? "bg-green-100 text-green-800"
                            : bid.status === "Negotiation"
                            ? "bg-yellow-100 text-yellow-800"
                            : bid.status === "Evaluating"
                            ? "bg-blue-100 text-blue-800"
                            : bid.status === "Submitted"
                            ? "bg-gray-100 text-gray-800"
                            : bid.status === "Invited"
                            ? "bg-purple-100 text-purple-800"
                            : "bg-gray-100 text-gray-800"
                        } rounded-full px-3 py-1 text-sm`}
                      >
                        {bid.status.charAt(0).toUpperCase() +
                          bid.status.slice(1)}
                      </span>
                    </td>
                    <td className="text-blue-900 px-4 py-2">
                      {bid.carbon_footprint}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </BazAICard>

      {selectedBid && (
        <EvaluationNegotiationModal
          evaluations={selectedBid?.procurement_feedback} // Replace with actual evaluations data
          // negotiations={negotiations} // Replace with actual negotiations data
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          handleOpenDialog={() => {}}
          handleCloseDialog={handleCloseDialog}
        />
      )}
    </div>
  );
}

export default BidsCard;
