import React, { useState } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import DXFRenderer from "./DXFRenderer"; // Import the updated DXFRenderer

const CADPreview = () => {
  const [fileContent, setFileContent] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileContent(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  return (
    <div>
      <h1>Upload and Preview Model</h1>
      <input type="file" accept=".dxf" onChange={handleFileUpload} />

      {fileContent && (
        <Canvas
          // style={{ height: "600px" }}
          style={{
            height: "600px",
            width: "100%",
            backgroundColor: "lightgray",
          }}
          camera={{ position: [0, 0, 40], fov: 50 }}
        >
          <ambientLight intensity={1} />
          <spotLight position={[10, 10, 10]} intensity={2} />
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 10]} intensity={1} />

          <DXFRenderer dxfContent={fileContent} />
          <OrbitControls />
        </Canvas>
      )}
    </div>
  );
};

export default CADPreview;
