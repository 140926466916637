import React from "react";
import BazAICard from "./BazAICard";

function DocumentsCard() {
  const attachments = [
    {
      sn: 1,
      fileName: "01. RFQ Specifications.pdf",
      fileDescription: "01. Specifications with Conditions of Contracts for the RFQ",
      fileSize: 247,
      downloadLink: "#",
    },
    // {
    //   sn: 2,
    //   fileName: "18. Appendix 1 - Confidentiality Agreement.pdf",
    //   fileDescription: "18. Appendix 1 - Confidentiality Agreement",
    //   fileSize: 206,
    //   downloadLink: "#",
    // },
    // {
    //   sn: 3,
    //   fileName: "20240819 Tender Docs (Trf B).zip",
    //   fileDescription:
    //     "Password to open the Tender Doc will be provided via email after the confidentiality agreement is signed and emailed back to CAG",
    //   fileSize: 39471,
    //   downloadLink: "#",
    // },
    // {
    //   sn: 4,
    //   fileName: "00. Corrigendum No.1 Cover Letter.pdf",
    //   fileDescription: "Corrigendum No.1 Cover Letter",
    //   fileSize: 337,
    //   downloadLink: "#",
    // },
    // {
    //   sn: 5,
    //   fileName: "ED_PJT_2024_46 Corrigendum No.1.zip",
    //   fileDescription: "Corrigendum No.1",
    //   fileSize: 4689,
    //   downloadLink: "#",
    // },
  ];

  return (
    <div className="px-4 pb-5">
      <BazAICard title={"Documents"} showIcon={true} icon='/assets/images/upload_icon.png'>
        <div className="border border-gray-300 rounded-lg p-4">
          <div className="overflow-x-auto">
            <table className="table-auto w-full border-collapse text-sm">
              <thead>
                <tr className="bg-blue-400 text-white">
                  <th className="w-1/12 text-left px-4 py-2">S/N</th>
                  <th className="w-3/12 text-left px-4 py-2">File Name</th>
                  <th className="w-5/12 text-left px-4 py-2">
                    File Description
                  </th>
                  <th className="w-2/12 text-right px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {attachments.map((attachment, index) => (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-blue-50"
                    } h-10`}
                  >
                    <td className="text-blue-900 px-4 py-2">{attachment.sn}</td>
                    <td className="text-blue-900 px-4 py-2">
                      {attachment.fileName}
                    </td>
                    <td className="text-blue-900 px-4 py-2">
                      <div className="hidden sm:block">
                        {attachment.fileDescription}
                      </div>
                      <div className="block sm:hidden">
                        {attachment.fileDescription.length > 50
                          ? `${attachment.fileDescription.substring(0, 50)}...`
                          : attachment.fileDescription}
                      </div>
                    </td>
                    <td className="text-blue-900 px-4 py-2 flex justify-end">
                      <a
                        href={attachment.downloadLink}
                        className="text-blue-500 hover:underline"
                      >
                        <img
                          src="/assets/images/green_download.png"
                          alt="download"
                          className="h-6 w-6 md:h-10 md:w-10"
                        />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </BazAICard>
    </div>
  );
}

export default DocumentsCard;
