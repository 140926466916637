// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";
// import { selectors } from "redux/container/authSlice";

// export default function PrivateOutlet({ children }) {
// 	const { loginStatus } = useSelector(selectors.getAuth);
// 	return loginStatus ? <Outlet /> : <Navigate to='/' />;
// }
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { selectors } from "redux/container/authSlice";

export default function PrivateOutlet() {
	const { loginStatus } = useSelector(selectors.getAuth);
	const location = useLocation();

	if (!loginStatus) {
		return <Navigate to='/' state={{ from: location }} />;
	}
	return <Outlet />;
}
