import React from "react";
import BazAICard from "./BazAICard";

function BazAIContactCard(props) {
  return (
    <div className="px-4 pb-5">
      <BazAICard title={"Contacts"}>
        {/* Primary and Secondary Contact */}
        <div className="border border-gray-300 rounded-lg p-4">
          <h4 className="text-16 font-bold mb-3">Primary Contact</h4>
          <div className="flex justify-between">
            <div className="w-1/2">
              <p className="text-blue-900 text-sm font-medium">Name</p>
              <p className="text-blue-900 text-sm">Marcus Lee</p>
            </div>
            <div className="w-1/2 text-right">
              <p className="text-blue-900 text-sm font-medium">Email</p>
              <p className="text-blue-900 text-sm">
                marcus.lee@changiairport.com
              </p>
            </div>
          </div>

          <h4 className="text-16 font-bold mt-5 mb-3">Secondary Contact</h4>
          <div className="flex justify-between">
            <div className="w-1/2">
              <p className="text-blue-900 text-sm font-medium">Name</p>
              <p className="text-blue-900 text-sm">Lim Choon Siong</p>
            </div>
            <div className="w-1/2 text-right">
              <p className="text-blue-900 text-sm font-medium">Email</p>
              <p className="text-blue-900 text-sm">
                lim.choonsiong@changiairport.com
              </p>
            </div>
          </div>
        </div>
      </BazAICard>
    </div>
  );
}

export default BazAIContactCard;
