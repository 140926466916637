/* global Autodesk */

import React, { useRef, useEffect, useState } from "react";
import "./Viewer.css";

function DWGViewer() {
  const viewerRef = useRef(null);
  const viewerDomRef = useRef(null);
  const [documentId, setDocumentId] = useState(null);

  useEffect(() => {
    const initializeViewer = async () => {
      try {
        const accessToken = await getAccessToken();

        const options = {
          env: "AutodeskProduction",
          api: "derivativeV2",
          accessToken,
        };

        Autodesk.Viewing.Initializer(options, async () => {
          const viewer = new Autodesk.Viewing.GuiViewer3D(viewerDomRef.current);
          viewerRef.current = viewer;

          const startedCode = viewer.start();
          if (startedCode > 0) {
            console.error("Failed to create a Viewer: WebGL not supported.");
            return;
          }

          if (documentId) {
            loadModel(viewer, documentId);
          }
        });
      } catch (error) {
        console.error("Error initializing viewer:", error);
      }
    };

    const getAccessToken = async () => {
      const clientId = "VfeAsBi74LEjsPj2uQmBMqmCffWuzeDLx5UqnmYRwNcoYjVN"; // Replace with your client ID
      const clientSecret =
        "FQULYJ3AEs8lYkhstV6wBd8ttKQppcRLPOKpcToXR5wodQu5PYP2kmOQ9v20TLef"; // Replace with your client secret
      const url = `https://developer.api.autodesk.com/authentication/v2/token`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Authorization:
            "Bearer VmZlQXNCaTc0TEVqc1BqMnVRbUJNcW1DZmZXdXplREx4NVVxbm1ZUndOY29ZalZOOkZRVUxZSjNBRXM4bFlraHN0VjZ3QmQ4dHRLUXBwY1JMUE9LcGNUb1hSNXdvZFF1NVBZUDJrbU9ROXYyMFRMZWY=",
        },
        body: new URLSearchParams({
          // client_id: clientId,
          // client_secret: clientSecret,
          grant_type: "client_credentials",
          scope: "data:read", // Adjust scopes as needed
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to get access token: ${response.statusText}`);
      }

      const data = await response.json();
      return data.access_token;
    };

    const loadModel = (viewer, file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        viewer.loadModel(arrayBuffer, { fileType: "dwg" });
      };
      reader.readAsArrayBuffer(file);
    };

    initializeViewer();

    return () => {
      if (viewerRef.current) {
        viewerRef.current.finish();
      }
    };
  }, [documentId]);

  const handleFileChange = (event) => {
    setDocumentId(event.target.files[0]);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} accept=".dwg" />
      <div id="forgeViewer" ref={viewerDomRef}></div>
    </div>
  );
}

export default DWGViewer;
