import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Business = {
	getBusiness: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_details/${id}/`);
	},

	// getBranchList: (page = 1, limit = "50") => {
	// 	return SuperFetch(
	// 		`${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
	// 	);
	// },
	getBranchList: (page = 1, limit = "50") => {
		return SuperFetch(
			`${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
		);
	},
	getPaymentData: (id) => {
		return SuperFetch(
			`${baseUrl.business}/v4/businesses/bank-info/details/${id}`
		);
	},
	updateUserBusiness: (id, query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_update/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	getEmployesList: (id, role, page = 1, limit = 100) => {
		return SuperFetch(
			`${
				baseUrl.bizNpdt
			}/ekkbaz/business/employees/${id}?page=${page}&limit=${limit}${
				role ? `&role_name=${role}` : ""
			}`
		);
	},
	getDriverList: (id, page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkadmin/business_employee_filter/${id}/?page=${page}&limit=${limit}`
		);
	},

	getEmployeeSearch: (query, businessId, role, page = 1, limit = 100) => {
		return SuperFetch(
			`${
				baseUrl.bizNpdt
			}/ekkbaz/business/employee/search/${businessId}/?page=${page}&limit=${limit}&q=${query}${
				role ? `&role_name=${role}` : ""
			}`
		);
	},

	assignEmployeeToOrder: (id, query) => {
		return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
			method: "PUT",
			data: query,
		});
	},
	getEmployees: (id) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business/employee/details/?emp_id=${id}`
		);
	},
	createBusiness: (query) => {
		return SuperFetch(
			`${baseUrl.business}/v4/business-employees/business/self`,
			{
				method: "POST",
				data: query,
			}
		);
	},
	updateEmployee: (id, query) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business/employee/update/${id}/`,
			{
				method: "PUT",
				data: query,
			}
		);
	},
	deleteEmployee: (id) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business/employee/delete/${id}/`,
			{
				method: "DELETE",
			}
		);
	},
	// updateBusinessLocation: (id, query) => {
	// 	return SuperFetch(`${baseUrl.bizNpdt}/seller/business/edit/${id}/`, {
	// 		method: "PUT",
	// 		data: query,
	// 	});
	// },
	updateBusinessLocation: (id, query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_update/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	getBusinessType: (page = 1, limit = 100) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/business_types/?page=${page}&limit=${limit}`
		);
	},
	createUserBusiness: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business/create/`, {
			method: "POST",
			data: query,
		});
	},
	checkBusiness: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_check/${id}/`);
	},
	getEmployeePosition: (page = 1, limit = 20) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/employee_roles/?page=${page}&limit=${limit}`
		);
	},
	createEmployee: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business/employee/create/`, {
			method: "POST",
			data: query,
		});
	},
	getKYC: (id) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/kyc/details/${id}/`);
	},
	createKYC: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/kyc/create/`, {
			method: "POST",
			data: query,
		});
	},
	updateKYC: (id, query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/kyc/update/${id}/`, {
			method: "PUT",
			data: query,
		});
	},
	businessDeleteOtp: () => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_delete_otp_send/`);
	},
	businessDelete: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/delete_business/`, {
			method: "POST",
			data: query,
		});
	},
	getCustomers: (sellerId, long, lati, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/customers/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}&seller_id=${sellerId}`
		);
	},
	getCustomer: (sellerId, long, lati, query, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/customers/?longitude=${long}&latitude=${lati}&q=${query}&page=${page}&limit=${limit}&seller_id=${sellerId}`
		);
	},
	getEmployeeList: (businessId, roleName, query, page = 1, limit = 50) => {
		return SuperFetch(
			`${
				baseUrl.bizNpdt
			}/ekkbaz/business/employees/${businessId}/?role_name=${roleName}&page=${page}&limit=${limit}${
				query ? `&q=${query}` : ""
			}`
		);
	},
	getBankList: () => {
		return SuperFetch(`${baseUrl.pay}/ekkbaz/seller_payment_gateways`);
	},
	createAgreement: (data) => {
		return SuperFetch(`${baseUrl.cms}/agreement/`, {
			method: "POST",
			data: data,
		});
	},
	agreementDetails: (id) => {
		return SuperFetch(`${baseUrl.cms}/agreement_details/${id}/`);
	},
	getDefaultPenalty: (id) => {
		return SuperFetch(`${baseUrl.loan}/loan/ekkbaz/default_penalty/${id}/`);
	},
	getPreviousLoan: (id) => {
		return SuperFetch(`${baseUrl.loan}/loan/seller/checkdue/${id}/`);
	},
	getCountry: (country) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkadmin/countries/?country_name=${country}`
		);
	},
	getIndustryList: () => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/primary_industry/`);
	},
	customerCheck: (phoneNo, sellerId) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/businesses/supplier_check/${phoneNo}/?seller_id=${sellerId}`
		);
	},
	distributorCheck: (phoneNo, sellerId) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/businesses/supplier_check/${phoneNo}/?seller_id=${sellerId}`
		);
	},
	createNewBusiness: (query) => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkbaz/business_relation/`, {
			method: "POST",
			data: query,
		});
	},
	getRelationList: () => {
		return SuperFetch(`${baseUrl.bizNpdt}/ekkhero/business_relation_types/`);
	},
	// getBusinessType: () => {
	// 	return SuperFetch(
	// 		`${baseUrl.ekkbazbizNpdt}/business_types/?page=1&limit=100`
	// 	);
	// },
	getNearbyCustomers: (sellerId, long, lati, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/nearby_customer/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}&seller_id=${sellerId}`
		);
	},
	getNearbyCustomer: (sellerId, long, lati, query, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/nearby_customer/search/?longitude=${long}&latitude=${lati}&q=${query}&page=${page}&limit=${limit}&seller_id=${sellerId}`
		);
	},

	getNearbySuppliers: (long, lati, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/seller_list/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}`
		);
	},
	getNearbySupplier: (long, lati, query, page = 1, limit = 30) => {
		return SuperFetch(
			`${baseUrl.bizNpdt}/ekkbaz/seller_list/search/?longitude=${long}&latitude=${lati}&tearm=${query}&page=${page}&limit=${limit}`
		);
	},
	getSuppliers: (long, lati, page = 1, limit = 20) => {
		return SuperFetch(
            `${baseUrl.bizNpdt}/sellers/?longitude=${long}&latitude=${lati}&page=${page}&limit=${limit}`
        );
	}
};
export default Business;
