import React, { useState } from "react";
import Select from "react-select";
import BazAICard from "./BazAICard";
import Modal from "react-modal";
import "react-responsive-modal/styles.css";
import Procurement from "services/Procurement";
import { toast } from "react-toastify";

const modalCustomStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    width: "400px",
    maxWidth: "100%",
    height: "auto",
    overflow: "visible",
    transition: ".4s all ease",
    borderRadius: 12,
    backgroundColor: "#FFFFFF",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: ".4s all ease",
    backgroundColor: "rgba(0,0,0,.5)",
  },
};

function WOCCard({
  procurementDetails,
  modalIsOpen,
  modalContent,
  handleModalClose,
  setUpdatePage
}) {
  const [procurementMethod, setProcurementMethod] = useState(null);
  const [procurementType, setProcurementType] = useState(null);
  const [remark, setRemark] = useState("");

  const showProcurementType =
    procurementMethod?.value === "Open Sourcing" ||
    procurementMethod?.value === "Limited Sourcing";

  // Styles for react-select
  const customStyles = (isValid) => ({
    control: (provided, state) => ({
      ...provided,
      borderColor: isValid ? "#28a745" : "#dc3545",
      boxShadow: state.isFocused
        ? isValid
          ? "0 0 0 1px #28a745"
          : "0 0 0 1px #dc3545"
        : null,
      "&:hover": {
        borderColor: isValid ? "#28a745" : "#dc3545",
      },
      textAlign: "left",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isValid ? "#28a745" : "#dc3545",
      textAlign: "left",
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    menu: (provided) => ({
      ...provided,
      left: 0,
      marginTop: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: isValid ? "#28a745" : "#dc3545",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  });

  const formatDescriptionAsJson = (description) => {
    try {
      const jsonDescription = JSON.parse(description);
      return Object.entries(jsonDescription)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return `${key}: ${value.join(", ")}`;
          } else {
            return `${key}: ${value}`;
          }
        })
        .join("\n");
    } catch (error) {
      return description;
    }
  };
  const handleSubmitDecision = async () => {
    const data = {
      status: "Divisional Approval",
      feedback: remark,
    };
    const response = await Procurement.acceptRejectProcurement(
      procurementDetails.id,
      data
    );
    if (response.status === 200) {
      setUpdatePage(true);
      toast.success("Decision submitted successfully.");
      handleModalClose();
    } else {
      toast.error("Decision submission failed.");
    }
  };

  return (
    <div className="px-4 pb-5">
      <BazAICard title="Waiver of Competition (WoC)">
        <div className="border border-gray-300 rounded-lg px-4 pt-4 pb-4">
          <table className="table-fixed w-full border-collapse">
            <tbody>
              <tr className="bg-blue-50 h-10">
                <td className="text-blue-900 text-sm font-medium py-2 px-4">
                  Justifications
                </td>
                <td className="text-blue-900 text-sm font-medium py-2 px-4 text-right">
                  <p className="w-full">
                    {formatDescriptionAsJson(procurementDetails?.waiver_of_competition)}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BazAICard>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleModalClose}
        contentLabel="Decision Modal"
        style={modalCustomStyles}
        closeTimeoutMS={200}
      >
        <h2 className="text-xl font-bold mb-4">Decision Details</h2>
        {modalContent === "Accept" ? (
          <>
            <p className="mb-4">
              Please enter your remarks for{" "}
              <b className="text-success">Acceptance</b>:
            </p>
            <textarea
              className="border border-gray-300 rounded p-2 w-full mb-4"
              placeholder="Enter remark here"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            ></textarea>
          </>
        ) : (
          <>
            <p className="mb-4">
              Please provide details for{" "}
              <b className="text-primary">Rejection</b>:
            </p>
            <div className="mb-4">
              <label className="block mb-2">Remark:</label>
              <textarea
                className="border border-gray-300 rounded p-2 w-full mb-4"
                placeholder="Enter remark here"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              ></textarea>
            </div>
            <div className="pb-4">
              <label className="block mb-2">Procurement Method:</label>
              <Select
                value={procurementMethod}
                onChange={(selectedOption) =>
                  setProcurementMethod(selectedOption)
                }
                options={[
                  { value: "Open Sourcing", label: "Open Sourcing" },
                  { value: "Limited Sourcing", label: "Limited Sourcing" },
                  { value: "Direct Award", label: "Direct Award" },
                  { value: "Exempted", label: "Exempted" },
                ]}
                placeholder="Select method"
                styles={customStyles(!!procurementMethod)}
                isSearchable={false}
              />
            </div>
            {showProcurementType && (
              <div className="pb-4">
                <label className="block mb-2">Procurement Type:</label>
                <Select
                  value={procurementType}
                  onChange={(selectedOption) =>
                    setProcurementType(selectedOption)
                  }
                  options={[
                    { value: "Tender", label: "Tender" },
                    { value: "RFQ", label: "RFQ" },
                  ]}
                  placeholder="Select type"
                  styles={customStyles(!!procurementType)}
                  isSearchable={false}
                />
              </div>
            )}
          </>
        )}
        <div className="flex justify-between mt-4 pb-4">
          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex-1 mr-2"
            onClick={handleModalClose}
          >
            Cancel
          </button>
          <button
            className="bg-success hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex-1"
            onClick={() => {
              handleSubmitDecision();
              // Submit logic can be placed here if needed
            }}
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default WOCCard;
