import React from "react";

function BazAIStatusCard({ procurementDetails }) {
  return (
    <div className="mt-9 mb-4">
      <div className="text-center py-5">
        <h2 className="text-black-1000 text-lg sm:text-xl md:text-2xl font-bold mb-10">
          {procurementDetails?.created_by_businesses_name[0]}
        </h2>
      </div>

      <div className="order-status pb-5">
        <ol className="steps flex flex-wrap justify-between gap-8 md:gap-6">
          <li
            className={`step ${
              procurementDetails?.status === "Submission" ||
              procurementDetails?.status === "Divisional Approval" ||
              procurementDetails?.status === "Published" ||
              procurementDetails?.status === "Bidding" ||
              procurementDetails?.status === "Evaluation" ||
              procurementDetails?.status === "Negotiation" ||
              procurementDetails?.status === "Awarded"
                ? "is-complete"
                : ""
            } relative min-w-[80px] md:min-w-[120px]`}
            data-step="1"
          >
            <span className="absolute bottom-[-40px] text-xs sm:text-sm md:text-base whitespace-nowrap">
              Submission
            </span>
          </li>
          <li
            className={`step ${
              procurementDetails?.status === "Divisional Approval" ||
              procurementDetails?.status === "Published" ||
              procurementDetails?.status === "Bidding" ||
              procurementDetails?.status === "Evaluation" ||
              procurementDetails?.status === "Negotiation" ||
              procurementDetails?.status === "Awarded"
                ? "is-complete"
                : ""
            } relative min-w-[80px] md:min-w-[120px]`}
            data-step="2"
          >
            <span className="absolute bottom-[40px] text-xs sm:text-sm md:text-base whitespace-nowrap">
              Divisional Approval
            </span>
          </li>
          <li
            className={`step ${
              procurementDetails?.status === "Published" ||
              procurementDetails?.status === "Bidding" ||
              procurementDetails?.status === "Evaluation" ||
              procurementDetails?.status === "Negotiation" ||
              procurementDetails?.status === "Awarded"
                ? "is-complete"
                : ""
            } relative min-w-[80px] md:min-w-[120px]`}
            data-step="3"
          >
            <span className="absolute bottom-[-40px] text-xs sm:text-sm md:text-base whitespace-nowrap">
              Published
            </span>
          </li>
          <li
            className={`step ${
              procurementDetails?.status === "Bidding" ||
              procurementDetails?.status === "Evaluation" ||
              procurementDetails?.status === "Negotiation" ||
              procurementDetails?.status === "Awarded"
                ? "is-complete"
                : ""
            } relative min-w-[80px] md:min-w-[120px]`}
            data-step="4"
          >
            <span className="absolute bottom-[40px] text-xs sm:text-sm md:text-base whitespace-nowrap">
              Bidding
            </span>
          </li>
          <li
            className={`step ${
              procurementDetails?.status === "Evaluation" ||
              procurementDetails?.status === "Negotiation" ||
              procurementDetails?.status === "Awarded"
                ? "is-complete"
                : ""
            } relative min-w-[80px] md:min-w-[120px]`}
            data-step="5"
          >
            <span className="absolute bottom-[-40px] text-xs sm:text-sm md:text-base whitespace-nowrap">
              Evaluation
            </span>
          </li>
          <li
            className={`step ${
              procurementDetails?.status === "Negotiation" ||
              procurementDetails?.status === "Awarded"
                ? "is-complete"
                : ""
            } relative min-w-[80px] md:min-w-[120px]`}
            data-step="6"
          >
            <span className="absolute bottom-[40px] text-xs sm:text-sm md:text-base whitespace-nowrap">
              Negotiation
            </span>
          </li>
          <li
            className={`step ${
              procurementDetails?.status === "Awarded" ? "is-complete" : ""
            } relative min-w-[80px] md:min-w-[120px]`}
            data-step="7"
          >
            <span className="absolute bottom-[-40px] text-xs sm:text-sm md:text-base whitespace-nowrap">
              Award
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default BazAIStatusCard;
