// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-title h5 span {
  position: absolute;
  height: 1.5px;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 16px;
  margin-right: 23px;
  margin-left: 5px;
}

.header-wrapper .short-nav {
  display: block;
}

.header-wrapper .long-nav {
  display: none;
}

.header-wrapper.issticky .short-nav {
  display: none;
}

.header-wrapper.issticky .long-nav {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000000;
}

.header-wrapper.issticky .long-nav .backIcon {
  background-color: rgb(251, 118, 110, var(--tw-bg-opacity));
}

.custom-placeholder-style::placeholder {
  color: #ff564c;
}`, "",{"version":3,"sources":["webpack://./src/components/mobile-components/Common/style.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,WAAA;EACA,8BAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,aAAA;AAGF;;AADA;EACE,aAAA;AAIF;;AAFA;EACE,cAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,kBAAA;AAKF;;AAHA;EACE,0DAAA;AAMF;;AAJA;EACE,cAAA;AAOF","sourcesContent":[".section-title h5 span {\n  position: absolute;\n  height: 1.5px;\n  width: 100%;\n  background: rgba(0, 0, 0, 0.2);\n  top: 16px;\n  margin-right: 23px;\n  margin-left: 5px;\n}\n.header-wrapper .short-nav {\n  display: block;\n}\n.header-wrapper .long-nav {\n  display: none;\n}\n.header-wrapper.issticky .short-nav {\n  display: none;\n}\n.header-wrapper.issticky .long-nav {\n  display: block;\n  position: fixed;\n  left: 0;\n  top: 0;\n  z-index: 100000000;\n}\n.header-wrapper.issticky .long-nav .backIcon {\n  background-color: rgb(251 118 110 / var(--tw-bg-opacity));\n}\n.custom-placeholder-style::placeholder {\n  color: #ff564c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
