import React from "react";

export default function DetailsCard({ children }) {
  return (
    <div className="px-4 profile-settings-card  overflow-y-scroll h-[550px]">
      <div className="text-center bg-white rounded-[30px] pb-10 pt-5">
        {children}
      </div>
    </div>
  );
}
