import { createSlice } from "@reduxjs/toolkit";

export const procurementSlice = createSlice({
  name: "procurement",
  initialState: {
    list: [],
    details: {},
    bids: [],
  },
  reducers: {
    setProcurementList: (state, action) => {
      state.list = action.payload;
    },
    setProcurementDetails: (state, action) => {
      state.details = action.payload;
    },
    setBidList: (state, action) => {
      state.bids = action.payload;
    },
  },
});

export const { actions, reducer } = procurementSlice;

//procurement information
export const setProcurementList = (values) => async (dispatch, getState) => {
  dispatch(actions.setProcurementList(values));
};
export const setProcurementDetails = (values) => async (dispatch, getState) => {
  dispatch(actions.setProcurementDetails(values));
};
export const setBidList = (values) => async (dispatch, getState) => {
  dispatch(actions.setBidList(values));
};

export default reducer;
