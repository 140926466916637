const malaysia = {
	login: {
		contryLabel: "NEGARA",
		contryPlaceHolder: "",
		phoneLable: "NOMBOR TELEFON",
		phonePlaceHolder: "NOMBOR TELEFON",
		validationLabel:
			"Nombor telefon tidak sah, Sila tulis nombor telefon dalam bahasa Inggeris",
		btnLabel: "Teruskan",
		tokenSuccessLabel: "Token anda telah dihantar ke telefon anda",
		tokenSuccessFailed: "Token anda belum dicipta lagi, sila cuba lagi",
		langChange: "Tukar bahasa kepada",
		termsAllow: "Sila benarkan terma dan syarat semasa log masuk!",
	},
	password: {
		passwordLabel: "KATA LALUAN",
		passwordPlaceHolder: "Masukkan kata laluan anda",
		forgetPass: "Lupa Kata Laluan",
		btnLabel: "Log masuk",
		otpSendLabel: "Kod telah dihantar ke telefon bimbit anda",
		backWord: "Kembali",
		passError: "Kata laluan tidak sepadan dengan nombor telefon",
	},
	changePass: {
		passwordLabel: "KATA LALUAN BARU",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Kata laluan tidak sepadan",
		btnLabel: "Teruskan",
		successLabel: "Kata laluan anda berjaya ditukar",
		errorLabel: "Penukaran kata laluan anda gagal",
		lengthErrorLabel: "Kata laluan mesti sekurang-kurangnya 4 aksara panjang",
		notMatchLabel:
			"Kata laluan dan pengesahan kata laluan anda tidak sepadan.",
	},
	token: {
		tokenLabel: "TOKEN",
		tokenPlaceHolder: "Masukkan token anda",
		tokenResend: "Hantar semula token",
		tokenSuccessLabel:
			"Kod telah dihantar ke telefon bimbit anda, taip untuk teruskan",
		tokenErrorLabel: "Penghantaran token gagal",
		timerLabel: "Sila tunggu",
		second: "saat",
		btnLabel: "Teruskan",
		tokenNotMatch: "Token tidak sepadan",
		tokenVarified: "Token anda telah disahkan",
		tokenNotWrite: "Sila masukkan token anda",
	},
	registration: {
		nameLabel: "NAMA PENUH",
		namePlaceholder: "Masukkan nama penuh anda",
		emailLabel: "EMAIL",
		emailPlaceholder: "Masukkan email anda",
		passwordLabel: "KATA LALUAN",
		passwordPlaceholder: "Masukkan kata laluan anda",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceholder: "Masukkan kata laluan pengesahan anda",
		validationLabel: "Medan input mesti sekurang-kurangnya 4 aksara panjang",
		passNotMatch:
			"Kata laluan dan pengesahan kata laluan anda tidak sepadan.",
		btnLabel: "Teruskan",
		successMessage: "Profil Berjaya Dicipta",
		errorMessage: "Sila semak input yang sah",
		passLengthValidLabel:
			"Kata laluan mesti sekurang-kurangnya 4 aksara panjang",
		passwordLength: "Kata laluan anda",
		strong: "Kuat",
		medium: "Sederhana",
		weak: "Lemah",
		gender: "JANTINA",
		male: "Lelaki",
		female: "Perempuan",
		others: "Lain-lain",
		dobLabel: "TARIKH LAHIR",
		day: "Hari",
		month: "Bulan",
		year: "Tahun",
	},
	letNav: {
		homeMenuLabel: "Rumah",
		orderMenuLabel: "Jualan",
		mySupplierMenuLabel: "Pembekal Saya",
		businessProfileMenuLabel: "Tetapan Profil Perniagaan",
		dashboardProfileMenuLabel: "Papan Pemuka",
		phoneCall: "Panggil Untuk Memesan",
		myEmployeesMenuLabel: "Pekerja",
		product: "Produk Saya",
		supplier: "Pembelian",
		learning: "Pembelajaran",
		collectionDeposit: "Kutipan & Deposit",
	},
	learning: {
		pageTitle: "Senarai Pembelajaran",
		learningDetailsTitle: "Butiran Pembelajaran",
		comments: "Komen",
		commentAs: "Tulis komen anda di sini",
		likedWarning: "Anda tidak boleh menyukai lebih daripada sekali",
	},
	home: {
		showMoreLabel: "Tunjukkan Lagi",
		brandLabel: "Jenama",
		offerLabel: "Tawaran Baru",
		allSuppliersLabel: "Senarai Pesanan",
		nearSuppliersLabel: "Pembekal",
		recommendentLabel: "Hanya Untuk Anda",
		searchLabel: "Cari produk",
		todayPurchaseLabel: "Pembelian Hari Ini",
		totalOrderLabel: "Produk dari",
		totalOrder: "Pesanan",
		skusLabel: "SKU",
		tillNow: "Sehingga Kini",
		orderDue: "Pesanan Tertunda",
		brandProductPageTitle: "Produk Jenama",
		offerProductPageTitle: "Produk Tawaran",
		offerProductNotFound: "Maaf, Tiada tawaran yang dijumpai untuk anda.",
		mySupplierNotFound:
			"Anda tidak mempunyai pembekal. Sila beli produk untuk menambah pembekal anda di sini.",
		account: "Akaun",
		orderNumber: "Nombor Pesanan",
		amount: "Jumlah",
		warning:
			"Harap maklum, transaksi akan terjejas pada 1 Mac 2024 kerana sistem sedang dinaik taraf.",
		warningHeading: "Amaran",
	},
	profile: {
		pageTitle: "Profil & Tetapan",
		contactMenuLable: "Maklumat Hubungan & Asas",
		passwordMenuLable: "Tukar Kata Laluan",
		logoutMenuLable: "Log Keluar",
		changeLanguageMenuLable: "Tukar Bahasa",
		kycMenuLable: "Maklumat KYC",
		subscriptionPlan: "pelan langganan",
	},
	changeLanguages: {
		pageTitle: "Pilih Bahasa",
		btnLabel: "Simpan",
	},
	contact: {
		pageTitle: "Maklumat Pengguna",
		emailLabel: "Email",
		emailPlaceholder: "Masukkan email anda",
		nidLabel: "Kad Pengenalan Nasional",
		nidPlaceholder: "Masukkan nombor Kad Pengenalan Nasional",
		btnLabel: "Simpan",
		successLabel: "Profil anda berjaya dikemaskini.",
		currectEmail: "sila masukkan alamat email semasa",
	},
	otpsend: {
		pageTitle: "Sahkan Nombor",
		btnLabel: "Hantar",
		message:
			"Ketik untuk menghantar kod pengesahan ke telefon bimbit anda melalui SMS.",
		successLabel: "Token anda telah dihantar ke telefon anda",
		errorLabel: "Token anda belum dicipta lagi, sila cuba lagi",
	},
	otpsubmit: {
		pageTitle: "Sahkan Nombor",
		btnLabel: "Hantar",
		verifiLabel: "Sahkan & Teruskan",
		title: "Kod Pengesahan",
		paragraph: "Kami telah menghantar kod pengesahan ke Nombor Telefon Anda",
		otpLabel: "Masukkan kod OTP anda",
		otpPlaceholder: "Masukkan kod OTP anda",
		receiveYetLabel: "Tidak menerima kod?",
		againRequestLabel: "Minta lagi",
		timerLabel: "Sila tunggu",
		second: "saat",
		successLabel: "Token anda telah dihantar ke telefon anda",
		errorLabel: "Token anda belum dicipta lagi, sila cuba lagi",
		tokenVarifiedMessage: "Token anda telah disahkan",
		validationLabel: "Sila hantar OTP anda",
		optNotMatch: "OTP anda tidak sah",
	},
	changePassword: {
		pageTitle: "Tukar Kata Laluan",
		btnLabel: "Hantar",
		passwordLabel: "KATA LALUAN",
		passwordPlaceholder: "Masukkan kata laluan anda",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceholder: "Masukkan pengesahan kata laluan anda",
		successLabel: "Kata laluan anda telah dikemaskini.",
		errorLabel: "Kemaskini kata laluan anda gagal",
		passNotMatchLabel:
			"Kata laluan dan pengesahan kata laluan anda tidak sepadan.",
		changePassSuccessPara: "Kata laluan anda telah berjaya ditukar.",
		changePassContinueBtn: "Teruskan",
	},
	brandDetails: {
		skusLabel: "SKU",
		descriptionLabel: "Deskripsi",
		productTitleLabel: "Produk Jenama Ini",
		pageTitle: "Butiran Jenama",
		sku: "SKU",
		import: "Import",
		edit: "Edit",
		delete: "Padam",
		deleteConformationAsk: "Adakah anda pasti mahu memadamkan jenama ini?",
		request: "Minta untuk menunjukkan harga",
	},
	mysuppliers: {
		pageTitle: "Senarai Pembekal",
		skusLabel: "SKU",
		productTitleLabel: "Produk Pembekal Ini",
		supplierOrderAmount: "Jumlah Pesanan Pembekal",
	},
	createOrder: {
		pageTitle: "Buat Pesanan",
		buyerDetailsTitle: "Butiran Pembeli",
		sellerDetailsTitle: "Butiran Penjual",
		deliveryDetailsTitle: "Penghantaran",
		productItemTitle: "Item Produk",
		costSummaryTitle: "Ringkasan Kos",
		paymentDetailsTitle: "Butiran Pembayaran",
		paymentTermsTitle: "Terma Pembayaran",
		paymentMethodeTitle: "Kaedah Pembayaran",
		moqLabel: "Kuantiti Pesanan Minimum",
		pcsLabel: "Unit",
		subtotal: "Jumlah Kecil",
		tax: "Cukai / VAT",
		discount: "Diskaun",
		deliveryFee: "Caj Penghantaran",
		creditFee: "Caj Perkhidmatan",
		total: "Jumlah",
		immediatePayment: "Pembayaran Segera",
		pickAndPay: "Ambil dan Bayar",
		today: "Pada Malam Hari Penghantaran",
		threeDays: "Bayar dalam 3 hari",
		sevenDays: "Bayar dalam 7 hari",
		immediatePaymentText:
			"Tiada Caj Perkhidmatan dikenakan untuk pesanan ini.",
		todayPaymentText1:
			"Untuk pembayaran hari ini, caj perkhidmatan tambahan sebanyak ",
		todayPaymentText2: "akan ditambah ke pesanan ini.",
		threePaymentText1:
			"Untuk pembayaran 3 hari, caj perkhidmatan tambahan sebanyak ",
		threePaymentText2: "akan ditambah ke pesanan ini.",
		sevenPaymentText1:
			"Untuk pembayaran 7 hari, caj perkhidmatan tambahan sebanyak ",
		sevenPaymentText2: " akan ditambah ke pesanan ini.",
		cashOnDelivery: "Bayar Semasa Penghantaran",
		cashOnDeliveryText1: "Bayar Tunai kepada",
		cashOnDeliveryText2: "apabila barang dihantar.",
		cash: "Tunai",
		bkashText: "Bayar melalui Bkash",
		nagadText: "Bayar melalui Nagad",
		number: "Nombor",
		numberWarning:
			"Jika wang dihantar ke nombor selain daripada nombor ini, ia akan dianggap sebagai dibatalkan",
		cashText: "Bayar Tunai kepada",
		bankTransfer: "Pindahan Bank",
		bankTransferText1: "Pindahan Bank - Bayar ke",
		bankTransferText2: "akaun bank dengan 'Nombor Pesanan' sebagai rujukan",
		bankTransferText3: "Nama Bank",
		bankTransferText4: "Nama A/C",
		bankTransferText5: "Nombor A/C",
		bankTransferText6: "Nombor Laluan",
		bankTransferText7: "Cawangan",
		btnLabel: "Sahkan",
		addMoreProductLabel: "Tambah Lagi Produk",
		selectProductLabel: "Hantar",
		cosgressTitle: "Tahniah!",
		cosgressPara: "Pesanan anda telah selesai.",
		preOrderCongress:
			"Pre-order anda telah berjaya diselesaikan. Wakil kami akan menghubungi anda tidak lama lagi",
		congressCancelText:
			"Jika terdapat sebarang masalah dengan pesanan, batalkan sebelum jam 12 tengahari. Anda tidak boleh membatalkan pesanan selepas jam 12 tengahari.",
		congressProductLabel: "Teruskan",
		orderNow: "Pesan Sekarang",
		alert: "Amaran",
		importentMessage: "Mesej penting",
		deliveryDateMessage: "Sila Pilih Tarikh Penghantaran",
		paymentTermsMessage: "Sila Pilih Terma Pembayaran",
		paymentMethodMessage: "Sila Pilih Kaedah Pembayaran",
		backButtonMessage:
			"Troli anda mempunyai beberapa item, jika anda meninggalkan halaman ini troli anda akan kosong.",
		okBtnLabel: "OK",
		loader: "Sila tunggu...",
		placeConformationMessage: "Adakah anda pasti mahu membuat pesanan ini?",
		lessThenMoq:
			"Kuantiti Item yang dipilih kurang daripada kuantiti pesanan minimum.",
		all: "Semua",
		requestDeliveryDate: "Minta Tarikh Penghantaran",
		fsMandatoryMessage: "Sila Pilih Jumlah Sokongan Kewangan",
		fsCoupon: "Kupon FS",
		downPayment: "Bayaran Pendahuluan",
		fsServiceFee: "Caj Perkhidmatan Sokongan Kewangan",
		fsPlaceholder: "Masukkan jumlah sokongan kewangan yang diperlukan",
		fsWarning: "jumlah sokongan kewangan tidak betul",
		dpWarning1:
			"Permintaan Sokongan Kewangan anda sedang dikaji. Sila tunggu dengan sabar sehingga hari bekerja berikutnya.",
		dpWarning2:
			"Sila ambil perhatian Penalti di bawah jika pembayaran gagal. Ini adalah anggaran sahaja. Sentiasa rujuk halaman Butiran Pesanan untuk jumlah sebenar.",
		orderId: "ID Pesanan",
		dueAmount: "Jumlah Tertunggak",
		dueLoanTitle:
			"Anda mesti menyelesaikan tunggakan terdahulu sebelum membuat permintaan kewangan yang baru.",
	},
	selectProduct: {
		pageTitle: "Pilih Produk",
		totalLabel: "Jumlah",
		btnLabel: "Hantar",
	},
	orderList: {
		pageTitle: "Buat Pesanan",
		tabMenuRecent: "Semua",
		tabMenuPlaced: "Dibuat",
		tabMenuAccepted: "Diterima",
		tabMenuScDelivered: "Dijadualkan Untuk Penghantaran",
		tabMenuPartialDelivered: "Dihantar Sebahagian",
		tabMenuDelivered: "Dihantar",
		tabMenuReturn: "Produk Dikembalikan",
		tabMenuPartialPaid: "Dibayar Sebahagian",
		tabMenuPaid: "Dibayar",
		tabMenuCancel: "Dibatalkan",
		tabMenuDeliveryFailed: "Penghantaran Gagal",
		orderItem: "Item Pesanan",
		seller: "Penjual",
		buyer: "Pembeli",
		loader: "Sila tunggu...",
		createdBy: "Dibuat Oleh",
		to: "Kepada",
		for: "Untuk",
		notFound: "Tidak Dijumpai",
	},
	orderDetails: {
		createdBy: "Dibuat oleh",
		placedLabel: "Dibuat",
		deliveredLabel: "Dihantar",
		paidLabel: "Dibayar",
		orderItemLabel: "Item Pesanan",
		castItemLabel: "Butiran Kos",
		timelineLabel: "Garis Masa",
		subtotal: "Jumlah Kecil",
		tax: "Cukai / VAT",
		discount: "Diskaun",
		deliveryFee: "Caj Penghantaran",
		creditFee: "Caj Perkhidmatan",
		total: "Jumlah",
		paymentSettlementLabel: "Penyelesaian Pembayaran",
		date: "Tarikh",
		amount: "Jumlah",
		dueDate: "Tarikh Tertunggak",
		remark: "Catatan",
		collectedBy: "Dikumpul oleh",
		paymentDetailsLabel: "Butiran Pembayaran",
		paymentMethod: "Kaedah Pembayaran",
		paymentTerms: "Terma Pembayaran",
		paymentDueDate: "Tarikh Pembayaran Tertunggak",
		totalDue: "Jumlah Tertunggak",
		totalPaid: "Jumlah Dibayar",
		remaining: "Baki",
		deliveryDetailsLabel: "Butiran Penghantaran",
		selectTimeDate: "Pilih Jadual Tarikh & Masa Penghantaran",
		selectTime: "Pilih Masa Penghantaran",
		selectDate: "Pilih Tarikh Penghantaran",
		acceptOrder: "Terima Pesanan",
		cancelOrder: "Batalkan Pesanan",
		scheduleDelivery: "Jadualkan Penghantaran",
		BuyerName: "Nama Pembeli",
		address: "Alamat",
		phoneNumber: "Nombor Telefon",
		email: "E-mel",
		deliveryDate: "Tarikh Penghantaran",
		downloadInvoices: "Muat Turun Invois",
		receiptDownloads: "Cetak Resit",
		cancelConformationAsk: "Adakah anda pasti mahu membatalkan pesanan ini?",
		cancelConformation: "Anda tidak boleh membatalkan pesanan ini",
		cancelConformationParagraph:
			"Anda tidak boleh membatalkan pesanan ini, anda hanya boleh membatalkan pesanan pada hari yang sama.",
		yesBtn: "Ya",
		noBtn: "Tidak",
		withoutSettelment:
			"Maklumat pembayaran yang lengkap akan dipaparkan di sini.",
		bankInfoLabel: "Maklumat Akaun Bank untuk Pindahan Pembayaran",
		assignDriver: "Tugaskan Pemandu",
		assignCollector: "Tugaskan Pengumpul",
		assignedDriver: "Pemandu Ditugaskan",
		assignedCollector: "Pengumpul Ditugaskan",
		notAssigned: "Belum Ditugaskan",
		addDeliveryFee: "Tambah Caj Penghantaran",
		addDiscount: "Tambah Diskaun",
		feePlaceHolder: "Tulis Jumlah Caj Penghantaran Anda",
		discountPlaceHolder: "Tulis Jumlah Caj Diskaun Anda",
		requestDeliveryDate: "Minta Tarikh Penghantaran",
		scheduleDeliveryDate: "Jadualkan Tarikh Penghantaran",
		scheduleDeliveryTime: "Jadualkan Masa Penghantaran",
		deliveryFailMessage: "Mesej Penghantaran Gagal",
		selectCancelOrder: "Pilih Alasan untuk Membatalkan Pesanan",
		multipleTime: "Penghantaran gagal beberapa kali",
		productNotAvailable: "Produk tidak tersedia",
		cannotFindAddress: "Tidak Dapat Mencari Alamat",
		noShop: "Menemui alamat tetapi tiada kedai di sana",
		buyerNotAccept: "Pembeli tidak menerima harga pesanan",
		supplierNotGive: "Pembekal tidak memberikan produk",
		other: "Lain-lain",
		yes: "Ya",
		no: "Tidak",
		deliveryTimeWarning:
			"Anda mesti memilih tarikh penghantaran, jika tidak kami tidak dapat memproses untuk menjadualkan penghantaran",
		deliveryDateWarning:
			"Anda mesti memilih masa penghantaran, jika tidak kami tidak dapat memproses untuk menjadualkan penghantaran",
		deliveryDateTimeWarning:
			"Anda mesti memilih tarikh dan masa penghantaran",
		orderSearchLabel: "Cari Pesanan Anda",
		loanCollectionTitle: "Penyelesaian Sokongan Kewangan",
		transactionType: "Jenis Transaksi",
		gateway: "Gerbang Pembayaran",
		accountName: "Nama Akaun",
		accountNumber: "Nombor Akaun",
		transactionNumber: "Nombor Transaksi",
		payEkkbaz: "Bayar Ekkbaz",
		close: "Tutup",
		confirmDelivery: "Penghantaran Lengkap",
		send: "Hantar",
		receipt: "Resit",
		receiptNotProvided: "Resit Tidak Disediakan",
		instantDelivery:
			"Adakah anda pasti mahu melengkapkan penghantaran untuk pesanan ini?",
		fss: "Penyelesaian Sokongan Kewangan",
		downPaymentLeft: "Bayaran Pendahuluan Tertinggal",
		default: "Default",
		first: "1st",
		second: "2nd",
		penaltyFee: "Caj Penalti",
		due: "Tertunggak",
		third: "3rd",
		immediatePayment: "Pembayaran Segera",
		inSettlement: "Dalam Penyelesaian",
		closeSettlement: "Tutup Penyelesaian",
		approve: "Lulus",
		downPayment: "Bayaran Pendahuluan",
		sellerPayment: "Pembayaran Penjual",
		delivered: "Dihantar",
		repaid: "Dibayar",
		amountWarning: "Jumlah sebenar mungkin berbeza.",
		penaltyHeader: "Jadual Penalti Default",
	},
	businessCreateName: {
		pageTitle: "Nama Perniagaan",
		businessName: "Nama Perniagaan",
		businessNamePlaceholder: "Masukkan Nama Perniagaan",
		paragraph:
			"Kami hanya 3 langkah dari membuat perniagaan anda. Mari mulakan dengan nama perniagaan anda.",
		btnLabel: "Seterusnya",
		submit: "Hantar",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Masukkan nama perniagaan!",
		congratulation: "Tahniah",
		congratulationMessage: "Perniagaan anda berjaya dibuat",
		kycInformation:
			"Maklumat kyc anda belum diserahkan lagi. sila serahkan maklumat kyc anda",
		freeTrail: "Mulakan Percubaan Percuma",
		kycSubmit: "Hantar KYC Anda",
		pending:
			"Terima kasih kerana menghantar KYC. Pengesahan KYC anda sedang diproses, sila tunggu.",
		goBack: "Kembali",
		trialStart: "tahniah! Anda telah berjaya memulakan percubaan.",
	},
	businessType: {
		pageTitle: "Jenis Perniagaan",
		paragraph: "Mari pilih jenis Perniagaan anda.",
		fmcg: "FMCG",
		miniMart: "Mini Mart",
		superMart: "Super Mart",
		pharmacy: "Farmasi",
		cart: "Kereta",
		bazaarStore: "Kedai Bazaar",
		hawkerCenter: "Pusat Penjaja",
		restaurant: "Restoran",
		hotel: "Hotel",
		cosmeticsStore: "Kedai Kosmetik",
		nonFmcg: "Bukan FMCG",
		office: "Pejabat",
		NGO: "NGO",
		entertainmentPlace: "Tempat Hiburan",
		religiousOrganization: "Organisasi Keagamaan",
		spa: "Spa",
		salon: "Salon",
		gym: "Gim",
		electronicsStore: "Kedai Elektronik",
		shoeStore: "Kedai Kasut",
		fashionStore: "Kedai Fesyen",
		laundryStore: "Kedai Dobi",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Seterusnya",
		validationLabel: "Sila Pilih jenis perniagaan",
		producer: "Pengeluar",
		reseller: "Penjual Semula",
	},
	businessLocation: {
		pageTitle: "Lokasi Perniagaan",
		paragraph: "Masukkan butiran hubungan perniagaan anda.",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat anda",
		city: "Bandar",
		cityPlaceholder: "Masukkan Nama bandar",
		postalCode: "Poskod",
		postalCodePlaceholder: "Masukkan poskod",
		email: "E-mel",
		emailPlaceholder: "Masukkan e-mel anda",
		userName: "Nama Pengguna",
		userNamePlaceholder: "Masukkan nama pengguna anda",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "SELESAI",
		successLabel: "Tahniah perniagaan telah dibuat",
		errorLabel: "Pembuatan perniagaan gagal",
		validationLabel: "Sila isi alamat untuk bergerak.",
		loader: "Sila tunggu...",
		validationCityLabel: "Sila isi bandar.",
		validationPostCodeLabel: "Sila isi poskod.",
		locationValidation: "Sila pilih lokasi dari peta.",
		validationEmailLabel: "Sila isikan e-mel anda",
	},
	locationPicker: {
		pageTitle: "Cari Lokasi Kedai",
		btnLabel: "SAHKAN",
		paragraph:
			"Tukar keadaan penanda peta untuk memilih lokasi perniagaan anda",
	},
	location: {
		title: "Aktifkan Lokasi Anda",
		paragraph:
			"Aplikasi ini memerlukan perkhidmatan lokasi dihidupkan pada peranti anda dan untuk aplikasi ini. Anda mesti mengaktifkannya di Tetapan sebelum menggunakan aplikasi ini.",
		btnLabel: "Benarkan hanya semasa menggunakan aplikasi",
	},
	businessSelect: {
		pageTitle: "Pilih Perniagaan",
	},
	pwaDownload: {
		header: "Muat Turun Aplikasi EkkBaz",
		paragraph:
			"Muat turun aplikasi dan buat pesanan pukal dari pembekal anda bila-bila masa mulai sekarang.",
		cancelBtn: "Batal",
		downloadBtn: "Muat Turun",
	},
	businessProfile: {
		pageTitle: "Tetapan Profil Perniagaan",
		businessDetailsLabel: "Butiran",
		registrationNo: "Nombor Pendaftaran",
		taxNo: "Nombor Cukai/VAT",
		mobileNo: "Nombor Telefon Bimbit",
		officeNo: "Nombor Pejabat",
		email: "E-mel",
		website: "Laman Web",
		branchLabel: "Senarai Cawangan",
		paymentDetailsLabel: "Butiran Pembayaran",
		bankInfoLabel: "Maklumat Bank",
		bankName: "Nama Bank",
		branchName: "Cawangan Bank",
		branchAddress: "Alamat Bank",
		swiftCode: "Kod Swift Bank",
		acName: "Nama A/C Bank",
		acNumber: "Nombor A/C Bank",
		bkashInfoLabel: "Maklumat Bkash",
		bkashAcName: "Nama A/C Bkash",
		bkashAcNo: "Nombor A/C Bkash",
		nagadInfoLabel: "Maklumat Nagad",
		nagadAcName: "Nama A/C Nagad",
		nagadAcNo: "Nombor A/C Nagad",
		upayInfoLabel: "Maklumat Upay",
		upayAcName: "Nama A/C Upay",
		upayAcNo: "Nombor A/C Upay",
		verified: "Disahkan",
		unVerified: "Tidak Disahkan",
		editBusinessMenu: "Sunting Perniagaan",
		paymentTermsSettings: "Tetapan Terma Pembayaran Pelanggan",
		addPayMenu: "Tambah Pilihan Pembayaran",
		setLocation: "Kemas Kini Lokasi Perniagaan",
		addMenu: "Tambah Cawangan",
		verifyBusinessMenu: "Sahkan Perniagaan",
		bankInfoNotFound: "Maklumat bank tidak dijumpai.",
		deleteBusiness: "Padam Perniagaan",
		deleteWarning:
			"Semua pesanan, produk, pekerja anda, dan keseluruhan akaun anda akan dipadam. Adakah anda pasti mahu memadamkan Perniagaan ini?",
		otpSubmit: "Hantar OTP",
		confirmDeleteWarning:
			"Fikirkan lagi! Semua pesanan, produk, pekerja anda, dan keseluruhan akaun anda akan dipadam. Adakah anda pasti mahu memadamkan Perniagaan ini?",
		switchBusiness: "Tukar Perniagaan",
	},
	paymentSettings: {
		pageTitle: "Tetapan Terma Pembayaran Pelanggan",
		mainTitle: "Pilih Terma Pembayaran yang Sesuai",
		subTitle:
			"Terma pembayaran yang anda pilih di sini, pelanggan hanya boleh menggunakan Terma Pembayaran ini semasa membuat pesanan.",
		btnLabel: "Simpan",
		payNow: "Bayar Sekarang",
		cashOnDelivery: "Bayar semasa Terima",
		pickAndPay: "Pilih dan Bayar",
		today: "Pada Malam Hari Penghantaran",
		threeDays: "Bayar dalam 3 hari",
		sevenDays: "Bayar dalam 7 hari",
		fourteenDays: "Bayar dalam 14 hari",
		thirtyDays: "Bayar dalam 30 hari",
	},
	businessEdit: {
		pageTitle: "Sunting Perniagaan",
		uploadPhoto: "Muat Naik Foto",
		email: "E-mel",
		emailPlaceholder: "Masukkan e-mel anda",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat anda",
		city: "Bandar",
		cityPlaceholder: "Masukkan Nama bandar",
		postalCode: "Poskod",
		postalCodePlaceholder: "Masukkan poskod",
		resigter: "Nombor Pendaftaran",
		resigterPlaceholder: "Masukkan Nombor Pendaftaran anda",
		tex: "Nombor Cukai/VAT",
		texPlaceholder: "Masukkan Nombor Cukai/VAT anda",
		mobile: "Nombor Telefon Bimbit",
		mobilePlaceholder: "Masukkan Nombor Telefon Bimbit anda",
		office: "Nombor Pejabat",
		officePlaceholder: "Masukkan Nombor Pejabat anda",
		website: "Laman Web",
		websitePlaceholder: "Masukkan laman web anda",
		btnLabel: "Simpan",
		successLabel: "Profil anda berjaya dikemas kini.",
		currectEmail: "Sila masukkan alamat e-mel semasa",
	},
	bankDetails: {
		referenceNumber: "Nombor Rujukan",
		bankName: "Nama Bank",
		accountName: "Nama A/C",
		accountNumber: "Nombor A/C",
		routeNumber: "Nombor Laluan",
		branch: "Cawangan",
	},
	invoice: {
		title: "INVOIS",
		receipt: "Resit",
		buyerDetails: "Butiran Pembeli",
		sellerDetails: "Butiran Penjual",
		description: "PENERANGAN",
		qty: "KUANTITI",
		price: "HARGA",
		subtotal: "JUMLAH KECIL",
	},
	verifyBusiness: {
		numberOne: "1",
		numberTwo: "2",
		numberThree: "3",
		numberFour: "4",
		numberFive: "5",
		numberSix: "6",
	},
	receipt: {
		placed: "Dibuat",
		name: "Nama",
		qty: "Kuantiti",
		price: "Harga",
		subtotal: "Jumlah Kecil",
		tax: "Cukai",
		discount: "Diskaun",
		creditFee: "Caj Perkhidmatan",
		deliveryFee: "Caj Penghantaran",
		total: "Jumlah",
		paid: "Dibayar",
		totalDue: "Jumlah Tertunggak",
		paymentTerms: "Terma Pembayaran",
		days: "Hari",
		paymentMethod: "Kaedah Pembayaran",
		referenceNumber: "Nombor Rujukan Bank",
		deliveryDate: "Tarikh Penghantaran",
		dueDate: "Tarikh Pembayaran Tertunggak",
		printTime: "Masa Cetakan",
		conditionsCreditFee: "Syarat Pembayaran & Caj Perkhidmatan",
		immediatePayment: "Pembayaran Segera",
		cashOnDelivery: "Bayar Semasa Terima",
		atNight: "Pada Malam Hari Penghantaran",
		payInthreeDay: "Bayar dalam 3 Hari",
		payInSevenDay: "Bayar dalam 7 Hari",
		afterSeven: "Selepas 7 Hari (tambahan untuk setiap hari tambahan)",
	},
	landing: {
		header1: "Transcending Your Procurement Teams",
		header2: "With Limitless AI Agents.",
		header3: "Bayar Kemudian!",
		login: "Log masuk",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malay: "Malay",
		retail: "Runcit",
		manufacture: "Pembekal",
		hotline: "Talian Panas",
		phoneNumber: "Nombor Telefon",
		phoneNumberPlaceHolder: "Masukkan Nombor Telefon",
		brandHeader: "Jenama yang bekerjasama dengan kami!",
		fAbout: "Jual produk dengan mudah di pasaran borong.",
		location: "Lokasi",
		singapore: "Pejabat Singapura",
		bangladesh: "Pejabat Bangladesh",
		bOfficeAddress: "Tingkat 13, Razzak Plaza, Mog Bazar Moor, Dhaka-1205",
		otherInfo: "Maklumat Lain",
		partners: "Rakan Kongsi Kami",
		news: "Berita",
		support: "Sokongan",
		corporate: "Korporat",
		refundPolicy: "Polisi Pemulangan",
		termsCondition: "Terma & Syarat",
		privacyPolicy: "Polisi Privasi",
		contactUs: "Hubungi Kami",
		messageUs: "Hantar Mesej",
		facebook: "Facebook",
		sales: "Jualan",
		media: "Media",
		downloadBtn: "Muat Turun Aplikasi",
		backers: "Penyokong Kami",
		benefits: "Faedah Anda",
		discover: "Berita Terkini EkkBaz",
		benefitsOne: "Tingkatkan jualan",
		benefitsDisOne: "",
		benefitsTwo: " Mudah Mencapai Pelanggan",
		benefitsDisOneTwo: "",
		benefitsThree: "Tingkatkan keterlihatan produk",
		benefitsDisOneThree: "",
		benefitsFore: "Digitalkan proses jualan anda",
		benefitsDisOneFore: "",
		seeMore: "Lihat Lagi",
		downloadApp: "Muat Turun Aplikasi Sekarang!",
		apiBtn: "Join Waitlist",
		logIn: "Login",
		ourTeam: "Our Team",
	},
	employees: {
		pageTitle: "Pekerja",
		createNewEmployees: "Cipta Pekerja Baru",
		searchPlaceHolder: "Cari pekerja anda",
		collectorList: "Senarai Pemungut",
		collectorSearch: "Cari pemungut",
	},
	employeesDetails: {
		pageTitle: "Butiran Pekerja",
	},
	createEmployees: {
		pageTitle: "Cipta Pekerja Baru",
		customerNoHolder: "Masukkan Nombor Telefon",
		employeeNumber: "Nombor Pekerja",
		name: "Nama",
		namePlaceholder: "Masukkan nama pekerja",
		confirmBtn: "Sahkan",
		selectEmployees: "Pilih jawatan pekerja",
		admin: "Administrator",
		stuff: "Staf",
		orderManager: "Pengurus Pesanan",
		bookManager: "Pengurus Buku",
		productManager: "Pengurus Produk",
		employeeManager: "Pengurus Pekerja",
		nameError: "Sila masukkan nama pekerja",
		roleError: "Sila pilih peranan pekerja",
		edit: "Sunting",
		delete: "Padam",
		editEmployee: "Sunting Pekerja",
		deleteConformationAsk: "Adakah anda pasti mahu memadam pekerja ini?",
		updateEmployee: "Kemas Kini Pekerja",
	},
	productList: {
		pageTitle: "Senarai Produk",
		offerPageTitle: "Senarai Tawaran",
		searchYourProduct: "Cari Produk",
		brandList: "Senarai Jenama",
		createProduct: "Cipta Produk",
		createPack: "Cipta Bungkusan",
		createOffer: "Cipta Tawaran",
		product: "Produk",
		pack: "Bungkusan",
		Offer: "Tawaran",
		discount: "Diskaun",
		all: "Semua",
	},
	productDetails: {
		pageTitle: "Butiran Produk",
		offerStart: "Tarikh Mula Tawaran",
		offerEnd: "Tarikh Akhir Tawaran",
		moqLabel: "MOQ",
		consumerLabel: "Harga Pengguna (MRP)",
		profitLabel: "Keuntungan",
		productDetailTitle: "Butiran Produk",
		expireData: "Tarikh Tamat Tempoh",
		manufactureData: "Tarikh Pembuatan",
		productWeight: "Berat Produk",
		packingType: "Jenis Pembungkusan",
		importedBy: "Diimport Oleh",
		manufacturedBy: "Dibuat Oleh",
		country: "Negara",
		descriptionLabel: "Penerangan",
		btnLabel: "Tempah Sekarang",
		inStock: "Stok ada",
		outStock: "Stok habis",
		description: "Penerangan",
		inventory: "Inventori",
		unpublished: "Belum Diterbitkan",
		published: "Diterbitkan",
		edit: "Sunting",
		delete: "Padam",
		deleteText: "Adakah anda pasti mahu memadam produk ini?",
		myProfit: "Keuntungan Saya",
		buyerProfit: "Keuntungan Pembeli",
		skuId: "ID SKU",
		preOrderBtn: "Mohon Pra-Pesanan",
		preOrderQuantity: "Tulis Kuantiti Pra-Pesanan",
		searchTram: "Cari Tram",
	},
	brandList: {
		pageTitle: "Senarai Pembekal/Jenama",
		searchBrand: "Cari Jenama mengikut Nama",
		searchProduct: "Cari Produk Jenama mengikut Nama",
		myBrand: "Jenama Saya",
		allBrand: "Semua Jenama",
		createNewBrand: "Cipta Jenama Baru Saya",
		importBrand: "Import Jenama Lain",
		brandList: "Senarai Jenama",
		products: "Produk",
		details: "Butiran",
		distributorCode: "Kod Pengedar",
	},
	brandDetail: {
		pageTitle: "Butiran Jenama",
		sku: "SKU",
		import: "Import",
		edit: "Sunting",
		delete: "Padam",
		deleteConformationAsk: "Adakah anda pasti mahu memadam jenama ini?",
	},
	createNewBrand: {
		pageTitle: "Cipta Jenama Baru",
		uploadNewLogo: "Muat Naik Logo baru",
		brandName: "Nama Jenama",
		brandNamePlaceHolder: "sila masukkan nama jenama anda",
		selectCountry: "Pilih Negara",
		description: "Penerangan",
		descriptionPlaceHolder: "sila masukkan penerangan jenama anda",
		createdBy: "Dicipta oleh",
		complete: "Lengkap",
		congratulation: "Tahniah",
		congratulationParagraph: "Cipta Jenama Baru berjaya",
		continue: "Teruskan",
		conformModal: "Adakah anda pasti mahu mencipta jenama ini?",
		importBrandConformations: "Adakah anda pasti mahu mengimport jenama ini?",
		errorMessage: "Anda tidak boleh muat naik imej lebih dari 1 MB",
		imageFormatError:
			"Format imej tidak sah. Hanya JPEG, PNG, dan JPG yang dibenarkan",
		ownerName: "Pemilik sah jenama",
		ownerNamePlaceholder: "Masukkan nama pemilik sah",
		isLegalOwner: "Syarikat saya memiliki sah jenama ini",
		ownerNumber: "Nombor Telefon",
		ownerNumberPlaceholder: "sila masukkan nombor telefon anda",
	},
	updateBrand: {
		pageTitle: "Kemas Kini Jenama",
		congratulationParagraph: "Kemas kini jenama berjaya",
		conformModal: "Adakah anda pasti mahu mengemaskini jenama ini?",
	},
	createProduct: {
		pageTitle: "Cipta Produk",
		productWarning: "Tajuk Produk akan dijana secara automatik di sini.",
		productPhoto: "Gambar Produk",
		productPhotoUpload: "Muat Naik Gambar Produk",
		uploadPhoto: "Muat Naik Gambar",
		uploadFromGallery: "Muat Naik Dari Galeri",
		takePhoto: "Ambil Gambar",
		productName: "Nama Produk",
		productNamePlaceHolder: "masukkan nama produk anda",
		description: "Penerangan",
		descriptionPlaceHolder: "masukkan penerangan produk anda",
		selectBrand: "Pilih Jenama",
		selectDepartment: "Pilih jabatan",
		selectCategory: "Pilih kategori",
		selectSubCategory: "Pilih subkategori",
		setPrice: "Set Harga",
		sizeDetermine: "Tentukan Saiz",
		inventoryDetermine: "Tentukan Inventori",
		manufacturingDate: "Tarikh Pengilangan",
		expireData: "Tarikh Luput",
		ManufacturerInformation: "Maklumat Pengilang",
		save: "Simpan",
		publish: "Terbitkan",
		purchasePrice: "Harga Belian",
		purchasePricePlaceHolder: "tulis harga belian",
		sellingPrice: "Harga Jualan",
		sellingPricePlaceHolder: "tulis harga jualan",
		priceWarning: "Harga anda kosong",
		customerPrice: "Harga Pelanggan (MRP)",
		customerPricePlaceHolder: "tulis harga pelanggan",
		taxVat: "Cukai/VAT",
		moq: "Kuantiti Pesanan Minimum (MOQ)",
		taxVatPlaceHolder: "tulis Cukai/VAT",
		moqPlaceHolder: "tulis Harga MOQ",
		weight: "Berat",
		grossWeight: "Berat Kotor",
		grossWeightPlaceholder: "tulis berat kotor",
		weightPlaceholder: "tulis berat",
		weightWarning: "berat kosong",
		measurement: "Ukuran",
		weightUnits: "Unit Berat",
		units: "Unit",
		packingType: "Jenis Pembungkusan",
		ok: "OK",
		manufacturedCountry: "Negara Pengilangan",
		manufacturedBy: "Diproduksi Oleh",
		manufacturedByPlaceHolder: "masukkan Nama Pengilang",
		importedBy: "Diimport Oleh",
		importedByPlaceHolder: "masukkan nama importir",
		distributedBy: "Disalurkan Oleh",
		distributedByPlaceHolder: "masukkan nama pengedar",
		initialInventory: "Inventori Awal",
		initialInventoryPlaceHolder: "Tulis inventori awal",
		skuPlaceholder: "Tulis bilangan sku",
		sku: "SKU",
		empty: "Kosong",
		barCode: "Kod Bar",
		scan: "Imbas",
		width: "Lebar",
		widthPlaceholder: "Tulis lebar",
		height: "Tinggi",
		heightPlaceholder: "Tulis tinggi",
		length: "Panjang",
		lengthPlaceholder: "Tulis panjang",
		searchDepartment: "Cari Jabatan Mengikut Nama",
		searchCategory: "Cari kategori mengikut nama",
		searchSubCategory: "Cari subkategori mengikut nama",
		conformModal: "Adakah anda pasti ingin membuat produk ini?",
		updateConformModal: "Adakah anda pasti ingin mengemas kini produk ini?",
		saveConformModal: "Adakah anda pasti ingin menyimpan produk ini?",
		updateBtn: "Kemas Kini",
		updateMessage: "Nama produk, harga, stok mesti ada untuk diterbitkan.",
		publishMessage: "Adakah anda pasti ingin menerbitkan produk ini?",
		unPublishMessage: "Adakah anda pasti ingin tidak menerbitkan produk ini?",
		englishName: "Nama Bahasa Inggeris untuk Carian",
		englishNamePlaceholder: "tulis nama carian",
		productCreated: "Produk berjaya dicipta!",
		finalGrossWeight: "Berat Penghantaran Akhir",
		grossWeightUnit: "Unit Berat Keseluruhan",
		productUpdated: "Produk berjaya dikemaskini!",
	},
	updateProduct: {
		pageTitle: "Kemas Kini Produk",
	},
	createPack: {
		pageTitle: "Cipta Pakej",
		packWarning: "Tajuk Pakej akan dijana secara automatik di sini.",
		packName: "Nama Pakej",
		packNamePlaceHolder: "Sila tulis nama pakej anda",
		description: "penerangan",
		descriptionPlaceHolder: "Sila tulis penerangan",
		selectProduct: "Produk Dipilih",
		qtyPack: "Kuantiti dalam Pakej",
		qtyPackPlaceholder: "tulis kuantiti dalam pakej",
		conformModal: "Adakah anda pasti ingin membuat pakej ini?",
		saveConformModal: "Adakah anda pasti ingin menyimpan tawaran ini?",
		productCreated: "Pakej berjaya dicipta!",
		productUpdated: "Pakej berjaya dikemaskini!",
	},
	createOffer: {
		pageTitle: "Cipta Tawaran",
		offerWarning: "Tajuk Tawaran akan dijana secara automatik di sini.",
		offerName: "Nama Tawaran",
		offerNamePlaceHolder: "Sila tulis nama tawaran anda",
		description: "penerangan",
		descriptionPlaceHolder: "Sila tulis penerangan",
		selectProduct: "Produk Dipilih",
		qtyOffer: "Kuantiti dalam Tawaran",
		qtyOfferPlaceholder: "tulis kuantiti dalam Tawaran",
		conformModal: "Adakah anda pasti ingin membuat tawaran ini?",
		saveConformModal: "Adakah anda pasti ingin menyimpan tawaran ini?",
		offerStartDate: " Tarikh Mula Tawaran ",
		offerEndDate: " Tarikh Akhir Tawaran ",
		selectedItem: "Produk Dipilih",
		totalProductCount: "Jumlah Produk",
		totalAmount: "Jumlah Jumlah",
		spWarnings: "Anda perlu memilih produk",
		closeButton: "Tutup",
		offerCreated: "Tawaran berjaya dicipta!",
		offerUpdated: "Tawaran berjaya dikemaskini!",
	},
	updateOffer: {
		pageTitle: "Kemas Kini Tawaran",
		conformModal: "Adakah anda pasti ingin mengemas kini tawaran ini?",
	},
	dashboard: {
		statusLable: "Status Pesanan",
		today: "Hari Ini",
		yesterday: "Semalam",
		thisWeek: "Minggu Ini",
		thisMonth: "Bulan Ini",
		thisYear: "Tahun Ini",
		lastWeek: "Minggu Lalu",
		lastMonth: "Bulan Lalu",
		lastYear: "Tahun Lalu",
		custom: "Hari Kustom",
		selectDay: "Pilih Hari",
		Order: "Pesanan",
		totalOrder: "Jumlah Pesanan",
		totalOrderAmount: "jumlah Jumlah Pesanan",
		fsDueAmount: "Sokongan Kewangan Perlu Dibayar",
		placed: "Ditempatkan",
		accepted: "Diterima",
		scDelivered: "Dijadualkan Untuk Penghantaran",
		partialDelivered: "Dihantar Secara Separa",
		delivered: "Dihantar",
		return: "Pulangkan Produk",
		partialPaid: "Dibayar Secara Separa",
		paid: "Dibayar",
		totalPaid: "Jumlah Dibayar",
		cancel: "Batal",
		deliveryFailed: "Penghantaran Gagal",
		orderAmount: "Jumlah Pesanan",
		totalAmount: "Jumlah Keseluruhan",
		collectedAmount: "Jumlah Dikumpulkan",
		dueAmount: "Jumlah Tertunggak",
		totalDue: "Jumlah Keseluruhan Tertunggak",
		directOrder: "Pesanan Terus",
		directOrderAmount: "Jumlah Pesanan Terus",
		ekkHeroOrder: "Pesanan EkkHero",
		ekkHeroOrderAmount: "Jumlah Pesanan EkkHero",
		cancelOrderAmount: "Jumlah Pesanan Dibatalkan",
		seeDetails: "Lihat Perincian",
		period: "Tempoh",
		perviousWeek: "Minggu Sebelumnya",
		nextWeek: "Minggu Seterusnya",
		supplierOrder: "Pesanan Pembekal",
		customerOrder: "Pesanan Pelanggan",
		preview: "Pratonton",
		promoCode: "Kod Promosi",
		monthlyOrder: "Pesanan Bulan Ini",
		dueDate: "Tarikh Akhir",
		orderNumber: "Nombor Pesanan",
	},

	kycVerification: {
		imageWarning: "Anda tidak boleh muat naik imej lebih daripada 10 MB",
		imageUpload: "Muat naik imej anda di sini, atau layari",
		verifyUser: "Sahkan Pengguna",
		nidFront: "Kad Pengenalan Kebangsaan - Bahagian Depan",
		nidBack: "Kad Pengenalan Kebangsaan - Bahagian Belakang",
		uploadNidFront: "Muat naik Kad Pengenalan Kebangsaan anda di sini",
		uploadNidBack: "Muat naik Kad Pengenalan Kebangsaan anda di sini",
		selfie: "Sila ambil Selfie anda",
		selfieLabel: "Ambil Selfie",
		complete: "Muat naik selesai",
		pleaseCheck: "Sila Semak",
		next: "SETERUSNYA",
		button: "butang",
		upload: "Sila, muat naik",
		nid: "NID",
		copy: "salin",
		tradeLicense: "Lesen Perniagaan/Pendaftaran Perniagaan",
		uploadTradeLicense:
			"Muat naik Lesen Perniagaan atau Pendaftaran Perniagaan anda",
		proofAddressLabel1: "Bukti Alamat",
		proofAddressLabel2:
			"Bil Utiliti (seperti air, elektrik, gas atau Penyata Bank)",
		proofAddressPlaceholder: "Muat naik kertas bil Utiliti anda",
		taxLabel: "Nombor Pengenalan Pembayar Cukai (TIN)",
		taxPlaceholder: "Muat naik Nombor Pengenalan Pembayar Cukai (TIN) anda",
		brandAgreementLabel: "Perjanjian Jaminan Jualan atau Kontrak",
		brandAgreementPlaceholder: "Muat naik Perjanjian atau Kontrak anda",
		ebsAgreementPageTitle: "Perjanjian & Kontrak",
		ebCompanyTitle: "EkkBaz Sdn. Bhd.",
		giveLater: "Saya akan berikan ini kemudian",
		propertyType: "Jenis Harta",
		rented: "Sewa",
		own: "Milik",
		rentalAgreement: "Perjanjian Sewa/ Perjanjian dengan Pemilik",
		ownAgreement: "Perjanjian Milikan/ Perjanjian",
		rentalDocument: "Dokumen Perjanjian Sewa",
		ownDocument: "Dokumen Harta Sendiri",
		startDate: "Tarikh Mula Sewa",
		rentPerMonth: "Sewa Setiap Bulan",
		rentPerMonthPlaceholder: "tulis sewa kedai anda setiap bulan",
		select: "Sila, pilih",
		fillup: "& isikan",
		amount: "jumlah",
		click: "Sila klik",
		submit: "HANTAR",
		nidNo: "Nombor Kad Pengenalan Kebangsaan",
		nidNoPlaceholder:
			"sila masukkan nombor kad pengenalan kebangsaan anda di sini",
		tradeLicenseNo: "Nombor Lesen Perniagaan",
		tradeLicensePlaceholder:
			"sila masukkan nombor Lesen Perniagaan/Bisnes anda",
		tinNumber: "Nombor TIN",
		tinNumberPlaceholder: "sila masukkan nombor TIN anda",
		brandSelectLabel: "Pilih Jenama",
		brandSelectPlaceholder: "Pilih jenama anda",
		termsConditionPageTitle: "Terma & Syarat EkkBaz",
		termsLabel: "Terma & Syarat",
		privacyLabel: "Dasar Privasi",
		refundPolicy: "Dasar Pembatalan",
		acceptCheckbox:
			"Saya mengakui bahawa saya telah menerima, membaca, dan memahami dokumen di atas dan saya bersetuju dengan semua terma.",
	},
	restrict: {
		pageTitle: "Akses Terhad",
		warning: `Akaun Staf, Pemungut, dan Pemandu tidak boleh log masuk di sini! Tukar peranan anda atau minta admin untuk log masuk`,
		btnClick: "Klik butang hijau di bawah untuk pulang ke laman utama",
	},
	notification: {
		pageTitle: "Pemberitahuan",
		customer: "Pelanggan",
		supplier: "Pembekal",
		update: "Kemas Kini",
		supplierPageTitle: "Pemberitahuan dari Pembekal",
	},
	orderSummary: {
		orderProductPageTitle: "Ringkasan Pesanan Produk",
		orderProductDetailsPageTitle: "Butiran Ringkasan Pesanan Produk",
		driverProductPageTitle: "Ringkasan Produk Pemandu Pesanan",
		orderDriverProductDrilldown: "Butiran Ringkasan Produk Pemandu Pesanan",
		driverDeliveryPageTitle: "Ringkasan Status Pemandu Pesanan",
		deliveryAmountPageTitle: "Ringkasan Jumlah Penghantaran Pesanan",
		orderDeliveryAmountDrilldown: "Butiran Jumlah Penghantaran Pesanan",
		buyerSummary: "Ringkasan Pembeli",
		buyerSummaryDrillDown: "Butiran Ringkasan Pembeli",
		delayInPayment: "Keclewangan Dalam Pembayaran",
		delayInPaymentDetails: "Butiran Keclewangan Dalam Pembayaran",
		productTitle: "Tajuk Produk",
		qty: "Kuantiti",
		orderNo: "Nombor Pesanan",
		date: "Tarikh",
		status: "Status",
		product: "Produk",
		driver: "Pemandu",
		searchPlaceholder: "Cari Pemandu",
		totalAmount: "Jumlah Keseluruhan",
		totalPaid: "Jumlah Dibayar",
		totalDue: "Jumlah Tertunggak",
		numberOfOrders: "Jumlah Pesanan",
		deliveryFailed: "Penghantaran Gagal",
		gvmAmount: "Jumlah GMV",
		numberOfFulfillment: "Pemenuhan",
		orderCancel: "Batal Pesanan",
		revenue: "Pendapatan",
		dueAmount: "Jumlah Tertunggak",
		dayPassed: "Hari Telah Berlalu",
		storeName: "Nama Kedai",
		selectPlaceholder: "Pilih Tarikh",
		amount: "Jumlah",
		orderAmount: "Jumlah Pesanan",
	},
	autoLogOut: {
		pageTitle: "Log Keluar Automatik",
		warningMsg:
			"Log keluar automatik kerana nombor ini digunakan dalam log masuk lain",
		instrMsg: "Klik butang hijau di bawah untuk log masuk semula",
		btnLabel: "Log masuk",
	},
	loan: {
		cardTitle: "Permintaan Sokongan Kewangan",
		amount: "Jumlah",
		serviceFee: "Yuran Perkhidmatan Sokongan Kewangan",
		sfWarning:
			"Sokongan kewangan sehingga 50% tersedia tertakluk kepada prestasi anda di platform.",
	},
	loanDetails: {
		cardTitle: "Sokongan Kewangan",
		requested: "Jumlah Permintaan",
		approved: "Jumlah Diluluskan",
		serviceFee: "Yuran Perkhidmatan",
		dueDate: "Tarikh Jatuh Tempo",
		status: "Status",
		approvedDate: "Tarikh Diluluskan",
		collectedDate: "Tarikh Dikutip",
		repaidDate: "Tarikh Dibayar Balik",
		downPayment: "Bayaran Pendahuluan",
	},
	customerOrder: {
		pageTitle: "Pesanan Pelanggan",
		customer: "Pelanggan",
		customerPlaceHolder: "Cari pelanggan dengan nama",
		customerOrderAmount: "Jumlah Pesanan Pelanggan",
	},
	payment: {
		pageTitle: "Bayar Ekkbaz",
		bank: "Bank",
		acName: "Nama Akaun",
		acNumber: "Nombor Akaun",
		branch: "Cawangan",
		address: "Alamat",
		city: "Bandar",
		postal: "Poskod",
		country: "Negara",
		selectTimeDate: "Pilih Masa & Tarikh",
		transitionId: "ID Transaksi",
		transitionIdPlaceHolder: "masukkan ID Transaksi anda",
		amount: "Jumlah",
		amountPlaceHolder: "masukkan jumlah yang dihantar anda",
		uploadReceipt: "Muat naik Resit",
		senderInfo: "Maklumat Penghantar",
		receiverInfo: "Maklumat Penerima",
		senderWarning: "Sila tambah akaun bank anda dahulu",
		paymentConfirmationMessage:
			"Adakah anda pasti mahu menghantar bayaran ini?",
		inputWarning: "Sila lengkapkan medan ini",
		congratsPayment: "Tahniah! Pembayaran anda telah berjaya dihantar.",
		paymentReceiptImage: "Imej Resit Pembayaran",
		receiptOrBank: "Muat naik Imej Resit atau Slip Bank",
		warning: "Jumlah bayaran tidak betul",
	},
	depositList: {
		pageTitle: "Senarai Deposit",
		collectionAndDeposit: "Deposit & Pengumpulan",
		depositDetails: "Butiran Deposit",
		collectionDetails: "Butiran Pengumpulan",
		selectDate: "Pilih Tarikh",
		selectCollector: "Pilih Pemungut",
		txnNo: "No. TXN",
		totalCollection: "Jumlah Kumpulan",
		branch: "Cawangan",
		totalOrder: "Jumlah Pesanan",
		verified: "Disahkan",
		unverified: "Belum disahkan",
		collection: "Jumlah Pengumpulan",
		totalDeposit: "Jumlah Deposit",
		totalDue: "Jumlah Tertunggak",
		bankName: "Nama Bank",
		acName: "Nama Akaun",
		acNumber: "Nombor Akaun",
		routeNumber: "Nombor Laluan",
		transactionNumber: "Nombor Transaksi",
		bankAddress: "Alamat Bank",
		collectorName: "Nama Pemungut",
		comment: "Komen",
		placeVerifyMessage: "Adakah anda pasti ingin mengesahkan Deposit ini?",
		placeUnverifyMessage:
			"Adakah anda pasti ingin membatalkan pengesahan Deposit ini?",
		confirmPageVerifyText: "Pengesahan deposit anda telah berjaya",
		confirmPageunVerifyText: "Pengesahan deposit anda telah dibatalkan",
		depositAmount: "Jumlah Deposit",
		collectionAmount: "Jumlah Pengumpulan",
		numberOfCollection: "Bilangan Pengumpulan",
		depositType: "Jenis Deposit",
	},
	voiceOrder: {
		pageTitle: "Pesanan Suara",
		bannerHello: "Hai",
		bannerAI: "Selamat datang ke AI Pesanan Suara",
		bannerUse: "Anda boleh mencari produk yang diinginkan menggunakan saya",
		speechTitle: "Ucapan Anda",
		speechHeader: "Saya ingin memesan - ",
		speechInfo:
			"Sila beritahu saya nama produk dengan mengklik butang putih di atas",
		keywordTitle: "Kata Kunci Padanan",
		keywordInfo:
			"Sila pilih satu kata kunci di atas untuk mendapatkan produk berkaitan",
		productsTitle: "Senarai Produk Padanan Terbaik",
	},
	couponList: {
		pageTitle: "Promo Sokongan Kewangan",
		addCoupon: "Tambah Kod Promo",
		couponPlaceholder: "masukkan kod promo",
		add: "Tambah",
		applyCoupon: "Gunakan Kod Promo FS",
		apply: "gunakan",
		expired: "tamat tempoh",
		used: "digunakan",
		remove: "buang",
		applied: "digunakan",
		endIn: "Berakhir Dalam",
	},
	addBankSettings: {
		bottomSheetTitle: "Tambah Akaun Bank Anda",
		pageTitle: "Tambah Akaun Bank Anda",
		accountName: "Nama Akaun",
		accountNamePlaceholder: "Masukkan nama akaun anda",
		accountNumber: "Nombor Akaun",
		accountNumberPlaceholder: "Masukkan nombor akaun anda",
		selectBank: "Pilih Bank",
		selectBankPlaceHolder: "Pilih akaun bank anda",
		branch: "Cawangan",
		branchPlaceHolder: "Masukkan cawangan bank anda",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat bank anda",
		selectCountry: "Pilih Negara",
		selectCountryPlaceHolder: "Pilih negara bank anda",
		selectCity: "Pilih Bandar",
		selectCityPlaceHolder: "Pilih bandar anda",
		postalCode: "Poskod",
		postalCodePlaceHolder: "Masukkan poskod bank anda",
		buttonTitle: "Simpan",
		bankNameWarning: "Sila masukkan nama akaun bank anda",
		bankNumberWarning: "Sila masukkan nombor akaun bank anda",
		selectBankWarning: "Sila pilih bank",
		branchWarning: "Sila masukkan cawangan bank anda",
		addressWarning: "Sila masukkan alamat anda",
		cityWarning: "Sila masukkan bandar anda",
		searchBank: "Cari bank anda",
	},
	addedBankList: {
		pageTitle: "Senarai Bank",
		acName: "Nama A/C",
		acNumber: "Nombor A/C",
		branch: "Cawangan",
		address: "Alamat",
		city: "Bandar",
		country: "Negara",
		addBank: "Tambah Akaun Bank Anda",
	},
	blackListed: {
		pageTitle: "Senarai Hitam",
		warningMsg: "Perniagaan Anda Terkunci",
		instrMsg:
			"Sila minta pemilik perniagaan anda menghubungi EkkBaz untuk tindakan yang diperlukan bagi membolehkan semula perniagaan anda.",
		btnLabel: "Log keluar",
	},
	ebsAgreement: {
		headline: "Perjanjian Sementara Mengenai Lesen Platfrom",
	},
	kycInfo: {
		pageTitle: "Maklumat KYC",
		nidFront: "Kad Pengenalan Negara - Bahagian Depan",
		nidBack: "Kad Pengenalan Negara - Bahagian Belakang",
		tradeLicense: "Lesen Perniagaan",
		selfie: "Selfie",
		proofAddress: "Bukti Alamat",
		tin: "Nombor Pengenalan Pembayar Cukai (TIN)",
		contractPaper: "Perjanjian atau Kontrak",
		agreementAndContact: "Perjanjian atau Kontrak",
		cheque: "Imej Cek",
		name: "Nama",
		signature: "Tandatangan",
		notFound: "Tidak Ditemui",
		viewPdf: "Lihat PDF",
		hidePdf: "Sembunyikan PDF",
	},
	customerList: {
		pageTitle: "Senarai Pengedar",
		searchPlaceHolder: "Cari Pengedar Anda",
		detailsPageTitle: "Butiran Pengedar",
		customerDetailsLabel: "Maklumat Pengedar",
		businessTypeLabel: "Jenis Perniagaan",
		customerNameLabel: "Nama",
		addressLabel: "Alamat",
		postalCodeLabel: "Poskod",
		cityLabel: "Bandar",
		acceptedCustomerLabel: "Pengedar Diterima",
		requestedCustomerLabel: "Permintaan Harga",
		accept: "Terima",
		acceptConfirmation: "Adakah anda pasti ingin menerima Pengedar ini?",
		km: "km",
		createCustomer: "Cipta Pengedar Baru",
		customerNoHolder: "Nombor Telefon Pengedar",
		nextBtn: "Seterusnya",
		deleteDistributor: "Padam Pengedar",
		addCustomPrice: "Tambah Harga Produk Kustom",
		warningMsg: "Adakah anda pasti ingin memadamkan Pengedar ini?",
		yes: "Ya",
		no: "Tidak",
		successMsg: "Pengedar berjaya dipadam!",
		customPriceMsg:
			"Adakah anda pasti ingin menetapkan harga kustom untuk pengedar ini?",
		inputPlaceholder: "Masukkan Harga Kustom",
		priceEmptyError: "Harga Kustom tidak boleh kosong!",
		alreadySetPriceError:
			"Harga Produk ini telah ditetapkan untuk pengedar ini",
		cancelBtn: "Batal",
		submit: "Hantar",
		modalTitle: "Tetapkan Harga Produk Kustom",
		productsSectionTitle: "Produk dengan Harga Kustom",
		updatePriceTitle: "Kemaskini Harga Kustom",
		deletePriceTitle: "Padam Harga Produk Ini",
		productDetailTitle: "Butiran Produk",
		offerDetailsTitle: "Butiran Tawaran",
		description:
			"Adakah anda pasti ingin memadamkan produk harga kustom ini?",
		distributorCode: "Kod Pengedar",
		notSet: "Belum Ditetapkan",
		distributorCodeAdd: "Tambah Kod Pengedar",
		distributorCodeEdit: "Kemaskini Kod Pengedar",
		writeDistributorCode: "Tulis Kod Pengedar",
		successLabel: "Kod Pengedar Ditambah Berjaya",
		editSuccessLabel: "Kod Pengedar Dikemaskini Berjaya",
	},

	createSupplier: {
		pageTitle: "Buat Pembekal Baharu",
		invitedSupplierList: "Senarai Pembekal yang dijemput",
		searchInvitedSupplierList: "Cari senarai pembekal yang dijemput",
		customerExists: "Sudah wujud!",
		invited: "Dijemput",
		newBusiness: "Perniagaan Baharu Berjaya Dicipta",
		addNameWarning: "Sila Tambah Nama Perniagaan",
		addCustomerNameWarning: "Sila Tambah Nama Pelanggan",
	},
	createBusinessInfo: {
		pageTitle: "Maklumat Pengurusan Kontrak",
		title: "Maklumat Perniagaan",
		brandsLabel: "Nama Jenama",
		brandsPlaceholder: "Masukkan Nama Jenama",
		skusLabel: "Jumlah SKU",
		skusPlaceholder: "Jumlah SKU",
		srsLabel: "Jumlah SR",
		srsPlaceholder: "Jumlah SR",
		driversLabel: "Jumlah Pemandu",
		driversPlaceholder: "Jumlah Pemandu",
		customersLabel: "Jumlah Pelanggan",
		customersPlaceholder: "Jumlah Pelanggan",
		salesLabel: "Jumlah Jualan Bulanan",
		salesPlaceholder: "Masukkan Jumlah Jualan",
		fsLabel: "Jumlah FS Bulanan Diperlukan",
		fsPlaceholder: "Masukkan Jumlah FS Diperlukan",
		pwFsLabel: "Minggu Kemungkinan untuk Keperluan FS",
		pwFsPlaceholder: "Pilih Minggu",
		operationLabel: "Tarikh/Tahun Mula Operasi Perniagaan",
		operationExtraLabel: "Tahun",
		operationPlaceholder: "Pilih Tahun",
		aeLabel: "Maklum Balas AE",
		aePlaceholder: "Masukkan Maklum Balas AE",
		nextBtn: "Seterusnya",
		owner: "Pemilik",
		manager: "Pengurus",
		stuff: "Kakitangan",
		validationLabel: "Sila lengkapkan alamat untuk bergerak.",
		okBtnLabel: "OK",
		successMessage: "Maklumat perniagaan berjaya dibuat",
		brandError: "Masukkan nama jenama",
		skuError: "Masukkan jumlah SKU",
		srError: "Masukkan jumlah SR",
		driverError: "Masukkan jumlah pemandu",
		customerError: "Masukkan jumlah pelanggan",
		salesError: "Masukkan jumlah jualan",
		fsError: "Masukkan jumlah sokongan kewangan",
		operationError: "Pilih tahun mula operasi",
		feedbackError: "Masukkan maklum balas anda",
		regionLabel: "Daerah",
		areaLabel: "Sub Daerah/Thana",
		regionError: "Pilih sebuah daerah",
		areaError: "Pilih sebuah kawasan",
		areaPlaceholderWarning: "Sila pilih sebuah daerah terlebih dahulu",
		pwFsError: "Sila pilih sebuah minggu",
	},
	customerDetails: {
		AEUserLabel: "Eksekutif Akaun",
		HSuserLabel: "Kakitangan Hab",
		visitType: "Jenis Lawatan",
		createdAt: "Dicipta pada",
		createdBy: "Dicipta oleh",
		updatedAt: "Dikemas kini pada",
		updatedBy: "Dikemas kini oleh",
		businessCardTitle: "Maklumat Perniagaan",
		contactCardTitle: "Maklumat Hubungan",
		contractCardTitle: "Maklumat Kontrak",
		visitCardTitle: "Maklumat Lawatan",
		name: "Nama",
		number: "Nombor",
		role: "Peranan",
		orderType: "Jenis Pesanan",
		serviceType: "Jenis Perkhidmatan",
		revenueTier: "Tahap Hasil",
		expectedRevenue: "Jangkaan Hasil Bulanan",
		expectedCloseDate: "Jangkaan Tarikh Tutup",
		status: "Status",
		checkIn: "Masa Daftar Masuk",
		checkOut: "Masa Daftar Keluar",
		remark: "Catatan",
		location: "Lokasi",
		image: "Gambar",
		image2: "Gambar Daftar Keluar",
		pageTitle: "Butiran Pelanggan",
		businessType: "Jenis Perniagaan",
	},
	createContact: {
		pageHeadline: "Maklumat Hubungan",
		nameLabel: "Nama",
		namePlaceholder: "Masukkan Nama",
		numberLabel: "Nombor",
		numberPlaceholder: "Masukkan Nombor",
		roleError: "Pilih sebuah peranan",
	},
	createContract: {
		pageHeadline: "Maklumat Kontrak",
		orderTypeLabel: "Jenis Pesanan",
		serviceTypeLabel: "Jenis Perkhidmatan",
		revenueTierLabel: "Tahap Hasil",
		statusLabel: "Status",
		selectPlaceholder: "Pilih",
		monthlyRevenueLabel: "Jangkaan Hasil Bulanan",
		monthlyRevenuePlaceholder: "Masukkan jangkaan hasil bulanan",
		expectedCloseDateLabel: "Jangkaan Tarikh Tutup",
		expectedCloseDatePlaceholder: "Pilih tarikh",
		primaryOrder: "Utama",
		secondaryOrder: "Sekunder",
		tertiaryOrder: "Tertiar",
		successMessage: "Kontrak berjaya dibuat",
		statusError: "Pilih sebuah status",
		closeDateError: "Pilih sebuah tarikh tutup",
		monthlyRevenueError: "Masukkan jumlah hasil bulanan",
		revenueTierError: "Pilih sebuah tahap hasil",
		serviceTypeError: "Pilih sebuah jenis perkhidmatan",
		orderTypeError: "Pilih sebuah jenis pesanan",
	},
	newCustomer: {
		pageTitle: "Cipta Perniagaan Baru",
		mobileNo: "Nombor Telefon Bimbit",
		customerNoHolder: "Nombor telefon bimbit perniagaan",
		customerName: "Nama Pengguna",
		role: "Peranan",
		selectRole: "Pilih Peranan",
		customerNameHolder: "Masukkan nama penuh pengguna",
		businessPhoto: "Foto Perniagaan",
		uploadPhoto: "Muat Naik Foto Perniagaan/Kedai",
		uploadPhotoTitle: "Muat Naik Foto",
		uploadFromGallery: "Muat Naik Dari Galeri",
		takePhoto: "Ambil Foto",
		businessName: "Nama Perniagaan",
		businessNameHolder: "masukkan nama perniagaan/kedai",
		address: "Alamat",
		addressHolder: "masukkan alamat perniagaan/kedai",
		city: "Bandar",
		cityHolder: "masukkan bandar",
		postalCode: "Poskod",
		postalCodeHolder: "masukkan poskod",
		selectBusiness: "Pilih Jenis Perniagaan",
		fmcg: "FMCG",
		nonFmcg: "BUKAN-FMCG",
		nextBtn: "Seterusnya",
		completeBtn: "Lengkapkan",
		customerFindText: `Perniagaan sudah ditemui di sini, jika anda mahu, anda boleh memilih untuk menambahnya ke senarai pelanggan anda.`,
		addBusiness: "Tambah Perniagaan",
		addBusinessInfo:
			"Adakah anda ingin menambah perniagaan ini sebagai pelanggan anda?",
		noBtn: "Tidak",
		alreayExist: "Perniagaan sudah wujud!",
		reseller: "Penjual Semula",
		businessNameWarning: "Sila Tambah Nama Perniagaan",
		distributorNameWarning: "Sila Tambah Nama Pengguna",
		businessCreationSuccess: "Perniagaan Baru Berjaya Dicipta",
		businessLocationWarning: "Sila Tambah Lokasi Perniagaan dari Peta Google",
	},
	industryType: {
		pageTitle: "Industri Bekerja",
		title: "Sila pilih industri kerja anda",
	},
	addCard: {
		pageTitle: "Tambah Kad",
		addedList: "Senarai Kad Ditambah",
		addNewCard: "Tambah Kad Baru",
		continue: "Teruskan",
	},
	paymentModule: {
		pageTitle: "Pilih Pelan Peningkatan",
		upgradeNow: "Tingkatkan Sekarang",
		monthlyPlan: "Pelan Bulanan",
		annualPlan: "Pelan Tahunan",
		card: "Kad",
		paymentMethod: "Kaedah Pembayaran",
		continue: "Teruskan",
		selectedMethod: "Sila pilih kaedah pembayaran..!",
		checkOut: "Semak Keluar",
		paymentCard: "Kad Pembayaran",
		changeCard: "Tukar Kad",
		selectPackage: "Pilih Pakej",
		orderSummary: "Ringkasan Pesanan",
		subtotal: "Jumlah Kecil",
		total: "Jumlah",
		payNow: "Bayar Sekarang",
		successTitle: "Pembayaran Berjaya",
		successText: "Terima kasih atas pembayaran anda",
		yourPlan: "Pelan Langganan Anda",
		transactionHistory: "Sejarah Transaksi",
		cardNumber: "Nombor Kad",
		status: "Status",
		invoice: "Invois",
		newCard: "Tambah kad baru",
	},
};
export default malaysia;
