import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import DetailsCard from "components/mobile-components/Profile/DetailsCard";
import IdentityCard from "components/mobile-components/Profile/IdentityCard";
import SettingsCard from "components/mobile-components/Profile/SettingsCard";
import { getUser, getUserBusiness, setUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNotification } from "redux/container/notificationSlice";
import { setUser } from "redux/container/userSlice";
import Business from "services/Business";
import Notification from "services/Notification";
import User from "services/User";
import ChangeLanguagePage from "./ChangeLanguagePage";
import ChangeLanguage from "components/mobile-components/Profile/ChangeLanguage";
import { profilePage_Block } from "Constants";
import VerifyNumberSend from "components/mobile-components/Profile/VerifyNumberSend";
import PickLocationPage from "./PickLocationPage";
import PaymentSettings from "components/mobile-components/BusinessProfile/PaymentSettings";
import AddedBankListPage from "./AddedBankListPage";
import BottomMenu from "components/global-components/common/BottomMenu";
import UpgradePlanPage from "./UpgradePlanPage";
import KYCInfoPage from "./KYCInfoPage";

export default function ProfilePage() {
  const [blockNumber, setBlockNumber] = useState(
    profilePage_Block.CHANGE_PASSWORD
  );
  let business = getUserBusiness();
  let [businessData, setBusinessData] = useState({});
  let user = getUser();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [notificationCount, setNotificationCount] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const navigate = useNavigate();

  const getUserInfo = async () => {
    let response = await User.getBasicInfoUpdate();
    if (response.status === 200) {
      dispatch(setUser(response.data));
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);
  const [menuCollapse, setMenuCollapse] = useState(true);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const menuIconClick = () => {
    setMenuCollapse(!menuCollapse);
  };

  //get business data
  let getBusiness = async () => {
    let response = await Business.getBusiness(business?.id);
    if (response.status === 200) {
      setLoading(false);
      setBusinessData(response.results);
      setUserBusiness({
        ...business,
        business_status: response.results.business_status,
      });
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getBusiness();
  }, []);
  let getNotificationList = async () => {
    let response = await Notification.getProcurementNotifications();
    if (response.success) {
      setNotificationCount(response?.unread);
      dispatch(setNotification([...notificationList, ...response?.results]));
      setNotificationList([...notificationList, ...response?.results]);
    }
  };

  useEffect(() => {
    getNotificationList();
  }, []);

  return (
    // <IonApp>
    <IonPage>
      <IonContent>
        {/* <LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.profile.pageTitle}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/> */}
        <BazAiNav
          title="Baz AI"
          menuIconClick={menuIconClick}
          //   showLeft={showLeft}
          //   showRight={showRight}
          //   setShowRight={setShowRight}
          notificationCount={notificationCount}
        />
        <section className="pt-20">
          {loading ? (
            <IonLoading
              isOpen={true}
              message={ekkLanguage.orderList?.loader}
            ></IonLoading>
          ) : (
            <>
              <IdentityCard user={user} businessData={businessData} />
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div>
                  <SettingsCard
                    businessData={businessData}
                    setBlockNumber={setBlockNumber}
                  />
                </div>
                <DetailsCard>
                  {blockNumber === profilePage_Block.CHANGE_PASSWORD ? (
                    <VerifyNumberSend />
                  ) : blockNumber === profilePage_Block.CHANGE_LANGUAGE ? (
                    <ChangeLanguage />
                  ) : blockNumber === profilePage_Block.UPDATE_LOCATION ? (
                    <PickLocationPage />
                  ) : blockNumber === profilePage_Block.PAYMENT_TERM_SETTING ? (
                    <PaymentSettings
                      businessData={businessData}
                    ></PaymentSettings>
                  ) : blockNumber === profilePage_Block.BANK_LIST ? (
                    <AddedBankListPage />
                  ) : blockNumber === profilePage_Block.SUBSCRIPTION_PLAN ? (
                    <UpgradePlanPage />
                  ) : blockNumber === profilePage_Block.KYC_INFORMATION ? (
                    <KYCInfoPage />
                  ) : (
                    ""
                  )}
                </DetailsCard>
              </div>
            </>
          )}
        </section>
        <BottomMenu />
      </IonContent>
    </IonPage>
    // </IonApp>
  );
}
