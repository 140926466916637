import BazAi from "components/mobile-components/BazAI";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-pro-sidebar/dist/css/styles.css";
import { hasActive } from "helpers/helper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NotificationList from "components/mobile-components/Notifications";
import { getUserBusiness } from "config/user";
import { getLanguage } from "config/language";
import Notification from "services/Notification";
import {
	setCustomerUnreadCount,
	setNotification,
	setSupplierUnreadCount,
} from "redux/container/notificationSlice";
import { clearThread } from "redux/container/bazAiSlice";
import Supplier from "assets/images/icon/svg/Supplier";
import Chart from "assets/images/icon/svg/Chart";
import Order from "assets/images/icon/svg/Order";
import BottomMenu from "components/global-components/common/BottomMenu";

export default function BazAiPage() {
	const { tid } = useParams();
	const location = useLocation();
	const contentRef = useRef();
	const messageData = useSelector((state) => state?.bazAi?.data);
	const { pathname } = useLocation();
	const [loading, setLoading] = useState(false);
	const [notificationList, setNotificationList] = useState([]);
	const [notificationType, setNotificationType] = useState("customer");
	const dispatch = useDispatch();
	const id = getUserBusiness()?.id;
	const localLanguage = getLanguage();
	const [notificationCount, setNotificationCount] = useState("");
	const business = getUserBusiness();
	const navigate = useNavigate();

	var windowSize = window.matchMedia("(max-width: 1100px)");

	const [showLeft, setShowLeft] = useState(!windowSize.matches ? true : false);
	const [showRight, setShowRight] = useState(false);

	const scrollToBottom = () => {
		if (contentRef.current) {
			contentRef.current.scrollToBottom();
		}
	};
	useEffect(() => {
		scrollToBottom();
	}, [messageData.length]);

	const menuIconClick = () => {
		setShowLeft(!showLeft);
	};

	let textContentRef = useRef();

	// let lastItemMessage = messageData[messageData?.length - 1]?.message;
	// let lastItemType = messageData[messageData?.length - 1]?.type;
	// let selfMessage = messageData[messageData?.length - 1]?.receiver;
	// let self = messageData[messageData?.length - 1]?.user_name !== "Bot";
	let loadingMessage = messageData[messageData?.length - 1]?.loading;
	useEffect(() => {
		textContentRef.current.scrollTop = textContentRef.current.scrollHeight;
	}, [loadingMessage]);

	let getNotificationList = async () => {
		let response = await Notification.getProcurementNotifications();
		if (response.success) {
			setNotificationCount(response?.unread);
			dispatch(setNotification([...notificationList, ...response?.results]));
			setNotificationList([...notificationList, ...response?.results]);
		}
	};
	let getNotificationListDetails = async (selectedNotification) => {
		console.log(selectedNotification);
		navigate(
			`/procurement-order-details/${Number(
				selectedNotification?.service_field_id
			)}/${business?.role}`
		);
		let response = await Notification.getProcurementNotificationsDetails(
			selectedNotification?.id
		);
		if (response.success) {
			dispatch(setNotification([...notificationList, ...response?.results]));
			setNotificationList([...notificationList, ...response?.results]);
		}
	};

	useEffect(() => {
		getNotificationList();
	}, [notificationType]);

	return (
		<div
			className='relative bg-white h-screen flex flex-col overflow-y-auto'
			ref={textContentRef}>
			<BazAiNav
				title='Baz AI'
				menuIconClick={menuIconClick}
				showLeft={showLeft}
				showRight={showRight}
				setShowRight={setShowRight}
				notificationCount={notificationCount}
			/>
			<div className='flex-1 pt-12 container'>
				<BazAi />
			</div>

			<BottomMenu />
		</div>
	);
}
