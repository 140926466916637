import {
  IonApp,
  IonContent,
  IonPage,
  IonButton,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomCheckBox from "components/global-components/common/CustomCheckBox";
import Payment from "services/Payment";

export default function PaymentSettings({ businessData }) {
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [paymentTermIds, setPaymentTermsIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sellerTerms, setSellerTerms] = useState([]);

  let getAllPaymentInfo = async function () {
    let results = await Payment.getAllPaymentTerms();
    let paymentTerms = await Payment.sellerPaymentTerms();
    if (results?.status === 200) {
      setSellerTerms(paymentTerms.results);
      setLoading(false);
      let ids = [];
      let finalArray = results?.results.map((item1) => {
        let matchChecker = paymentTerms.results.find(
          (item2) => item2.id === item1.id
        );
        if (matchChecker) {
          ids.push(item1.id);
          return { ...item1, is_active: true };
        } else {
          return { ...item1, is_active: false };
        }
      });
      setPaymentTermsIds(ids);
      setPaymentTerms(finalArray);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllPaymentInfo();
  }, []);
  const changeCheckHandler = (data) => {
    let updateArray = [...paymentTermIds];
    let checkIndex = updateArray.indexOf(data.id);
    if (checkIndex !== -1) {
      updateArray.splice(checkIndex, 1);
      let updateTerms = paymentTerms.map((term) =>
        term.id === data.id ? { ...term, is_active: false } : term
      );
      setPaymentTerms(updateTerms);
    } else {
      updateArray.push(data.id);
      let updateTerms = paymentTerms.map((term) =>
        term.id === data.id ? { ...term, is_active: true } : term
      );
      setPaymentTerms(updateTerms);
    }
    setPaymentTermsIds(updateArray);
  };
  const handleSaveTerms = async () => {
    if (sellerTerms?.every((term) => term.is_default === true)) {
      // Create API call
      const data = {
        seller_id: businessData?.id,
        pterms_ids: paymentTermIds?.sort((a, b) => a - b),
      };
      let response = await Payment.createPaymentTerms(data);
      if (response.success) {
        toast.success("Payment Terms Successfully Created!");
      } else {
        // Update API call
        let res = await Payment.updatePaymentTerms(data);
        if (res.success) {
          toast.success("Payment Terms Successfully Updated!");
        }
      }
    } else {
      // Update API call
      const data = {
        seller_id: businessData?.id,
        pterms_ids: paymentTermIds?.sort((a, b) => a - b),
      };
      let response = await Payment.updatePaymentTerms(data);
      if (response.success) {
        toast.success("Payment Terms Successfully Updated");
      }
    }
  };
  return (
    // <IonApp>
    // 	<IonPage>
    // 		<IonContent>
    <div className="pt-5 px-4">
      {/* page title  */}
      <h6 className="text-18 font-bold mb-5">
        {ekkLanguage.paymentSettings.mainTitle}{" "}
      </h6>
      <h2 className="text-14 opacity-50 font-semibold mb-5">
        {ekkLanguage.paymentSettings.subTitle}{" "}
      </h2>
      {/* payment terms options */}
      <>
        {loading ? (
          <div className="flex items-center justify-center h-[156px] m-auto">
            <IonSpinner name="crescent"></IonSpinner>
          </div>
        ) : (
          <>
            {paymentTerms?.map((term) => (
              <CustomCheckBox
                el={term}
                key={term.id}
                currentState={term.is_active}
                changeHandler={changeCheckHandler}
                title={term.title}
              />
            ))}
          </>
        )}
      </>

      {/* next button  */}
      <IonButton
        type="submit"
        onClick={() => handleSaveTerms()}
        color="success"
        className="font-bold h-12 text-20 rounded  w-full mt-5 mx-auto"
      >
        {ekkLanguage.paymentSettings.btnLabel}
      </IonButton>
    </div>
    // 		</IonContent>
    // 	</IonPage>
    // </IonApp>
  );
}
