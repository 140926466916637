import React from "react";

export default function DotMenu({ color = "white" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g clipPath="url(#clip0_1174_45)">
        <path
          d="M5.8333 14C5.8333 12.3892 4.52747 11.0833 2.91665 11.0833C1.30583 11.0833 -1.97902e-07 12.3892 -1.27491e-07 14C-5.70795e-08 15.6108 1.30583 16.9166 2.91665 16.9166C4.52747 16.9166 5.8333 15.6108 5.8333 14Z"
          fill={color}
        />
        <path
          d="M16.9168 14C16.9168 12.3892 15.611 11.0833 14.0001 11.0833C12.3893 11.0833 11.0835 12.3892 11.0835 14C11.0835 15.6108 12.3893 16.9166 14.0001 16.9166C15.611 16.9166 16.9168 15.6108 16.9168 14Z"
          fill={color}
        />
        <path
          d="M27.9998 14C27.9998 12.3892 26.694 11.0833 25.0832 11.0833C23.4723 11.0833 22.1665 12.3892 22.1665 14C22.1665 15.6108 23.4723 16.9166 25.0832 16.9166C26.694 16.9166 27.9998 15.6108 27.9998 14Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1174_45">
          <rect
            width="28"
            height="28"
            fill={color}
            transform="translate(0 28) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
