import { IonLoading } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import NotificationCard from "./NotificationCard";

export default function NotificationList({
	loading,
	from,
	localLanguage,
	getNotificationListDetails,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div>
			{loading ? (
				<IonLoading
					isOpen={true}
					message={ekkLanguage.orderList?.loader}></IonLoading>
			) : (
				<NotificationCard
					localLanguage={localLanguage}
					from={from}
					getNotificationListDetails={getNotificationListDetails}
				/>
			)}
		</div>
	);
}
