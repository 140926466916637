// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.porduct-details .product-specific-details div:before {
  position: absolute;
  left: -4px;
  top: 5px;
  height: 6px;
  width: 6px;
  background: rgba(0, 0, 0, 0.33);
  content: "";
  border-radius: 50%;
}

ion-button {
  --box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/components/mobile-components/VoiceRecord/style.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;EACA,UAAA;EACA,QAAA;EACA,WAAA;EACA,UAAA;EACA,+BAAA;EACA,WAAA;EACA,kBAAA;AACD;;AACA;EACC,kBAAA;AAED","sourcesContent":[".porduct-details .product-specific-details div:before {\n\tposition: absolute;\n\tleft: -4px;\n\ttop: 5px;\n\theight: 6px;\n\twidth: 6px;\n\tbackground: rgba(0, 0, 0, 0.33);\n\tcontent: \"\";\n\tborder-radius: 50%;\n}\nion-button {\n\t--box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
