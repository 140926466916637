import React, { useEffect, useRef, useState } from "react";
import BannerSlider from "./BannerSlider";
import "./style.scss";
import PhoneNumber from "pages/LandingPageV2/PhoneNumber";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateToken } from "config/SuperFetch";
import { authOtpSend } from "helpers/helper";
import Auth from "services/Auth";
import LeftArrow2 from "assets/images/icon/svg/LeftArrow2";

export default function BannerTokenSubmit() {
	var sSize = window.matchMedia("(max-width: 599px)");
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	let clickBtnRef = useRef(null);
	let userInfo = useSelector((state) => state.user);
	const countryCode = userInfo?.createUserData?.countryCode;
	const country = userInfo?.createUserData?.country;
	const phoneNo = userInfo?.createUserData?.phoneNumber;
	const [token, setToken] = useState("");
	const [waiting, setWaiting] = useState(false);
	const [counter, setCounter] = useState(120);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let time = 0;
	var interval;
	const startLoading = () => setLoading(true);
	const stopLoading = () => setLoading(false);

	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			clickBtnRef.click();
		}
	};

	//this function handle token resend
	const resendTokenHandler = async (phoneNumber, country, countryCode) => {
		let getToken = authOtpSend(phoneNumber, country, countryCode);
		getToken
			.then((res) => {
				if (res.status === 200) {
					// setBlockIndex(authBox_Block.TOKEN);
					toast.success(ekkLanguage.token.tokenSuccessLabel);
					setWaiting(true);
					interval = setInterval(() => {
						time = time + 1;
						setCounter(counter - time);
						if (time === 120) {
							setWaiting(false);
							clearInterval(interval);
							setCounter(120);
						}
					}, 1000);
				} else {
					// setBlockIndex(authBox_Block.TOKEN);
					toast.success(ekkLanguage.token.tokenErrorLabel);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const tokenSubmit = async (countryCode, country, phoneNumber) => {
		if (token) {
			let req_body = {
				country: country,
				phone_number: (countryCode + phoneNumber).trim(),
				otp_token: Number(token),
			};

			try {
				startLoading();
				let response = await Auth.userVerifyOtp(req_body);
				console.log(response);
				if (response.status === 200) {
					updateToken(response);
					if (response.success) {
						toast.success(ekkLanguage.token.tokenVarified);
						navigate("/change-password");
						// setBlockIndex(authBox_Block.CHANGE_PASS_BLOCK);
					} else {
						toast.error(ekkLanguage.token.tokenNotMatch);
					}
				}
				stopLoading();
			} catch (e) {
				stopLoading();
				toast.error(ekkLanguage.token.tokenNotMatch);
				console.log(e);
			}
		} else {
			toast.error(ekkLanguage.token.tokenNotWrite);
		}
	};
	useEffect(() => {
		setWaiting(true);
		let time = 0;
		let int;
		int = setInterval(() => {
			time = time + 1;
			setCounter(counter - time);
			if (time === 120) {
				setWaiting(false);
				clearInterval(int);
				setCounter(120);
			}
		}, 1000);
	}, []);
	return (
		<div className='relative banner-area-wrapper '>
			<div className='pb-[70px] sm:pb-[70px] container m-auto  bg-F2F3F7'>
				<div className='flex items-center md:space-x-0 lg:space-x-10 relative z-10 slider-wrapper'>
					<div className='sm:w-5/12 w-8/12 slider-area'>
						<BannerSlider />
					</div>
					<div className='lg:w-7/12 w-full banner-content pb-[70px] lg:pb-[0]'>
						<div className=' bg-[#FFFFFF] lg:p-10 px-5 py-10 rounded-lg shadow-md w-full'>
							<div>
								<label
									className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] flex justify-between items-center`}>
									{ekkLanguage.token.tokenLabel}
									<span
										onClick={() => navigate("/password")}
										className='text-xs text-red cursor-pointer flex items-center'>
										<span className='mr-1'>
											<LeftArrow2 />
										</span>
										{ekkLanguage?.password.backWord}
									</span>
								</label>
								<div className='pb-5'>
									<input
										onChange={(event) => {
											setToken(event.target.value);
										}}
										id={"ekk-input"}
										label={ekkLanguage.token.tokenLabel}
										placeholder={ekkLanguage.token.tokenPlaceHolder}
										onKeyPress={_handleKeyPress}
										className='appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white'
										style={{
											paddingLeft: sSize.matches ? 10 : 20,
											height: sSize.matches ? 50 : 60,
											flex: "flex: 1 0 auto",
											borderRadius: 15,
											borderWidth: 1,
											border: "1px solid #00000026",
										}}
									/>
								</div>

								<div>
									{!waiting ? (
										<span
											onClick={() =>
												resendTokenHandler(
													phoneNo,
													country,
													countryCode
												)
											}
											id='resend-token'
											className='text-sm underline text-red'>
											{ekkLanguage.token.tokenResend}
										</span>
									) : (
										<p className='text-dark-500 text-14 font-normal'>
											{ekkLanguage.token.timerLabel} {counter}{" "}
											{ekkLanguage.token.second}
										</p>
									)}
								</div>
							</div>

							<div className='flex justify-center py-4'>
								<button
									onClick={() =>
										tokenSubmit(countryCode, country, phoneNo)
									}
									ref={clickBtnRef}
									style={{
										background:
											"linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
									}}
									className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px]`}>
									{ekkLanguage?.token.btnLabel}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
