import Close from "assets/images/icon/svg/Close";
import React from "react";

export default function BazAICard({
  title,
  children,
  onHide,
  closeModal,
  showIcon,
  icon,
}) {
  return (
    <div
      className="bg-[#ffffff] rounded-[10px] py-5"
      style={{ boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)" }}
    >
      <div
        className=" pb-3 flex justify-between items-center px-4"
        style={{ bordreColor: "#f2f3f7" }}
      >
        <div className="flex justify-between items-center py-2 w-full">
          <h3 className="text-222222 font-bold text-20 ">{title}</h3>
          {showIcon && <img src={icon} alt="" className="w-10 h-10 mr-5" />}
        </div>

        {onHide && (
          <span className="block" onClick={closeModal}>
            <Close />
          </span>
        )}
      </div>
      <div className="px-[10px]">{children}</div>
    </div>
  );
}
