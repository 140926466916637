import { IonLoading } from "@ionic/react";
import BottomMenu from "components/global-components/common/BottomMenu";
import BazAiNav from "components/mobile-components/Common/BazAiNav";
import React, { useEffect, useState } from "react";
import Product from "services/Product";
import Banner from "components/mobile-components/ProductDetails/Banner";
import ProductDetails from "components/mobile-components/ProductDetails/ProductDetails";

export default function AssetsPage() {
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const handleAssetSelect = (asset, index) => {
    setSelectedAsset(asset);
    setSelectedIndex(index);
  };
  const getAssets = async () => {
    setLoading(true);
    let response = await Product.productList();
    if (response) {
      setAssets(response.results);
      setSelectedAsset(response.results[0]);
      setSelectedIndex(0);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAssets();
  }, []);
  let defaultImageMokeUp = () => {
		if (selectedAsset?.type === "variant") {
			return "/assets/images/varient_big_default.png";
		} else if (selectedAsset?.type === "pack") {
			return "/assets/images/pack_big_default.png";
		} else if (selectedAsset?.type === "offer") {
			return "/assets/images/offer_big_default.png";
		} else {
			return "/assets/images/varient_big_default.png";
		}
	};

	let slideData = selectedAsset?.images?.length
		? [...selectedAsset?.images]
		: [`${defaultImageMokeUp()}`];
  return (
    <div className="px-10 pb-5 mx-auto">
      <BazAiNav />
      {loading ? (
        <IonLoading
          isOpen={loading}
          message={"Please wait..."}
          spinner="circles"
        />
      ) : assets?.length === 0 ? (
        <div className=" flex justify-center items-center text-gray-500 font-bold text-2xl">
          No data found!
        </div>
      ) : (
        <div className="w-full flex lg:flex-row flex-col space-x-5">
          <div className="lg:w-1/2 w-full py-2.5">
            {/* Left Pane (Table) */}
            <div
              className="bg-[#ffffff] rounded-[10px] mt-20"
              style={{
                boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
              }}
            >
              <h3 className="text-222222 font-bold text-24 py-2 px-4">
                Assets
              </h3>
              <div className="border border-gray-300 rounded-lg p-4">
                <div className="">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr className="bg-gray-500 text-white">
                        <th className="text-left px-4 py-2">S/N</th>
                        <th className="text-left px-4 py-2">Asset Name</th>
                        <th className="text-left px-4 py-2">Price</th>
                        <th className="text-left px-4 py-2">Inventory</th>
                        <th className="text-left px-4 py-2">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assets?.map((asset, index) => (
                        <tr
                          key={asset?.id}
                          className={`${
                            index % 2 === 0 ? "bg-white" : "bg-gray-50"
                          } h-12 cursor-pointer transition-colors duration-200 
                              ${
                                selectedIndex === index
                                  ? "bg-gray-400 text-white"
                                  : "hover:bg-gray-200"
                              }`}
                          onClick={() => {
                            setSelectedIndex(index);
                            handleAssetSelect(asset, index);
                          }}
                        >
                          <td className="text-black px-4 py-2">{index + 1}</td>
                          <td className="text-black px-4 py-2 t text-left">
                            {asset?.title}
                          </td>
                          <td className="text-black px-4 py-2">
                            {asset?.base_price}
                          </td>
                          <td className="text-black px-4 py-2">
                            {asset?.inventory_quantity}
                          </td>
                          <td className="px-4 py-2">
                            <span
                              className={`${
                                asset?.status === "Publish"
                                  ? "bg-green-100 text-green-800"
                                  : asset?.status === "Unpublish"
                                  ? "bg-yellow-100 text-yellow-800"
                                  : asset?.status === "Unverified"
                                  ? "bg-red-100 text-red-800"
                                  : "bg-gray-100 text-gray-800"
                              } rounded-full px-3 py-1 text-sm`}
                            >
                              {asset?.status.charAt(0).toUpperCase() +
                                asset?.status.slice(1)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Right Pane (Details) */}
          </div>
          <div
            id="main-content"
            className="rounded-lg lg:w-1/2 w-full  mt-20  py-2.5"
          >
            <Banner
								slideData={slideData}
								serverImsge={selectedAsset?.images?.length}
							/>
              <ProductDetails product={selectedAsset} />
          </div>
          <BottomMenu />
        </div>
      )}
    </div>
  );
}
